import baseUrl from "./baseUrl";

export default {
  login: `${baseUrl}/login`,
  register: `${baseUrl}/register`,
  forgotPassword: `${baseUrl}/forgot-password`,
  logout: `${baseUrl}/logout`,
  refreshToken: `${baseUrl}/refresh-token`,
  googleAuth: `${baseUrl}/google-auth`,
  lowonganKerja: `${baseUrl}/lowongan-kerja`,
  autoComplete: `${baseUrl}/auto-complete`,
  autoCompletePerusahaan: `${baseUrl}/auto-complete-perusahaan`,
  perusahaan: `${baseUrl}/perusahaan`,
  magang: `${baseUrl}/magang`,
  autoCompleteMagang: `${baseUrl}/auto-complete-magang`,
  dataDiri: `${baseUrl}/data-diri`,
  jobsClassification: `${baseUrl}/jobs-classification`,
  countryCity: `${baseUrl}/country-city`,
  updateDataDiri: `${baseUrl}/data-diri`,
  jobsCategory: `${baseUrl}/jobs-category`,
  workExperience: `${baseUrl}/work-experience`,
  degrees: `${baseUrl}/degrees`,
  education: `${baseUrl}/education`,
  proficiency: `${baseUrl}/proficiency`,
  skill: `${baseUrl}/skill`,
  license: `${baseUrl}/license`,
  profile: `${baseUrl}/profile`,
  usersRating: `${baseUrl}/users-rating`,
  verifyProfile: `${baseUrl}/users-verify`,
  usersApply: `${baseUrl}/users-apply`,
  checkUser: `${baseUrl}/check-users`,
  emailVerification: `${baseUrl}/email-verification`,
  applyStatus: `${baseUrl}/apply-status`,
  uploadPhoto: `${baseUrl}/photo`,
  resume: `${baseUrl}/resume`,
  psychotest: `${baseUrl}/psychotest`,
};
