import React, { useEffect, useState } from "react";
import Layout from "../../components/layouts/Layout";
import { Link, useParams } from "react-router-dom";
import { MdOutlineNavigateNext } from "react-icons/md";
import axios from "axios";
import api from "../../Api/api";

export default function EmailVerification() {
  const { tokenEmail } = useParams();
  const [msgResponse, setMsgResponse] = useState("");
  const [successVerify, setSuccessVerify] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    verifyEmail();
  }, []);

  const verifyEmail = async () => {
    setLoading(true);
    const url = `${api.emailVerification}/${tokenEmail}`;
    const users = JSON.parse(localStorage.getItem("user"));

    const data = {
      users_email: users?.user_email,
    };

    await axios
      .put(url, data)
      .then((response) => {
        setLoading(false);
        setSuccessVerify(true);
      })
      .catch((error) => {
        if (error.response.status === 400) {
          setMsgResponse(error.response.data.message);
          setSuccessVerify(false);
        }
        setLoading(false);
      });
  };
  return (
    <>
      <Layout title="Email Verification">
        {loading && (
          <>
            <div className="w-full pt-80 h-[100vh]">
              <div className="flex items-center justify-center">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          </>
        )}

        {!loading && (
          <>
            {successVerify ? (
              <div className="max-w-[1200px] min-h-[100vh] py-[200px] m-auto px-5 lg:px-0">
                <h1 className="flex justify-center mb-2 text-2xl font-semibold">
                  Verifikasi email anda sukses
                </h1>
                <p className="flex justify-center">
                  Anda bisa melanjutkan mengakses halaman yang ada
                </p>
                <div className="flex justify-center mt-[30px] ">
                  <Link
                    to="/profile"
                    className="flex items-center gap-[6px] font-semibold"
                  >
                    Kembali ke profile{" "}
                    <MdOutlineNavigateNext className="text-xl" />
                  </Link>
                </div>
              </div>
            ) : (
              <div className="max-w-[1200px] min-h-[100vh] py-[200px] m-auto px-5 lg:px-0">
                <h1 className="flex justify-center mb-2 text-2xl font-semibold">
                  Verifikasi email anda gagal
                </h1>
                <p className="flex justify-center">
                  Mohon ulangi verifikasi email anda
                </p>
              </div>
            )}
          </>
        )}
      </Layout>
    </>
  );
}
