import loginStyle from "../Login.module.css";

const Tagline = () => {
  return (
    <div className={loginStyle.loginTagline}>
      <div>Find your suitable job</div>
      <div>You can find any position in any companies</div>
    </div>
  );
};

export default Tagline;
