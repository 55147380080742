import forgotPasswordStyle from "../ForgotPassword.module.css";
import Button from "../../../components/auth/Button";

export default function ForgotPasswordComponent({
  msgResponse,
  onSubmit,
  users_email,
  setUsersEmail,
  loading,
}) {
  return (
    <>
      <div className={forgotPasswordStyle.title}>Forgot Password</div>
      <div className={forgotPasswordStyle.line}></div>
      <div className={forgotPasswordStyle.validationMessage}>
        <small>{msgResponse}</small>
      </div>
      <div className={forgotPasswordStyle.emailLabel}>Email</div>
      <form onSubmit={onSubmit}>
        <div className={forgotPasswordStyle.inputEmail}>
          <input
            type="email"
            placeholder="example.mail@gmail.com"
            value={users_email}
            onChange={(e) => setUsersEmail(e.target.value)}
            required
          />
        </div>
        <div className={forgotPasswordStyle.btnWrapper}>
          <Button text="Verify" loading={loading} />
        </div>
      </form>
    </>
  );
}
