import React, { useState, useEffect } from "react";
import axios from "axios";
import CardStatusLamaran from "./CardStatusLamaran";
import api from "../../Api/api";
import Swal from "sweetalert2";
import { ScrollTop } from "../../utils/ScrollTop";

function Interviewed() {
  const [loading, setLoading] = useState(false);
  const [dataStatusLamaran, setDataStatusLamaran] = useState([]);

  useEffect(() => {
    ScrollTop();
    fecthDataStatusLamaran();
  }, []);

  const fecthDataStatusLamaran = async () => {
    setLoading(true);
    const users = JSON.parse(localStorage.getItem("user"));
    const url = `${api.applyStatus}?users_email=${
      users?.user_email
    }&apply_status_id=${2}`;

    await axios
      .get(url, {
        headers: {
          "Content-type": "application/json",
          Authorization: `bearer ${users.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        setDataStatusLamaran(response.data);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const cancelLamaran = (id) => {
    Swal.fire({
      title: "Cancel apply?",
      text: "Are you sure want to cancel this apply?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#F39058",
      cancelButtonColor: "#d33",
      confirmButtonText: "Process",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const users = JSON.parse(localStorage.getItem("user"));
        const url = `${api.applyStatus}/${id}`;

        await axios
          .delete(url, {
            headers: {
              "Content-type": "application/json",
              Authorization: `bearer ${users?.token}`,
            },
          })
          .then((response) => {
            Swal.fire({
              title: "Success",
              text: "Suceess cancel apply",
              icon: "success",
              confirmButtonColor: "#F39058",
            });
            fecthDataStatusLamaran();
          })
          .catch((error) => {
            console.log(error);
            Swal.fire({
              title: "Failed",
              text: "Failed cancel apply",
              icon: "error",
              confirmButtonColor: "#F39058",
            });
            fecthDataStatusLamaran();
          });
      }
    });
  };

  return (
    <div className="xl:pl-[50px] w-full">
      {loading && (
        <>
          <div className="w-full pt-20">
            <div className="flex items-center justify-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </>
      )}

      {!loading && (
        <>
          {dataStatusLamaran?.length > 0 && (
            <>
              {dataStatusLamaran?.map((data, index) => {
                return (
                  <div key={index}>
                    <CardStatusLamaran
                      data={data}
                      cancelLamaran={cancelLamaran}
                    />
                  </div>
                );
              })}
            </>
          )}
          {dataStatusLamaran?.length === 0 && (
            <div className="w-full px-10 py-20">
              <div className="flex items-center justify-center gap-4 font-semibold">
                <img src="/images/noData.png" alt="data-not-found" /> Data Not
                Found
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default Interviewed;
