import React from "react";
import styleExploreJob from "../LowonganKerja.module.css";
import { MdLocationPin } from "react-icons/md";
import { AiTwotoneTags } from "react-icons/ai";
import { FaMoneyBillWave } from "react-icons/fa";
import style from "../LowonganKerja.module.css";
import moment from "moment";

export default function Card(props) {
  moment.locale("en");
  return (
    <>
      <div
        className={`w-full h-full bg-[#fffefe] rounded-md p-3 flex flex-col justify-between ${style.card} hover:text-[#f39058]`}
      >
        <div className="flex gap-3 mb-3">
          <div>
            <img
              className="w-[100px]"
              src={
                props?.logo
                  ? `${process.env.REACT_APP_MEDIA_URL}/images/${props?.logo}`
                  : "/images/company_default.png"
              }
              alt="company"
            />
          </div>
          <div>
            <div className="text-xs font-semibold">{props.company}</div>
            <div className={styleExploreJob.jobTitle}>{props.jobTitle}</div>
          </div>
        </div>
        <div className="flex gap-3 mb-2">
          <div className="flex items-center gap-1 text-xs">
            <MdLocationPin /> {props.location}
          </div>
          <div className="flex items-center gap-1 text-xs">
            <AiTwotoneTags /> {props.workType}
          </div>
        </div>
        {props.hideSalary === "false" && (
          <>
            {props.salaryRange === "true" && (
              <div className="flex items-center gap-2 mb-2 text-xs font-semibold">
                <FaMoneyBillWave />
                {props.currency}&nbsp;{props.minSalary?.toLocaleString()} -{" "}
                {props.maxSalary?.toLocaleString()} / Month
              </div>
            )}
            {props.salaryRange === "false" && (
              <div className="flex items-center gap-2 mb-2 text-xs font-semibold">
                <FaMoneyBillWave />
                {props.currency}&nbsp;
                {props.minSalary?.toLocaleString()} / Month
              </div>
            )}
          </>
        )}
        {!props.updated && (
          <div className="flex items-end justify-end h-8 text-xs font-semibold">
            Created {moment(props.created, "YYYYMMDD").fromNow()}
          </div>
        )}
        {props.updated && (
          <div className="flex items-end justify-end h-8 text-xs font-semibold">
            Updated {moment(props.updated, "YYYYMMDD").fromNow()}
          </div>
        )}
      </div>
    </>
  );
}
