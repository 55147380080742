import changePasswordStyle from "../ChangePassword.module.css";
import { Link } from "react-router-dom";

export default function SuccessChangePasswordComponent() {
  return (
    <>
      <div className={changePasswordStyle.succesChangeMsg}>
        Password succesfully change, now you can login
      </div>
      <div className={changePasswordStyle.btnWrapper}>
        <Link to="/login">
          <button className={changePasswordStyle.btn} type="button">
            Login
          </button>
        </Link>
      </div>
    </>
  );
}
