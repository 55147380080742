import loginStyle from "../Login.module.css";

const Header = ({ msgResponse }) => {
  return (
    <>
      <div className={loginStyle.title}>Sign In</div>
      <div className={loginStyle.line}></div>
      <div className={loginStyle.validationMessage}>
        <small>{msgResponse}</small>
      </div>
    </>
  );
};

export default Header;
