export function generateYear() {
  const endDate = new Date().getFullYear()
  const yearList = []
  for (var i = 1970; i <= endDate + 10; i++) {
    var list = {
      label: i,
      value: i,
    }
    yearList.push(list)
  }

  return yearList
}

export function getMonth(idx) {
  if (idx) {
    var objDate = new Date()
    objDate.setDate(1)
    objDate.setMonth(idx - 1)

    var locale = 'en-us',
      month = objDate.toLocaleString(locale, { month: 'long' })

    return month
  } else {
    return ' '
  }
}

export function titleCase(str) {
  var splitStr = str.toLowerCase().split(' ')
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
  }
  return splitStr.join(' ')
}

export function updatedToDate(idx) {
  if (idx) {
    var split = idx.split(' ')
    var date = split[0].split('-')

    return date[2] + ' ' + getMonth(date[1]) + ' ' + date[0]
  } else {
    return ' '
  }
}

export function updatedToDateTime(idx) {
  if (idx) {
    var split = idx.split(' ')
    var date = split[0].split('-')
    var time = split[1].split(':')

    return (
      date[2] +
      ' ' +
      getMonth(date[1]) +
      ' ' +
      date[0] +
      ' | ' +
      time[0] +
      ':' +
      time[1]
    )
  } else {
    return ' '
  }
}

export function numberFormat(idx) {
  if (idx) {
    var nf = new Intl.NumberFormat()
    return nf.format(idx)
  } else {
    return '0'
  }
}
