import React, { useState, useEffect } from "react";
import Layout from "../../../components/layouts/Layout";
import style from "./Perusahaan.module.css";
import { AiOutlineMail } from "react-icons/ai";
import { BsTelephone } from "react-icons/bs";
import { FaLinkedin } from "react-icons/fa";
import { BiWorld } from "react-icons/bi";
import axios from "axios";
import api from "../../../Api/api";
import { useParams } from "react-router-dom";
import Deskripsi from "./Deskripsi";
import Galeri from "./Galeri";
import LowonganPerusahaan from "./LowonganPerusahaan";

export default function DetailPerusahaanIndex() {
  let params = useParams();
  const companyId = params.companyId;
  const [dataCompany, setDataComapny] = useState("");
  const [dataCompanyJobs, setDataCompanyJobs] = useState([]);
  const [dataGallery, setDataGallery] = useState("");
  const [menuActive, setMenuActive] = useState(1);

  useEffect(() => {
    getDataDetailPerusahaan();
  }, []);

  const getDataDetailPerusahaan = async () => {
    const url = `${api.perusahaan}/${companyId}`;

    await axios
      .get(url)
      .then((response) => {
        setDataComapny(response.data.data_company);
        setDataCompanyJobs(response.data.data_company_jobs);
        setDataGallery(response.data.data_company_gallery);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const menuDetailPerusahaan = [
    {
      id: 1,
      component: <Deskripsi company={dataCompany} />,
    },
    {
      id: 2,
      component: <Galeri dataGallery={dataGallery} />,
    },
    {
      id: 3,
      component: <LowonganPerusahaan dataJob={dataCompanyJobs} />,
    },
  ];

  // link parser
  const urlParser = (url) => {
    const urlFromDatabase = url;

    const formattedUrl =
      urlFromDatabase.startsWith("http://") ||
      urlFromDatabase.startsWith("https://")
        ? urlFromDatabase
        : `https://${urlFromDatabase}`;

    return formattedUrl;
  };

  return (
    <>
      <Layout title="Company">
        <div className={style.container}>
          <div className={style.containerDetail}>
            <div className={style.banner}>
              <img
                src={
                  dataCompany?.company_banner
                    ? `${process.env.REACT_APP_MEDIA_URL}/images/${dataCompany?.company_banner}`
                    : "/images/company_banner_default.png"
                }
                alt="company-banner"
              />
            </div>
            <div className={style.companyInfo}>
              <div className={`${style.companyMainInfo}`}>
                <img
                  src={
                    dataCompany?.company_photo
                      ? `${process.env.REACT_APP_MEDIA_URL}/images/${dataCompany?.company_photo}`
                      : "/images/company_default.png"
                  }
                  alt="company"
                />
                <div>
                  <div className={style.companyName}>
                    {dataCompany.company_name}
                  </div>
                  <div className={style.companyCategory}>
                    {dataCompany.company_category_name}
                  </div>
                  <div className={style.socialMedia}>
                    <a
                      rel="noreferrer"
                      href={
                        dataCompany.company_website
                          ? urlParser(dataCompany.company_website)
                          : ""
                      }
                    >
                      <BiWorld />
                    </a>
                    <a
                      rel="noreferrer"
                      href={
                        dataCompany.company_linkedin
                          ? urlParser(dataCompany.company_linkedin)
                          : ""
                      }
                    >
                      <FaLinkedin />
                    </a>
                  </div>
                </div>
              </div>
              <div>
                <div className={style.contactSection}>
                  {dataCompany.company_email}
                  <AiOutlineMail className={style.contactIcon} />
                </div>
                <div className={style.contactSection}>
                  {dataCompany.company_phone_number}
                  <BsTelephone className={style.contactIcon} />
                </div>
              </div>
            </div>
            <hr />
            <div className={style.navCompanyWrapper}>
              <div className={style.navCompany}>
                <button
                  className={
                    menuActive === 1 ? "text-[#F39058]" : "hover:text-[#F39058]"
                  }
                  onClick={() => setMenuActive(1)}
                >
                  Company
                </button>
                <button
                  className={
                    menuActive === 2 ? "text-[#F39058]" : "hover:text-[#F39058]"
                  }
                  onClick={() => setMenuActive(2)}
                >
                  Gallery
                </button>
                <button
                  className={
                    menuActive === 3 ? "text-[#F39058]" : "hover:text-[#F39058]"
                  }
                  onClick={() => setMenuActive(3)}
                >
                  Vacancies
                </button>
              </div>
            </div>
          </div>
        </div>
        {menuDetailPerusahaan
          .filter((m) => m.id === menuActive)
          .map((m, i) => {
            return <div key={i}>{m.component}</div>;
          })}
      </Layout>
    </>
  );
}
