import forgotPasswordStyle from "../ForgotPassword.module.css";

export default function SuccessForgotPasswordComponent({ users_email }) {
  return (
    <div className={forgotPasswordStyle.successBoxWrapper}>
      <div className={forgotPasswordStyle.successBox}>
        <h5>An email has been sent to {users_email}</h5>
        <span>Please check your email</span>
      </div>
    </div>
  );
}
