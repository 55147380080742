import { useState } from "react";
import Layout from "../../components/layouts/Layout";
import changePasswordStyle from "./ChangePassword.module.css";
import { useParams } from "react-router-dom";
import ChangePasswordHeader from "./components/ChangePasswordHeader";
import SuccessChangePasswordComponent from "./components/SuccessChangePasswordComponent";
import ChangePasswordComponent from "./components/ChangePasswordComponent";
import { changePassword } from "../../Api/api-libs";

export default function ChangePassword() {
  const { tokenEmail } = useParams();
  const [users_password, setUserPassword] = useState("");
  const [users_password_confirmation, setUserPasswordConfirmation] =
    useState("");
  const [msgResponse, setMsgResponse] = useState("");
  const [succesChangePassword, setSuccessChangePassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState([]);

  const changePasswordHandler = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const body = {
        users_password: users_password,
        users_password_confirmation: users_password_confirmation,
      };
      const response = await changePassword(body, tokenEmail);

      if (response.status === 200) {
        setMsgResponse("");
        setSuccessChangePassword(true);
      }
    } catch (error) {
      if (error.response.status === 400) {
        setMsgResponse(error.response.data.message);
      }
      setValidation(error.response.data);
    }

    setLoading(false);
  };

  return (
    <>
      <Layout title="Change Password">
        <div className={changePasswordStyle.body}>
          <div className={changePasswordStyle.mainWrapper}>
            <div className={changePasswordStyle.forgotPasswordCard}>
              <ChangePasswordHeader msgResponse={msgResponse} />
              {!succesChangePassword && (
                <ChangePasswordComponent
                  onSubmit={changePasswordHandler}
                  users_password={users_password}
                  setUserPassword={setUserPassword}
                  validation={validation}
                  users_password_confirmation={users_password_confirmation}
                  setUserPasswordConfirmation={setUserPasswordConfirmation}
                  loading={loading}
                />
              )}
              {succesChangePassword && <SuccessChangePasswordComponent />}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
