export function titleParser(str) {
  if (str) {
    var title = str.replace(/[^a-z0-9+]+/gi, '-').toLowerCase()
    return title
  } else {
    return 'job-position'
  }
}

export function randomString(length) {
  var result = ''
  var characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  var charactersLength = characters.length
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}
