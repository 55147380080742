import { Route, Routes, Navigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { AuthContext } from "./context/AuthContext";
import LandingPage from "./pages/LandingPage/LandingPage";
import Login from "./pages/Login/Login";
import Register from "./pages/Register/Register";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import ChangePassword from "./pages/ForgotPassword/ChangePassword";
import LokerIndex from "./pages/LowonganKerja/LokerIndex";
import NotFound from "./pages/NotFound/NotFound";
import DetailLowonganKerja from "./pages/LowonganKerja/LokerPage/DetailLowonganKerja";
import DetailPerusahaanIndex from "./pages/LowonganKerja/PerusahaanPage/DetailPerusahaanIndex";
import Magang from "./pages/Magang/Magang";
import UserProfileIndex from "./pages/UserProfile/UserProfileIndex";
import StatusLamaranIndex from "./pages/StatusLamaran/StatusLamaranIndex";
import Feedback from "./pages/Feedback/Feedback";
import Lamar from "./pages/Lamar/Lamar";
import EmailVerification from "./pages/EmailVerification/EmailVerification";
import { getTokenDuration, getUser } from "./utils/auth";

function App() {
  const user = getUser();
  const [isAuth, setIsAuth] = useState(user?.isAuth);

  useEffect(() => {
    if (getUser()) {
      if (getTokenDuration() < 0) {
        localStorage.removeItem("user");
        window.location.reload("/login");
      }

      // console.log(getTokenDuration());
    }
  }, []);

  return (
    <AuthContext.Provider value={{ isAuth, setIsAuth }}>
      <Routes>
        {isAuth && (
          <>
            <Route path="/" element={<LandingPage />} />
            <Route path="/lowongan-kerja" element={<LokerIndex />} />
            <Route
              path="/lowongan-kerja/:titleJob/:jobId"
              element={<DetailLowonganKerja />}
            />
            <Route
              path="/perusahaan/:companyName/:companyId"
              element={<DetailPerusahaanIndex />}
            />
            <Route path="/lamar/:jobId" element={<Lamar />} />
            <Route path="/magang" element={<Magang />} />
            <Route path="/profile" element={<UserProfileIndex />} />
            <Route path="/status-lamaran" element={<StatusLamaranIndex />} />
            <Route path="/feedback" element={<Feedback />} />
            <Route
              path="/email-verification/:tokenEmail"
              element={<EmailVerification />}
            />
          </>
        )}
        {!isAuth && (
          <>
            <Route path="/" element={<LandingPage />} />
            <Route path="/lowongan-kerja" element={<LokerIndex />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route
              path="/change-password/:tokenEmail"
              element={<ChangePassword />}
            />
            <Route path="/lowongan-kerja" element={<LokerIndex />} />
            <Route
              path="/lowongan-kerja/:titleJob/:jobId"
              element={<DetailLowonganKerja />}
            />
            <Route
              path="/perusahaan/:companyName/:companyId"
              element={<DetailPerusahaanIndex />}
            />
            <Route path="/magang" element={<Magang />} />
          </>
        )}
        <Route
          path="*"
          element={isAuth ? <NotFound /> : <Navigate to={"/login"} />}
        />
      </Routes>
    </AuthContext.Provider>
  );
}

export default App;
