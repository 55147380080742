import { React, useState, useRef, useEffect, useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import styleNav from "./Navbar.module.css";
import {
  IoPersonSharp,
  IoCloseSharp,
  IoBriefcaseSharp,
  IoLogOut,
} from "react-icons/io5";
import { MdFeedback } from "react-icons/md";
import { HiArrowRight } from "react-icons/hi";
import { GoThreeBars } from "react-icons/go";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import api from "../../Api/api";
import { AuthContext } from "../../context/AuthContext";
import { getAuthToken, getUser } from "../../utils/auth";

export default function Navbar() {
  const [dropdown, setDropdown] = useState(false);
  const [menuResponsive, setMenuResponsive] = useState(false);

  const { isAuth, setIsAuth } = useContext(AuthContext);

  const wrapperRef = useRef(null);
  const fullname = getUser()?.user_fullname;
  let navigate = useNavigate();

  const handleDropdown = () => {
    setDropdown(!dropdown);
  };

  const handleMenuResponsive = () => {
    setMenuResponsive(!menuResponsive);
  };

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setDropdown(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const logoutHandler = (e) => {
    e.preventDefault();

    const token = getAuthToken();

    axios
      .get(api.logout, {
        headers: {
          "Content-type": "application/json",
          Authorization: `bearer ${token}`,
        },
      })
      .then(() => {
        setIsAuth(false);
        localStorage.removeItem("user");
        navigate("/login");
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useOutsideAlerter(wrapperRef);

  const navigateProfile = () => {
    navigate("/profile");
  };

  const navigateApply = () => {
    navigate("/status-lamaran");
  };

  const navigateFeedback = () => {
    navigate("/feedback");
  };

  return (
    <div className={styleNav.container}>
      <nav className={styleNav.navbarMain}>
        <div className={styleNav.leftSectionNav}>
          <Link to="/">
            <img
              src="/images/logo-lemondial.png"
              className={styleNav.logoNav}
              alt="logo-lemondial"
            />
          </Link>
          <div className={styleNav.link}>
            <NavLink
              to="/lowongan-kerja"
              className={({ isActive }) =>
                isActive
                  ? `${styleNav.activeLink}`
                  : `${styleNav.notActiveLink}`
              }
            >
              Job Vacancies
            </NavLink>
            <NavLink
              to="/magang"
              className={({ isActive }) =>
                isActive
                  ? `${styleNav.activeLink}`
                  : `${styleNav.notActiveLink}`
              }
            >
              Internship
            </NavLink>
          </div>
        </div>
        <div className={styleNav.rightSectionNav}>
          <div className={styleNav.web}>
            {isAuth && (
              <>
                {/* <div>
                  <IoNotifications />
                </div> */}
                <div ref={wrapperRef} style={{ position: "relative" }}>
                  <button
                    className={`${styleNav.userSection} hover:text-[#F39058]`}
                    onClick={handleDropdown}
                  >
                    <IoPersonSharp />
                    <span className={styleNav.user}>
                      {fullname?.split(" ")[0]}
                    </span>
                  </button>
                  <div
                    className={
                      dropdown
                        ? `${styleNav.menu} ${styleNav.show}`
                        : styleNav.menu
                    }
                  >
                    <div
                      onClick={navigateProfile}
                      className={styleNav.menu__content}
                    >
                      <IoPersonSharp /> Profil
                    </div>
                    <div
                      onClick={navigateApply}
                      className={styleNav.menu__content}
                    >
                      <IoBriefcaseSharp /> Apply status
                    </div>
                    <div
                      onClick={navigateFeedback}
                      className={styleNav.menu__content}
                    >
                      <MdFeedback /> Feedback
                    </div>
                    <div
                      onClick={logoutHandler}
                      className={`${styleNav.menu__content} text-danger`}
                    >
                      <IoLogOut /> Logout
                    </div>
                  </div>
                </div>
              </>
            )}

            {!isAuth && (
              <div className={styleNav.notAuth}>
                <NavLink
                  to="/login"
                  className={({ isActive }) =>
                    isActive
                      ? `${styleNav.activeLink}`
                      : `${styleNav.notActiveLink}`
                  }
                >
                  Sign In
                </NavLink>
                /
                <NavLink
                  to="/register"
                  className={({ isActive }) =>
                    isActive
                      ? `${styleNav.activeLink}`
                      : `${styleNav.notActiveLink}`
                  }
                >
                  Sign Up
                </NavLink>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="http://company.lemondialcareer.com"
                >
                  <div className={styleNav.companyBtn}>
                    For Company <HiArrowRight />
                  </div>
                </a>
              </div>
            )}
          </div>
          <div className={styleNav.mobile}>
            {/* {isAuth && (
              <div>
                <IoNotifications />
              </div>
            )} */}
            <div onClick={handleMenuResponsive}>
              <GoThreeBars />
            </div>
          </div>
        </div>
      </nav>
      <div
        className={
          menuResponsive
            ? `${styleNav.menuResponsive} ${styleNav.open}`
            : styleNav.menuResponsive
        }
      >
        <div className={styleNav.close} onClick={handleMenuResponsive}>
          <IoCloseSharp />
        </div>
        <div className={styleNav.menuResWrap}>
          {isAuth && (
            <div className={styleNav.authResponsive}>
              <div className={styleNav.userResponsive}>
                <IoPersonSharp />
                <span className={styleNav.user}>{fullname?.split(" ")[0]}</span>
              </div>
              <hr />
              <Link to="/profile">
                <div className={styleNav.menuResWrap__content}>
                  <IoPersonSharp /> Profil
                </div>
              </Link>
              <Link to="/status-lamaran">
                <div className={styleNav.menuResWrap__content}>
                  <IoBriefcaseSharp /> Apply status
                </div>
              </Link>
              <Link to="/feedback">
                <div className={styleNav.menuResWrap__content}>
                  <MdFeedback /> Feedback
                </div>
              </Link>
              <div
                onClick={logoutHandler}
                className={`text-danger ${styleNav.menuResWrap__content}`}
              >
                <IoLogOut /> Logout
              </div>
            </div>
          )}
          {!isAuth && (
            <div className={styleNav.authResponsive}>
              <div className={styleNav.menuResWrap__link}>
                <Link to="/login">Sign In</Link>
              </div>
              <div className={styleNav.menuResWrap__link}>
                <Link to="/register">Sign Up</Link>
              </div>
              <a
                target="_blank"
                rel="noreferrer"
                href="http://company.lemondialcareer.com"
              >
                <div>For Company</div>
              </a>
            </div>
          )}
          <hr />
          <div className={styleNav.menuResWrap__link}>
            <Link to="/lowongan-kerja">Job Vacancies</Link>
          </div>
          <div className={styleNav.menuResWrap__link}>
            <Link to="/magang">Internship</Link>
          </div>
        </div>
      </div>
    </div>
  );
}
