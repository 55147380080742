import { React, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import style from "../LokerPage/LowonganKerja.module.css";
import styleCompany from "./Perusahaan.module.css";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import axios from "axios";
import api from "../../../Api/api";
import Select from "react-select";
import WindowedSelect from "react-windowed-select";
import CardPerusahaan from "./CardPerusahaan";
import { ScrollTop } from "../../../utils/ScrollTop";
import { titleParser } from "../../../utils/helper";

export default function Perusahaan() {
  const [perusahaan, setPerusahaan] = useState([]);
  const [location, setLocation] = useState([]);
  const [country, setCountry] = useState([]);
  const [countryValue, setCountryValue] = useState("");
  const [dataCompany, setDataCompany] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showScrollBtn, setShowScrollBtn] = useState(false);
  const [namaPerusahaanValue, setNamaPerusahaanValue] = useState("");
  const [lokasiValue, setLokasiValue] = useState("");
  const [page, setPage] = useState(1);
  const [loadingPage, setLoadingPage] = useState(false);
  const [totalDataCompany, setTotalDataCompany] = useState("");

  useEffect(() => {
    setIsLoading(true);
    ScrollTop();
    fetchDataAutoCompleteFilter();
    fetchDataCompany();

    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowScrollBtn(true);
      } else {
        setShowScrollBtn(false);
      }
    });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    ScrollTop();
    fetchDataCompany();
    setPage(1);
  }, [namaPerusahaanValue, lokasiValue]);

  useEffect(() => {
    setLoadingPage(true);
    fetchDataCompany();
  }, [page]);

  const fetchDataCompany = async () => {
    const filterLocation = lokasiValue ? lokasiValue?.value : "";

    const url = `${api.perusahaan}?nama_perusahaan=${namaPerusahaanValue}&lokasi=${filterLocation}&page=${page}`;

    await axios
      .get(url)
      .then((response) => {
        setIsLoading(false);
        setLoadingPage(false);
        if (page === 1) {
          setDataCompany(response.data?.perusahaan);
          setTotalDataCompany(response.data?.total_data?.total);
        } else {
          setDataCompany([...dataCompany, ...response.data.perusahaan]);
          setTotalDataCompany(response.data.total_data.total);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setLoadingPage(false);
        setDataCompany(error.response.data.perusahaan.data);
        setTotalDataCompany(error.response.data.perusahaan.total);
      });
  };

  // fetch data autocomplete profesi and lokasi
  const fetchDataAutoCompleteFilter = async () => {
    await axios
      .get(api.autoCompletePerusahaan)
      .then((response) => {
        setPerusahaan(response.data.perusahaan);
        setCountry(response.data.country);
        setLocation(response.data.lokasi);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // set data for profesi and lokasi options autocomplete
  const perusahaanOptions = perusahaan.map((item) => {
    return { id: item.company_id, name: item.company_name };
  });

  const countryOptions = country.map((item) => {
    return { value: item.country_id, label: item.country_name };
  });

  const lokasiOptions = location
    .filter((c) => c.country_id === parseInt(countryValue?.value))
    .map((item) => {
      return { value: item.city_id, label: item.city_name };
    });

  // handle for input search profesi and lokasi job
  const handleLocation = (value) => {
    setLokasiValue(value);
  };

  const handleOnSearch = (string, results) => {
    setNamaPerusahaanValue(string);
  };

  const handleOnSelect = (item) => {
    setNamaPerusahaanValue(item.name);
  };

  // custom style for react select
  const customStyles = {
    control: (base) => ({
      ...base,
      height: 48,
      borderRadius: 10,
    }),
    option: (provided) => ({
      ...provided,
      color: "black",
    }),
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "#f7f7f7",
          paddingBottom: "120px",
        }}
      >
        <div className={style.firstContainer}>
          <div className={style.firstContainerWrap}>
            <div className={style.sectionSearch}>
              <div className={style.sectionSearch__profession}>
                <ReactSearchAutocomplete
                  items={perusahaanOptions}
                  onSearch={handleOnSearch}
                  onSelect={handleOnSelect}
                  placeholder="Company name"
                  showIcon={false}
                  styling={{
                    lineColor: "#cccccc",
                    border: "1px solid #cccccc",
                    borderRadius: "10px",
                    zIndex: 4,
                  }}
                />
              </div>
              <div className="w-[100%] md:w-[35%]">
                <Select
                  styles={customStyles}
                  options={countryOptions}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "#cccccc",
                      primary: "#cccccc",
                    },
                  })}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  value={countryValue}
                  isClearable="true"
                  placeholder={"Country"}
                  onChange={(e) => setCountryValue(e)}
                />
              </div>
              <div className={style.sectionSearch__location}>
                <WindowedSelect
                  styles={customStyles}
                  options={lokasiOptions}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "#cccccc",
                      primary: "#cccccc",
                    },
                  })}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  value={lokasiValue}
                  isClearable="true"
                  placeholder={"Location"}
                  onChange={handleLocation}
                />
              </div>
            </div>
          </div>
        </div>
        {!isLoading && (
          <>
            {dataCompany?.length > 0 && (
              <>
                <div className={styleCompany.companiesWrapper}>
                  {dataCompany?.map((data, index) => {
                    const companySlug = titleParser(data.company_name);
                    return (
                      <a
                        target="_blank"
                        href={`/perusahaan/${companySlug}/${data.company_id}`}
                        key={index}
                        rel="noreferrer"
                      >
                        <CardPerusahaan
                          logo={data.company_photo}
                          company={data.company_name}
                          description={data.company_description}
                          location={data.city_name}
                          category={data.company_category_name}
                          hiring={data.company_hiring}
                          key={index}
                        />
                      </a>
                    );
                  })}
                </div>
                {loadingPage && (
                  <div className="flex justify-center">
                    <div className="w-full pt-20">
                      <div className="flex items-center justify-center">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {!loadingPage && dataCompany?.length !== totalDataCompany && (
                  <div className="flex justify-center">
                    <button
                      className="bg-[#323232] text-white mt-5 rounded-full py-2 px-5 max-w-[400px] text-sm"
                      onClick={() => setPage(page + 1)}
                    >
                      Show more
                    </button>
                  </div>
                )}
                {totalDataCompany === dataCompany?.length && (
                  <div className="flex justify-center mt-5 font-semibold">
                    End of page
                  </div>
                )}
              </>
            )}

            {dataCompany?.length === 0 && (
              <div className="w-full px-10 pt-40">
                <div className="flex items-center justify-center gap-4 font-semibold">
                  <img src="/images/noData.png" alt="data-not-found" /> Data not
                  found
                </div>
              </div>
            )}
          </>
        )}
        {isLoading && (
          <>
            <div className="w-full pt-20">
              <div className="flex items-center justify-center">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {showScrollBtn && (
        <div className={style.scrollTop}>
          <button onClick={() => ScrollTop()}>Scroll to top</button>
        </div>
      )}
    </>
  );
}
