import { useState } from "react";
import Layout from "../../components/layouts/Layout";
import { Menu } from "./Data/Menu";
import { MenuContent } from "./Data/MenuContent";

function StatusLamaranIndex() {
  const [menuActive, setMenuActive] = useState(1);

  return (
    <>
      <Layout title="Apply Status">
        <div className="w-full pt-[100px] xl:px-[100px] px-[20px]">
          <div className="flex justify-start mb-4 text-3xl font-semibold">
            <div className="border-b-4 border-[#F39058]">Apply Status</div>
          </div>
          <div className="flex flex-col xl:flex-row gap-4 min-h-[100vh] pt-[20px] pb-[60px] mb-[30px]">
            <div className="w-full xl:w-[22%] font-medium flex xl:flex-col overflow-auto">
              {Menu.map((m, i) => {
                return (
                  <li
                    key={i}
                    onClick={() => setMenuActive(m.id)}
                    className={
                      menuActive === m.id
                        ? "m-3 py-[5px] xl:px-4 text-lg list-none border-b-4 xl:border-l-4 xl:border-b-0 border-[#F39058] cursor-pointer"
                        : "m-3 py-[5px] xl:px-4 text-lg list-none cursor-pointer hover:text-[#F39058] border-b-4 xl:border-l-4 xl:border-b-0 border-[#f7f7f7]"
                    }
                  >
                    {m.menu}
                  </li>
                );
              })}
            </div>

            <div className="w-ful xl:w-[78%] xl:border-l-[1px] xl:border-[#D6D6D6]">
              {MenuContent.filter((m) => m.id === menuActive).map((m, i) => {
                return <div key={i}>{m.component}</div>;
              })}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default StatusLamaranIndex;
