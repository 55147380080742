import React from 'react'
import { AiFillStar } from 'react-icons/ai'

export const starValue = (value) => {
  if (value === 1) {
    return (
      <div className="flex text-[#C4C4C4]">
        <AiFillStar className="text-[#F39058]" />
        <AiFillStar />
        <AiFillStar />
        <AiFillStar />
        <AiFillStar />
      </div>
    )
  } else if (value === 2) {
    return (
      <div className="flex text-[#C4C4C4]">
        <AiFillStar className="text-[#F39058]" />
        <AiFillStar className="text-[#F39058]" />
        <AiFillStar />
        <AiFillStar />
        <AiFillStar />
      </div>
    )
  } else if (value === 3) {
    return (
      <div className="flex text-[#C4C4C4]">
        <AiFillStar className="text-[#F39058]" />
        <AiFillStar className="text-[#F39058]" />
        <AiFillStar className="text-[#F39058]" />
        <AiFillStar />
        <AiFillStar />
      </div>
    )
  } else if (value === 4) {
    return (
      <div className="flex text-[#C4C4C4]">
        <AiFillStar className="text-[#F39058]" />
        <AiFillStar className="text-[#F39058]" />
        <AiFillStar className="text-[#F39058]" />
        <AiFillStar className="text-[#F39058]" />
        <AiFillStar />
      </div>
    )
  } else if (value === 5) {
    return (
      <div className="flex text-[#C4C4C4]">
        <AiFillStar className="text-[#F39058]" />
        <AiFillStar className="text-[#F39058]" />
        <AiFillStar className="text-[#F39058]" />
        <AiFillStar className="text-[#F39058]" />
        <AiFillStar className="text-[#F39058]" />
      </div>
    )
  }
}
