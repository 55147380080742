import { useState } from "react";
import Layout from "../../components/layouts/Layout";
import { Menu } from "./Menu";
import Tab from "./Tab";

function LokerIndex() {
  const [menuActive, setMenuActive] = useState(1);

  return (
    <>
      <Layout title="Job Vacancies & Companies">
        <Tab menuActive={menuActive} setMenuActive={setMenuActive} />
        {Menu.filter((m) => m.id === menuActive).map((m, i) => {
          return <div key={i}>{m.component}</div>;
        })}
      </Layout>
    </>
  );
}

export default LokerIndex;
