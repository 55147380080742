import Navbar from "./Navbar";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { ScrollTop } from "../../utils/ScrollTop";

function Layout({ children, title }) {
  useEffect(() => {
    ScrollTop();
  }, []);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Navbar />
      {children}
      <Footer />
    </>
  );
}

export default Layout;
