import style from "../LowonganKerja.module.css";
import Select from "react-select";
import WindowedSelect from "react-windowed-select";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import axios from "axios";
import api from "../../../../Api/api";
import { useEffect, useState } from "react";

export default function Search({ onSearch, onSelect, onChange, filterLokasi }) {
  const [categoryJob, setCategoryJob] = useState([]);
  const [country, setCountry] = useState([]);
  const [location, setLocation] = useState([]);
  const [countryValue, setCountryValue] = useState("");

  useEffect(() => {
    fetchDataAutoCompleteFilter();
  }, []);

  // fetch data autocomplete profesi and lokasi
  const fetchDataAutoCompleteFilter = async () => {
    await axios
      .get(api.autoComplete)
      .then((response) => {
        setCategoryJob(response.data.profesi);
        setCountry(response.data.country);
        setLocation(response.data.lokasi);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // set data for profesi and lokasi options autocomplete
  const profesiOptions = categoryJob.map((item) => {
    return { id: item.company_jobs_id, name: item.company_jobs_position };
  });

  const countryOptions = country.map((item) => {
    return { value: item.country_id, label: item.country_name };
  });

  const lokasiOptions = location
    .filter((c) => c.country_id === parseInt(countryValue?.value))
    .map((item) => {
      return { value: item.city_id, label: item.city_name };
    });

  // custom style for react select
  const customStyles = {
    control: (base) => ({
      ...base,
      height: 48,
      borderRadius: 10,
    }),
    option: (provided) => ({
      ...provided,
      color: "black",
    }),
  };

  return (
    <div className={style.firstContainer}>
      <div className={style.firstContainerWrap}>
        <div className={style.sectionSearch}>
          <div className={style.sectionSearch__profession}>
            <ReactSearchAutocomplete
              items={profesiOptions}
              onSearch={onSearch}
              onSelect={onSelect}
              placeholder="Position"
              showIcon={false}
              styling={{
                lineColor: "#cccccc",
                border: "1px solid #cccccc",
                borderRadius: "10px",
                zIndex: 4,
              }}
            />
          </div>
          <div className="w-[100%] md:w-[35%]">
            <Select
              styles={customStyles}
              options={countryOptions}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "#cccccc",
                  primary: "#cccccc",
                },
              })}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              value={countryValue}
              isClearable="true"
              placeholder={"Country"}
              onChange={(e) => setCountryValue(e)}
            />
          </div>
          <div className={style.sectionSearch__location}>
            <WindowedSelect
              styles={customStyles}
              options={lokasiOptions}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "#cccccc",
                  primary: "#cccccc",
                },
              })}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              value={filterLokasi}
              isClearable="true"
              placeholder={"City"}
              onChange={onChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
