import { useState } from "react";
import forgotPasswordStyle from "./ForgotPassword.module.css";
import Layout from "../../components/layouts/Layout";
import { forgotPassword } from "../../Api/api-libs";
import SuccessForgotPasswordComponent from "./components/SuccessForgotPasswordComponent";
import ForgotPasswordComponent from "./components/ForgotPasswordComponent";

export default function ForgotPassword() {
  const [users_email, setUsersEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [msgResponse, setMsgResponse] = useState("");
  const [succesSentEmail, setSuccessSentEmail] = useState(false);

  const forgotPasswordHandle = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await forgotPassword({ users_email: users_email });
      if (response.status === 200) {
        setMsgResponse("");
        setSuccessSentEmail(true);
      }
    } catch (error) {
      setMsgResponse("");
      if (error.response.status === 404) {
        setMsgResponse(error.response.data.message);
      }
    }

    setLoading(false);
  };
  return (
    <>
      <Layout title="Forgot Password">
        <div className={forgotPasswordStyle.body}>
          <div className={forgotPasswordStyle.mainWrapper}>
            <div className={forgotPasswordStyle.forgotPasswordCard}>
              {!succesSentEmail && (
                <ForgotPasswordComponent
                  msgResponse={msgResponse}
                  onSubmit={forgotPasswordHandle}
                  users_email={users_email}
                  setUsersEmail={setUsersEmail}
                  loading={loading}
                />
              )}
              {succesSentEmail && (
                <SuccessForgotPasswordComponent users_email={users_email} />
              )}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
