import Card from "../components/Card";
import { titleParser } from "../../../../utils/helper";
import style from "../LowonganKerja.module.css";
import Loading from "./Loading";

export default function Job({
  dataJob,
  loadingPage,
  totalDataJob,
  page,
  setPage,
}) {
  return (
    <div className={style.jobWrap}>
      <div className={style.jobGrid}>
        {dataJob?.map((data, index) => {
          const companyJobSlug = titleParser(data.company_jobs_position);
          return (
            <a
              target="_blank"
              href={`/lowongan-kerja/${companyJobSlug}/${data.company_jobs_id}`}
              key={index}
              rel="noreferrer"
            >
              <Card
                logo={data.company_photo}
                company={data.company_name}
                jobTitle={data.company_jobs_position}
                location={data.city_name}
                workType={data.jobs_type_name}
                salaryRange={data.company_jobs_salary_range}
                minSalary={data.company_jobs_salary_min}
                maxSalary={data.company_jobs_salary_max}
                created={data.company_jobs_created_date}
                updated={data.company_jobs_updated_date}
                hideSalary={data.company_jobs_salary_hide}
                currency={data.company_jobs_salary_currency}
                key={index}
              />
            </a>
          );
        })}
      </div>
      {loadingPage && (
        <div className="flex justify-center">
          <Loading />
        </div>
      )}
      {!loadingPage && dataJob?.length !== totalDataJob && (
        <div className="flex justify-center">
          <button
            className="bg-[#323232] text-white mt-5 rounded-full py-2 px-5 max-w-[400px] text-sm hover:bg-[#5e5e5e]"
            onClick={() => setPage(page + 1)}
          >
            Show more
          </button>
        </div>
      )}
      {totalDataJob === dataJob?.length && (
        <div className="flex justify-center mt-5 font-semibold">
          End of page
        </div>
      )}
    </div>
  );
}
