import { useState, useEffect, useContext } from "react";
import Layout from "../../components/layouts/Layout";
import style from "./Magang.module.css";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import Select from "react-select";
import WindowedSelect from "react-windowed-select";
import { ImOffice } from "react-icons/im";
import { FaMoneyBillWave } from "react-icons/fa";
import api from "../../Api/api";
import axios from "axios";
import moment from "moment";
import { ScrollTop } from "../../utils/ScrollTop";
import { BsArrowLeft } from "react-icons/bs";
import { RiAlertFill } from "react-icons/ri";
import { AuthContext } from "../../context/AuthContext";
import AES from "crypto-js/aes";
import { Markup } from "interweave";

export default function Magang() {
  const { isAuth } = useContext(AuthContext);
  const [usersVerify, setUsersVerify] = useState("");
  const [usersApply, setUsersApply] = useState([]);

  const [posisi, setPosisi] = useState([]);
  const [country, setCountry] = useState([]);
  const [countryValue, setCountryValue] = useState("");
  const [lokasi, setLokasi] = useState([]);
  const [filterPosisi, setFilterPosisi] = useState("");
  const [filterLokasi, setFilterLokasi] = useState("");
  const [activeJob, setActiveJob] = useState("");
  const [dataMagang, setDataMagang] = useState([]);
  const [companyJobsId, setCompanyJobsId] = useState();
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [showDetailMagang, setShowDetailMagang] = useState(false);

  // momment js preset
  moment.locale("en");

  useEffect(() => {
    setLoading(true);
    ScrollTop();
    fetchDataMagang();
    fetchDataAutoCompleteFilter();
  }, []);

  useEffect(() => {
    if (isAuth) {
      checkUser();
    }
  }, [companyJobsId]);

  useEffect(() => {
    setLoading(true);
    ScrollTop();
    fetchDataMagang();
    setPage(1);
  }, [filterPosisi, filterLokasi]);

  useEffect(() => {
    setLoadingPage(true);
    fetchDataMagang();
  }, [page]);

  // check user
  const checkUser = async () => {
    const users = JSON.parse(localStorage.getItem("user"));
    const url = `${api.checkUser}?users_email=${users?.user_email}&company_jobs_id=${companyJobsId}`;

    await axios
      .get(url)
      .then((response) => {
        setUsersApply(response.data.data_apply);
        setUsersVerify(response.data.data_verify.users_verified_status_id);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // encrypt
  const encryptId = (str) => {
    const ciphertext = AES.encrypt(str, "secretPassphrase");
    return encodeURIComponent(ciphertext.toString());
  };

  // fetch data magang
  const fetchDataMagang = async () => {
    const filterLocation = filterLokasi ? filterLokasi?.value : "";

    const url = `${api.magang}?posisi=${filterPosisi}&lokasi=${filterLocation}&page=${page}`;

    await axios
      .get(url)
      .then((response) => {
        setLoading(false);
        setLoadingPage(false);
        if (page === 1) {
          setDataMagang(response.data.data_magang.data);
          setTotal(response.data.data_magang.total);
          setActiveJob(response.data.data_magang.data[0].company_jobs_id);
          setCompanyJobsId(
            response?.data?.data_magang?.data[0]?.company_jobs_id
          );
        } else {
          setDataMagang([...dataMagang, ...response.data.data_magang.data]);
          setTotal(response.data.data_magang.total);
        }
      })
      .catch((error) => {
        setLoading(false);
        setLoadingPage(false);
        console.log(error);
      });
  };

  // fetch data autocomplete posisi and lokasi
  const fetchDataAutoCompleteFilter = async () => {
    await axios
      .get(api.autoCompleteMagang)
      .then((response) => {
        setPosisi(response.data.posisi);
        setCountry(response.data.country);
        setLokasi(response.data.lokasi);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // set data for posisi and lokasi options autocomplete
  const posisiOptions = posisi.map((item) => {
    return { id: item.company_jobs_id, name: item.company_jobs_position };
  });

  const countryOptions = country.map((item) => {
    return { value: item.country_id, label: item.country_name };
  });

  const lokasiOptions = lokasi
    .filter((c) => c.country_id === parseInt(countryValue?.value))
    .map((item) => {
      return { value: item.city_id, label: item.city_name };
    });

  // handle for input search profesi and lokasi job
  const handleLocation = (value) => {
    setFilterLokasi(value);
  };

  const handleOnSearch = (string, results) => {
    setFilterPosisi(string);
  };

  const handleOnSelect = (item) => {
    setFilterPosisi(item.name);
  };

  // custom style for react select
  const customStyles = {
    control: (base) => ({
      ...base,
      height: 48,
      borderRadius: 10,
    }),
    option: (provided) => ({
      ...provided,
      color: "black",
    }),
  };
  return (
    <>
      <Layout title="Internship">
        <div
          style={{
            backgroundColor: "#f7f7f7",
            paddingBottom: "120px",
            paddingTop: "75px",
          }}
        >
          <div className={style.firstContainer}>
            <div className={style.firstContainerWrap}>
              <div className={style.sectionSearch}>
                <div className={style.sectionSearch__profession}>
                  <ReactSearchAutocomplete
                    items={posisiOptions}
                    onSearch={handleOnSearch}
                    onSelect={handleOnSelect}
                    placeholder="Position"
                    showIcon={false}
                    styling={{
                      lineColor: "#cccccc",
                      border: "1px solid #cccccc",
                      borderRadius: "10px",
                      zIndex: 4,
                    }}
                  />
                </div>
                <div className="w-[100%] md:w-[35%]">
                  <Select
                    styles={customStyles}
                    options={countryOptions}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: "#cccccc",
                        primary: "#cccccc",
                      },
                    })}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    value={countryValue}
                    isClearable="true"
                    placeholder={"City"}
                    onChange={(e) => setCountryValue(e)}
                  />
                </div>
                <div className={style.sectionSearch__location}>
                  <WindowedSelect
                    styles={customStyles}
                    options={lokasiOptions}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: "#cccccc",
                        primary: "#cccccc",
                      },
                    })}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    value={filterLokasi}
                    isClearable="true"
                    placeholder={"Location"}
                    onChange={handleLocation}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="px-[15px] xl:px-[50px] py-[20px]">
            {!loading && (
              <>
                <div className="mb-[20px]">{total} results found</div>
                <div className="flex gap-4">
                  {dataMagang?.length > 0 && (
                    <>
                      {/* data magang responsive */}
                      <div className="block w-full xl:w-[600px] h-[120vh] overflow-auto xl:hidden">
                        <div className="grid grid-cols-1 xl:pr-4 md:grid-cols-2 md:gap-4 xl:grid-cols-1">
                          {dataMagang?.map((data, index) => {
                            return (
                              <div
                                key={index}
                                onClick={() => {
                                  setActiveJob(data.company_jobs_id);
                                  setCompanyJobsId(data.company_jobs_id);
                                  setShowDetailMagang(!showDetailMagang);
                                  ScrollTop();
                                }}
                                className={
                                  activeJob === data.company_jobs_id
                                    ? "border-[#FE5D40] max-w-[400px] p-3 border-4   rounded-lg mb-3 bg-white cursor-pointer"
                                    : "border-[#BFBFBF] max-w-[400px] p-3 border-4   rounded-lg mb-3 bg-white cursor-pointer"
                                }
                              >
                                <div className="font-semibold">
                                  {data.company_jobs_position}
                                </div>
                                <div className="mb-8 text-sm">
                                  {data.company_name}
                                </div>
                                <div className="text-sm">{data.city_name}</div>
                                {data.company_jobs_salary_hide === "false" &&
                                  data.company_jobs_salary_range === "true" && (
                                    <div className="text-sm font-semibold">
                                      {data.company_jobs_salary_currency} &nbsp;
                                      {data.company_jobs_salary_min.toLocaleString()}{" "}
                                      -{" "}
                                      {data.company_jobs_salary_max.toLocaleString()}
                                    </div>
                                  )}
                                {data.company_jobs_salary_hide === "false" &&
                                  data.company_jobs_salary_range ===
                                    "false" && (
                                    <div className="text-sm font-semibold">
                                      {data.company_jobs_salary_currency} &nbsp;
                                      {data.company_jobs_salary_max.toLocaleString()}{" "}
                                    </div>
                                  )}
                                {!data.company_jobs_updated_date && (
                                  <div className="mt-8 text-xs font-semibold">
                                    Posted{" "}
                                    {moment(
                                      data.company_jobs_verified_date,
                                      "YYYYMMDD"
                                    ).fromNow()}
                                  </div>
                                )}
                                {data.company_jobs_updated_date && (
                                  <div className="mt-8 text-xs font-semibold">
                                    Updated{" "}
                                    {moment(
                                      data.company_jobs_updated_date,
                                      "YYYYMMDD"
                                    ).fromNow()}
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                        {loadingPage && (
                          <div className="flex justify-center">
                            <div className="w-full pt-20">
                              <div className="flex items-center justify-center">
                                <div className="spinner-border" role="status">
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {!loadingPage && dataMagang?.length !== total && (
                          <div className="flex justify-center">
                            <button
                              className="bg-[#323232] hover:bg-[#5e5e5e] text-white mt-5 rounded-full py-2 px-5 max-w-[400px] text-sm"
                              onClick={() => setPage(page + 1)}
                            >
                              Show more
                            </button>
                          </div>
                        )}
                        {total === dataMagang?.length && (
                          <div className="flex justify-center mt-5 font-semibold">
                            Sudah di akhir halaman
                          </div>
                        )}
                      </div>
                      {/* data magang responsive */}

                      {/* data magang non responsive */}
                      <div className="hidden w-full xl:w-[600px] h-[120vh] overflow-auto xl:block">
                        <div className="grid grid-cols-1 xl:pr-4 md:grid-cols-2 md:gap-4 xl:grid-cols-1">
                          {dataMagang?.map((data, index) => {
                            return (
                              <div
                                key={index}
                                onClick={() => {
                                  setActiveJob(data.company_jobs_id);
                                  setCompanyJobsId(data.company_jobs_id);
                                }}
                                className={
                                  activeJob === data.company_jobs_id
                                    ? "border-[#FE5D40] max-w-[400px] p-3 border-4   rounded-lg mb-3 bg-white cursor-pointer"
                                    : "border-[#BFBFBF] max-w-[400px] p-3 border-4   rounded-lg mb-3 bg-white cursor-pointer"
                                }
                              >
                                <div className="font-semibold">
                                  {data.company_jobs_position}
                                </div>
                                <div className="mb-8 text-sm">
                                  {data.company_name}
                                </div>
                                <div className="text-sm">{data.city_name}</div>
                                {data.company_jobs_salary_hide === "false" &&
                                  data.company_jobs_salary_range === "true" && (
                                    <div className="text-sm font-semibold">
                                      {data.company_jobs_salary_currency} &nbsp;
                                      {data.company_jobs_salary_min.toLocaleString()}{" "}
                                      -{" "}
                                      {data.company_jobs_salary_max.toLocaleString()}
                                    </div>
                                  )}
                                {data.company_jobs_salary_hide === "false" &&
                                  data.company_jobs_salary_range ===
                                    "false" && (
                                    <div className="text-sm font-semibold">
                                      {data.company_jobs_salary_currency}{" "}
                                      {data.company_jobs_salary_max.toLocaleString()}{" "}
                                    </div>
                                  )}
                                {!data.company_jobs_updated_date && (
                                  <div className="mt-8 text-xs font-semibold">
                                    Posted{" "}
                                    {moment(
                                      data.company_jobs_verified_date,
                                      "YYYYMMDD"
                                    ).fromNow()}
                                  </div>
                                )}
                                {data.company_jobs_updated_date && (
                                  <div className="mt-8 text-xs font-semibold">
                                    Updated{" "}
                                    {moment(
                                      data.company_jobs_updated_date,
                                      "YYYYMMDD"
                                    ).fromNow()}
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                        {loadingPage && (
                          <div className="flex justify-center">
                            <div className="w-full pt-20">
                              <div className="flex items-center justify-center">
                                <div className="spinner-border" role="status">
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {!loadingPage && dataMagang?.length !== total && (
                          <div className="flex justify-center">
                            <button
                              className="bg-[#323232] hover:bg-[#5e5e5e] text-white mt-5 rounded-full py-2 px-5 max-w-[400px] text-sm"
                              onClick={() => setPage(page + 1)}
                            >
                              Show more
                            </button>
                          </div>
                        )}
                        {total === dataMagang?.length && (
                          <div className="flex justify-center mt-5 font-semibold">
                            End of page
                          </div>
                        )}
                      </div>
                      {/* data magang non responsive */}

                      {/* detail Magang */}
                      <div
                        className={
                          showDetailMagang
                            ? "block absolute top-0 left-0 z-10 w-full min-h-[300vh] pt-[100px] px-4 pb-[100px] bg-white"
                            : "hidden w-full h-[120vh] overflow-auto p-4 bg-white rounded-lg shadow-md xl:block"
                        }
                      >
                        {dataMagang
                          .filter((data) => data.company_jobs_id === activeJob)
                          .map((data, index) => {
                            return (
                              <div key={index}>
                                {showDetailMagang && (
                                  <button
                                    className="mb-4 text-3xl"
                                    onClick={() =>
                                      setShowDetailMagang(!showDetailMagang)
                                    }
                                  >
                                    <BsArrowLeft />
                                  </button>
                                )}
                                <div className="flex items-center gap-4 mb-5">
                                  <img
                                    className="w-[126px]"
                                    src={
                                      data.company_photo
                                        ? `${process.env.REACT_APP_MEDIA_URL}/images/${data.company_photo}`
                                        : "/images/company_default.png"
                                    }
                                    alt="company-logo"
                                  />
                                  <div>
                                    <div className="mb-1 text-xl font-semibold">
                                      {data.company_jobs_position}
                                    </div>
                                    <div className="text-sm">
                                      {data.jobs_category_name} |{" "}
                                      {data.city_name}
                                    </div>
                                  </div>
                                </div>
                                {isAuth && (
                                  <>
                                    {usersVerify == 3 ? (
                                      <>
                                        {usersApply.length > 0 ? (
                                          <div>
                                            <button
                                              className="flex items-center gap-[5px] py-2 px-[70px] bg-[#fabc9b] text-white rounded-full mt-4 text-sm cursor-not-allowed font-semibold"
                                              disabled
                                            >
                                              Already applied
                                            </button>
                                            <div className="flex items-center gap-3 mt-3 text-xs font-semibold">
                                              <RiAlertFill className="text-[18px] text-[#f9c135]" />{" "}
                                              You already applied this job
                                            </div>
                                          </div>
                                        ) : (
                                          <a
                                            rel="noreferrer"
                                            target="_blank"
                                            href={`/lamar/${data.company_jobs_id}`}
                                          >
                                            <div className={style.apply}>
                                              Apply
                                            </div>
                                          </a>
                                        )}
                                      </>
                                    ) : (
                                      <div>
                                        <button
                                          className="flex items-center gap-[5px] py-2 px-[70px] bg-[#fabc9b] text-white rounded-full mt-4 text-sm cursor-not-allowed font-semibold"
                                          disabled
                                        >
                                          Apply
                                        </button>
                                        <div className="flex items-center gap-3 mt-3 text-xs font-semibold">
                                          <RiAlertFill className="text-[18px] text-[#f9c135]" />{" "}
                                          You can't apply this job because your
                                          profile isn't verified
                                        </div>
                                      </div>
                                    )}
                                  </>
                                )}

                                {!isAuth && (
                                  <div>
                                    <button
                                      className="flex items-center gap-[5px] py-2 px-[70px] bg-[#fabc9b] text-white rounded-full mt-4 text-sm cursor-not-allowed font-semibold"
                                      disabled
                                    >
                                      Apply
                                    </button>
                                    <div className="flex items-center gap-3 mt-3 text-xs font-semibold">
                                      <RiAlertFill className="text-[18px] text-[#f9c135]" />{" "}
                                      Login to apply this job
                                    </div>
                                  </div>
                                )}
                                <hr className="my-3" />
                                {!data.company_jobs_updated_date && (
                                  <div className="my-4 text-xs font-semibold">
                                    Posted{" "}
                                    {moment(
                                      data.company_jobs_verified_date,
                                      "YYYYMMDD"
                                    ).fromNow()}
                                  </div>
                                )}
                                {data.company_jobs_updated_date && (
                                  <div className="my-4 text-xs font-semibold">
                                    Updated{" "}
                                    {moment(
                                      data.company_jobs_updated_date,
                                      "YYYYMMDD"
                                    ).fromNow()}
                                  </div>
                                )}
                                <div className="flex items-center gap-3 mb-2">
                                  <ImOffice className="text-xl" />
                                  <p>{data.company_category_name}</p>
                                </div>
                                {data.company_jobs_salary_hide === "false" &&
                                  data.company_jobs_salary_range === "true" && (
                                    <div className="flex items-center gap-3">
                                      <FaMoneyBillWave className="text-xl" />
                                      <p>
                                        {data.company_jobs_salary_currency}{" "}
                                        {data.company_jobs_salary_min.toLocaleString()}{" "}
                                        -{" "}
                                        {data.company_jobs_salary_max.toLocaleString()}{" "}
                                        Per Month
                                      </p>
                                    </div>
                                  )}
                                {data.company_jobs_salary_hide === "false" &&
                                  data.company_jobs_salary_range ===
                                    "false" && (
                                    <div className="flex items-center gap-3">
                                      <FaMoneyBillWave className="text-xl" />
                                      <p>
                                        {data.company_jobs_salary_currency}
                                        &nbsp;
                                        {data.company_jobs_salary_max.toLocaleString()}{" "}
                                        / Bulan
                                      </p>
                                    </div>
                                  )}

                                <h1 className="mt-10 mb-3 font-semibold">
                                  Job description
                                </h1>
                                <hr />
                                <div className="mt-3">
                                  <Markup
                                    content={data.company_jobs_description}
                                  />
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </>
                  )}
                  {dataMagang.length === 0 && (
                    <div className="w-full px-10 pt-40">
                      <div className="flex items-center justify-center gap-4 font-semibold">
                        <img src="/images/noData.png" alt="data-not-found" />{" "}
                        Data not found
                      </div>
                    </div>
                  )}
                </div>
                {/* detail Magang */}
              </>
            )}
            {loading && (
              <>
                <div className="w-full pt-20 h-[120vh]">
                  <div className="flex items-center justify-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </Layout>
    </>
  );
}
