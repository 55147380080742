import React from "react";
import { FaInstagram, FaLinkedin } from "react-icons/fa";

function Footer() {
  return (
    <div className="w-full bg-[#323232] text-white text-xs">
      <div className="flex flex-col max-w-[1200px] m-auto py-[10em] px-10 lg:px-[7em] lg:flex lg:flex-row justify-between gap-6">
        <div>
          <img
            className="w-[150px] mb-8"
            src="/images/logo-lemondial-white.png"
            alt="logo-footer"
          />
          <p className="mb-2 font-semibold">
            PT. Lentera Mondial Karir (Lemondial Career)
          </p>
          <a href="https://maps.app.goo.gl/p9tk1W2sT67Xct938">
            <p>
              Jalan KH. Hasyim Ashari No.54, Petojo Utara, <br /> Kecamatan
              Gambir, DKI. Jakarta 10130.
            </p>
          </a>
        </div>
        <div className="flex flex-col gap-6 lg:flex lg:flex-row lg:gap-[8em]">
          <div>
            <h1 className="mb-4 font-semibold">Product</h1>
            <ul className="list-none">
              <li className="mb-2">
                <a href="https://www.mondy.co.id/">Mondy</a>
              </li>
              <li>
                <a href="https://www.lemondialacademy.com/">
                  Lemondial Academy
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h1 className="mb-4 font-semibold">Contact</h1>
            <ul className="list-none">
              <li className="mb-2">
                <a href="tel:+62 21 6321 789">+62 21 6321 789</a>
              </li>
              <li className="mb-1">
                <a href="mailto:info@lemondialcareer.com">
                  info@lemondialcareer.com
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h1 className="mb-4 font-semibold">Social Media</h1>
            <div className="flex gap-4 text-3xl">
              <a href="https://www.instagram.com/lemondial.career/">
                <FaInstagram />
              </a>
              <a href="https://www.linkedin.com/company/lemondial-career/">
                <FaLinkedin />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-[1200px] m-auto py-4 flex justify-end px-5 lg:px-0">
        Copyright &#169; Lemondial Career 2022
      </div>
    </div>
  );
}

export default Footer;
