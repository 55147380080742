import All from '../All'
import Interviewed from '../Interviewed'
import Menunggu from '../Menunggu'
import Rejected from '../Rejected'

export const MenuContent = [
  {
    id: 1,
    component: <All />,
  },
  {
    id: 2,
    component: <Menunggu />,
  },
  {
    id: 3,
    component: <Interviewed />,
  },
  {
    id: 4,
    component: <Rejected />,
  },
]
