import style from "../LowonganKerja.module.css";

export default function BtnFilterResponsive({ onClick }) {
  return (
    <div className={style.filterResponsiveBtnWrap}>
      <button className={style.filterResponsiveBtn} onClick={onClick}>
        Filter
      </button>
    </div>
  );
}
