import { FaUser, FaGraduationCap, FaUserCog } from "react-icons/fa";
import { ImProfile } from "react-icons/im";
import { MdHomeWork, MdPsychology } from "react-icons/md";
import { RiFileUserLine } from "react-icons/ri";
import { TbCertificate } from "react-icons/tb";

export const Menu = [
  {
    id: 1,
    menu: "My profile",
    icon: <FaUser />,
  },
  {
    id: 2,
    menu: "Personal data",
    icon: <ImProfile />,
  },
  {
    id: 3,
    menu: "Work experience",
    icon: <MdHomeWork />,
  },
  {
    id: 4,
    menu: "Education",
    icon: <FaGraduationCap />,
  },
  {
    id: 5,
    menu: "Skill",
    icon: <FaUserCog />,
  },
  {
    id: 6,
    menu: "Certificate",
    icon: <TbCertificate />,
  },
  {
    id: 7,
    menu: "Psychotest",
    icon: <MdPsychology />,
  },
  {
    id: 8,
    menu: "Resume",
    icon: <RiFileUserLine />,
  },
];
