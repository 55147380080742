import React, { useEffect } from "react";
import Layout from "../../components/layouts/Layout";
import CardFeedback from "./CardFeedback";
import { RiAlertFill } from "react-icons/ri";
import axios from "axios";
import api from "../../Api/api";
import { useState } from "react";
import { ScrollTop } from "../../utils/ScrollTop";
import { BiArrowBack } from "react-icons/bi";
import { Link } from "react-router-dom";

export default function Feedback() {
  const [dataRating, setDataRating] = useState([]);
  const [totalData, setTotalData] = useState("");
  const [totalRating, setTotalRating] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    ScrollTop();
    setLoading(true);
    fetchDataRating();
  }, []);

  useEffect(() => {
    setLoadingPage(true);
    fetchDataRating();
  }, [page]);

  const fetchDataRating = async () => {
    const users = JSON.parse(localStorage.getItem("user"));
    const url = `${api.usersRating}?users_email=${users?.user_email}&page=${page}`;

    await axios
      .get(url, {
        headers: {
          "Content-type": "application/json",
          Authorization: `bearer ${users?.token}`,
        },
      })
      .then((response) => {
        setDataRating([...dataRating, ...response?.data?.data_rating?.data]);
        setTotalRating(response.data.total_rating);
        setTotalData(response.data.data_rating.total);
        setLoading(false);
        setLoadingPage(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setLoadingPage(false);
      });
  };

  return (
    <>
      <Layout title="Feedback">
        <div className="max-w-[1000px] m-auto py-[100px] px-3 md:px-0">
          {loading && (
            <>
              <div className="w-full pt-20">
                <div className="flex items-center justify-center">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              </div>
            </>
          )}

          {!loading && (
            <>
              <div className="w-full">
                <Link to="/profile">
                  <button className="flex items-center gap-[5px] py-[6px] px-4 bg-[#F39058] text-white rounded-full hover:bg-[#f47126] mb-[35px] text-sm">
                    <BiArrowBack /> Back
                  </button>
                </Link>
              </div>
              <div className="flex">
                <h1 className="border-b-4 border-[#F39058] text-3xl font-semibold">
                  Feedback
                </h1>
              </div>
              <div className="mt-[20px] flex flex-col sm:flex-row items-center gap-2 mb-[45px]">
                <i>
                  <RiAlertFill className="text-[#fccc53] text-3xl md:text-xl" />
                </i>
                <p>Your rating can determines company interest to you</p>
              </div>
              {dataRating?.length > 0 && (
                <>
                  <p className="my-[15px] font-semibold">
                    Rating : ({totalRating} / 5)
                  </p>
                  <div>
                    {dataRating?.map((data, index) => {
                      return (
                        <div key={index}>
                          <CardFeedback dataRating={data} />
                        </div>
                      );
                    })}
                    {loadingPage && (
                      <div className="flex justify-center">
                        <div className="w-full pt-20">
                          <div className="flex items-center justify-center">
                            <div className="spinner-border" role="status">
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {!loadingPage && dataRating?.length !== totalData && (
                      <div className="flex justify-center">
                        <button
                          className="bg-[#323232] hover:bg-[#5e5e5e] text-white mt-5 rounded-full py-2 px-5 max-w-[400px] text-sm"
                          onClick={() => setPage(page + 1)}
                        >
                          Show more
                        </button>
                      </div>
                    )}
                    {totalData === dataRating?.length && (
                      <div className="flex justify-center mt-5 font-semibold">
                        End of page
                      </div>
                    )}
                  </div>
                </>
              )}

              {dataRating?.length === 0 && (
                <div className="w-full px-10 py-20">
                  <div className="flex items-center justify-center gap-4 font-semibold">
                    <img src="/images/noData.png" alt="data-not-found" /> Data
                    Not Found
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </Layout>
    </>
  );
}
