import signUpStyle from "./Register.module.css";
import { useState, useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import Layout from "../../components/layouts/Layout";
import Tagline from "./components/Tagline";
import RegisterComponent from "./components/RegisterComponent";
import { register } from "../../Api/api-libs";
import { setAuthToken } from "../../utils/auth";

export default function SignUp() {
  const [registerData, setRegisterData] = useState({
    users_full_name: "",
    users_email: "",
    users_password: "",
    users_password_confirmation: "",
    level_id: 4,
  });
  const [validation, setValidation] = useState([]);
  const [loading, setLoading] = useState(false);
  const { setIsAuth } = useContext(AuthContext);

  let navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const url_referrer = searchParams.get("url_referrer") || "";

  const handleInputChange = (identifier, value) => {
    setRegisterData((prev) => ({
      ...prev,
      [identifier]: value,
    }));
  };

  const registerHandler = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const fetchRegister = await register(registerData);
      const user = fetchRegister?.data;
      setAuthToken(user);
      setIsAuth(true);
      if (url_referrer) {
        let url_to_decode = window.atob(url_referrer);
        window.location.reload(navigate(url_to_decode));
      } else {
        window.location.reload(navigate("/lowongan-kerja"));
      }
    } catch (error) {
      setValidation(error.response.data);
    }

    setLoading(false);
  };

  return (
    <>
      <Layout title="Sign Up">
        <div className={signUpStyle.body}>
          <div className={signUpStyle.mainRegisterWrapper}>
            <RegisterComponent
              onSubmit={registerHandler}
              registerData={registerData}
              onChange={handleInputChange}
              validation={validation}
              loading={loading}
              urlReferrer={url_referrer}
            />
            <Tagline />
          </div>
        </div>
      </Layout>
    </>
  );
}
