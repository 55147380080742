import React from "react";
import Navbar from "./Navbar";
import { useEffect } from "react";
import { ScrollTop } from "../../utils/ScrollTop";

export default function LayoutUser({ children }) {
  useEffect(() => {
    ScrollTop();
  }, []);

  return (
    <>
      <Navbar />
      {children}
    </>
  );
}
