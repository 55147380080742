import { Link } from "react-router-dom";
import signUpStyle from "../Register.module.css";
import Header from "./Header";
import RegisterInput from "./RegisterInput";
import Button from "../../../components/auth/Button";

export default function RegisterComponent({
  onSubmit,
  registerData,
  onChange,
  validation,
  loading,
  urlReferrer,
}) {
  return (
    <div className={signUpStyle.registerCard}>
      <Header />
      <form onSubmit={onSubmit}>
        <RegisterInput
          label="Full Name"
          type="text"
          placeholder="Full name"
          name="users_full_name"
          value={registerData.users_full_name}
          onChange={(event) => onChange("users_full_name", event.target.value)}
          required
          validation={validation.users_full_name}
        />

        <RegisterInput
          label="Email"
          type="email"
          placeholder="yourmail@mail.com"
          name="users_email"
          value={registerData.users_email}
          onChange={(event) => onChange("users_email", event.target.value)}
          required
          validation={validation.users_email}
        />

        <RegisterInput
          label="Password"
          type="password"
          placeholder="password"
          name="users_password"
          value={registerData.users_password}
          onChange={(event) => onChange("users_password", event.target.value)}
          required
          validation={validation.users_password}
        />

        <RegisterInput
          label="Password Confirmation"
          type="password"
          placeholder="password confirmation"
          name="users_password_confirmation"
          id="users_password_confirmation"
          value={registerData.users_password_confirmation}
          onChange={(event) =>
            onChange("users_password_confirmation", event.target.value)
          }
          required
          validation={validation.users_password_confirmation}
        />

        <div className={signUpStyle.btnWrapper}>
          <Button text="Sign Up" loading={loading} />
        </div>
      </form>
      <div className={signUpStyle.login}>
        Already have account?
        <Link
          to={urlReferrer ? `/login?url_referrer=${urlReferrer}` : "/login"}
        >
          <span className={signUpStyle.loginLink}> Sign in</span>
        </Link>
      </div>
    </div>
  );
}
