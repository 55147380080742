import { useEffect, useState, useContext } from "react";
import { BiWorld, BiTimeFive } from "react-icons/bi";
import { BsTelephone } from "react-icons/bs";
import {
  FaLinkedin,
  FaMoneyBillWave,
  FaShareAlt,
  FaFacebookSquare,
  FaTwitter,
  FaWhatsappSquare,
  FaTelegram,
} from "react-icons/fa";
import { ImOffice, ImBriefcase } from "react-icons/im";
import { AiOutlineMail } from "react-icons/ai";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import { Markup } from "interweave";
import moment from "moment";
import { RiAlertFill } from "react-icons/ri";
import { titleParser } from "../../../utils/helper";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

import { AuthContext } from "../../../context/AuthContext";
import Layout from "../../../components/layouts/Layout";
import api from "../../../Api/api";
import styleDetail from "./LowonganKerja.module.css";
import ClipboardCopy from "./components/CopyLinkJob";

export default function DetailLowonganKerja() {
  const { isAuth } = useContext(AuthContext);

  const [dataDetailJob, setDataDetailJob] = useState("");
  const [dataDetailJobSkill, setDataDetailJobSkill] = useState([]);
  const [usersVerify, setUsersVerify] = useState("");
  const [usersApply, setUsersApply] = useState([]);
  const [jobseekerVerificationStatus, setJobseekerVerificationStatus] =
    useState("");
  const linkShare = window.location.href;

  let params = useParams();
  const jobId = params.jobId;
  const titleJob = params.titleJob;

  const url_referrer = window.btoa(`/lowongan-kerja/${titleJob}/${jobId}`);
  moment.locale("en");

  useEffect(() => {
    getDataDetailJob();
    if (isAuth) {
      checkUser();
    }
  }, []);

  const getDataDetailJob = async () => {
    const url = `${api.lowonganKerja}/${jobId}`;

    await axios
      .get(url)
      .then((response) => {
        setDataDetailJob(response.data.data_detail_job);
        setDataDetailJobSkill(response.data.data_company_jobs_skill);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const checkUser = async () => {
    const users = JSON.parse(localStorage.getItem("user"));
    const url = `${api.checkUser}?users_email=${users?.user_email}&company_jobs_id=${jobId}`;

    await axios
      .get(url)
      .then((response) => {
        setUsersApply(response.data.data_apply);
        setUsersVerify(response.data.data_verify.users_verified_status_id);
        setJobseekerVerificationStatus(
          response.data.jobseeker_verification_status.status
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // link parser
  const urlParser = (url) => {
    const urlFromDatabase = url;

    const formattedUrl =
      urlFromDatabase.startsWith("http://") ||
      urlFromDatabase.startsWith("https://")
        ? urlFromDatabase
        : `https://${urlFromDatabase}`;

    return formattedUrl;
  };

  return (
    <>
      <Layout title="Detail Job">
        <div
          style={{
            backgroundColor: "#F7F7F7",
            paddingBottom: "120px",
          }}
        >
          <div className="sm:px-2 lg:px-12">
            <div className={styleDetail.containerDetailJob}>
              <div className={styleDetail.aboutCompany}>
                <div className={styleDetail.aboutCompanyWrap}>
                  <div className={styleDetail.aboutTitle}>About company</div>
                  <div className={styleDetail.lineAbout}></div>
                  <div className={styleDetail.companyWrap}>
                    <div>
                      <img
                        src={
                          dataDetailJob?.company_photo
                            ? `${process.env.REACT_APP_MEDIA_URL}/images/${dataDetailJob?.company_photo}`
                            : "/images/company_default.png"
                        }
                        className={styleDetail.logoCompany}
                        alt="company"
                      />
                    </div>
                    <div>
                      <div className={styleDetail.companyName}>
                        <Link
                          to={`/perusahaan/${titleParser(
                            dataDetailJob.company_name
                          )}/${dataDetailJob.company_id}`}
                          target="_blank"
                          style={{ color: "#323232" }}
                        >
                          {dataDetailJob.company_name}
                        </Link>
                      </div>
                      <div className={styleDetail.companySubName}>
                        {dataDetailJob.company_category_name}
                      </div>
                      <div className={styleDetail.socialMediaCompany}>
                        <a
                          rel="noreferrer"
                          href={
                            dataDetailJob.company_website
                              ? urlParser(dataDetailJob.company_website)
                              : ""
                          }
                        >
                          <BiWorld />
                        </a>
                        <a
                          rel="noreferrer"
                          href={
                            dataDetailJob.company_linkedin
                              ? urlParser(dataDetailJob.company_linkedin)
                              : ""
                          }
                        >
                          <FaLinkedin />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className={styleDetail.companyContact}>
                    <div className={styleDetail.contactIcon}>
                      <AiOutlineMail />
                    </div>
                    <div>{dataDetailJob.company_email}</div>
                  </div>
                  <div className={styleDetail.companyContact}>
                    <div className={styleDetail.contactIcon}>
                      <BsTelephone />
                    </div>
                    <div>{dataDetailJob.company_phone_number}</div>
                  </div>
                  <div className={styleDetail.whoWeAre}>Description</div>
                  <div className={styleDetail.companyDescription}>
                    <Markup content={dataDetailJob.company_description} />
                  </div>
                </div>
              </div>
              <div className={styleDetail.detailJobContent}>
                <div className={styleDetail.status}>
                  {!dataDetailJob.company_jobs_updated_date && (
                    <div className={styleDetail.subStatus}>
                      Created{" "}
                      {moment(
                        dataDetailJob.company_jobs_created_date,
                        "YYYYMMDD"
                      ).fromNow()}
                    </div>
                  )}
                  {dataDetailJob.company_jobs_updated_date && (
                    <div className={styleDetail.subStatus}>
                      Updated{" "}
                      {moment(
                        dataDetailJob.company_jobs_updated_date,
                        "YYYYMMDD"
                      ).fromNow()}
                    </div>
                  )}
                </div>
                <div className={styleDetail.jobContent}>
                  <div>
                    <img
                      src={
                        dataDetailJob?.company_photo
                          ? `${process.env.REACT_APP_MEDIA_URL}/images/${dataDetailJob?.company_photo}`
                          : "/images/company_default.png"
                      }
                      className={styleDetail.logoCompanyJobContent}
                      alt="company"
                    />
                  </div>
                  <div className={styleDetail.jobContentWrap}>
                    <div className="flex gap-[0.7rem] text-2xl font-semibold">
                      <p>{dataDetailJob.company_jobs_position}</p>
                      <div className="lg:tooltip" data-tip="Share this job">
                        <button
                          className="text-lg hover:text-[#f39058]"
                          onClick={() =>
                            document.getElementById("share_job").showModal()
                          }
                        >
                          <FaShareAlt />
                        </button>
                      </div>
                    </div>
                    <div className={styleDetail.jobContentSubTitle}>
                      {dataDetailJob.jobs_category_name} |{" "}
                      {dataDetailJob.city_name}
                    </div>
                    <div className="mt-4">
                      <div className={styleDetail.jobCategory}>
                        <div className={styleDetail.jobCategoryIcon}>
                          <ImOffice />
                        </div>
                        <div>{dataDetailJob.company_category_name}</div>
                      </div>
                      <div className={styleDetail.jobCategory}>
                        <div className={styleDetail.jobCategoryIcon}>
                          <BiTimeFive />
                        </div>
                        <div>{dataDetailJob.jobs_type_name}</div>
                      </div>
                      <div className={styleDetail.jobCategory}>
                        <div className={styleDetail.jobCategoryIcon}>
                          <ImBriefcase />
                        </div>
                        <div>{dataDetailJob.jobs_experience_name}</div>
                      </div>
                      {dataDetailJob?.company_jobs_salary_hide === "false" && (
                        <div className={styleDetail.jobCategory}>
                          <div className={styleDetail.jobCategoryIcon}>
                            <FaMoneyBillWave />
                          </div>
                          <div>
                            {dataDetailJob.company_jobs_salary_currency}{" "}
                            {dataDetailJob.company_jobs_salary_range == "true"
                              ? dataDetailJob.company_jobs_salary_min.toLocaleString() +
                                " - " +
                                dataDetailJob.company_jobs_salary_max.toLocaleString()
                              : dataDetailJob.company_jobs_salary_min.toLocaleString()}{" "}
                            / Month
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="flex mt-[40px]">
                      {isAuth && jobseekerVerificationStatus === 1 && (
                        <>
                          {usersVerify == 3 ? (
                            <>
                              {usersApply.length > 0 ? (
                                <div>
                                  <button
                                    className="flex items-center gap-[5px] py-2 px-[70px] bg-[#fabc9b] text-white rounded-full mt-4 text-sm cursor-not-allowed font-semibold"
                                    disabled
                                  >
                                    Already applied
                                  </button>
                                  <div className="flex items-center gap-2 mt-3 text-xs font-semibold">
                                    <RiAlertFill className="text-[18px] text-[#f9c135]" />{" "}
                                    You already applied this job
                                  </div>
                                </div>
                              ) : (
                                <>
                                  <a href={`/lamar/${jobId}`}>
                                    <div className={styleDetail.apply}>
                                      Apply
                                    </div>
                                  </a>
                                </>
                              )}
                            </>
                          ) : (
                            <div>
                              <button
                                className="flex items-center gap-[5px] py-2 px-[70px] bg-[#fabc9b] text-white rounded-full mt-4 text-sm cursor-not-allowed font-semibold"
                                disabled
                              >
                                Apply
                              </button>
                              <div className="flex items-center gap-2 mt-3 text-sm font-semibold">
                                <RiAlertFill className="text-[18px] text-[#eeb933]" />
                                <p>
                                  Please
                                  <Link
                                    to="/profile"
                                    className="underline underline-offset-4 text-[#fba255] hover:!text-[#cf731c]"
                                  >
                                    {" "}
                                    verify your account{" "}
                                  </Link>
                                  before apply this job.
                                </p>
                              </div>
                            </div>
                          )}
                        </>
                      )}

                      {isAuth && jobseekerVerificationStatus === 0 && (
                        <>
                          {usersApply.length > 0 ? (
                            <div>
                              <button
                                className="flex items-center gap-[5px] py-2 px-[70px] bg-[#fabc9b] text-white rounded-full mt-4 text-sm cursor-not-allowed font-semibold"
                                disabled
                              >
                                Already applied
                              </button>
                              <div className="flex items-center gap-2 mt-3 text-xs font-semibold">
                                <RiAlertFill className="text-[18px] text-[#eeb933]" />{" "}
                                You already applied this job
                              </div>
                            </div>
                          ) : (
                            <>
                              <a href={`/lamar/${jobId}`}>
                                <div className={styleDetail.apply}>Apply</div>
                              </a>
                            </>
                          )}
                        </>
                      )}

                      {!isAuth && (
                        <div>
                          <button
                            className="flex items-center gap-[5px] py-2 px-[70px] bg-[#fabc9b] text-white rounded-full mt-4 text-sm cursor-not-allowed font-semibold"
                            disabled
                          >
                            Apply
                          </button>
                          <div className="flex items-center gap-2 mt-3 text-sm font-semibold">
                            <RiAlertFill className="text-[18px] text-[#eeb933]" />{" "}
                            <Link
                              to={`/login?url_referrer=${url_referrer}`}
                              className="underline underline-offset-4 text-[#fba255] hover:!text-[#cf731c]"
                            >
                              Sign In first
                            </Link>{" "}
                            to apply this job
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className={styleDetail.requirementWrapper}>
                  <div className="mt-12 text-base text-[20px] font-semibold mb-4">
                    Requirement & Job description
                  </div>
                  <hr />
                  <div className="mt-10">
                    <Markup content={dataDetailJob.company_jobs_description} />
                  </div>
                  <div className="mt-12 text-base text-[20px] font-semibold mb-4">
                    Requirement skill
                  </div>
                  <hr />
                  <div className="flex flex-wrap gap-3 mt-4">
                    {dataDetailJobSkill?.map((data, index) => {
                      return (
                        <div
                          className="bg-[#989898] rounded-full py-2 px-4 text-white text-sm"
                          key={index}
                        >
                          {data?.skills_name}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>

      {/* modal share job */}
      <dialog id="share_job" className="!modal">
        <div className="modal-box">
          <h3 className="text-lg font-bold">Share this job</h3>
          <div className="flex justify-center mt-4 mb-8 gap-4 text-[2.5rem]">
            <FacebookShareButton url={linkShare}>
              <FaFacebookSquare className="hover:text-[#f39058] " />
            </FacebookShareButton>
            <LinkedinShareButton url={linkShare}>
              <FaLinkedin className="hover:text-[#f39058]" />
            </LinkedinShareButton>
            <TwitterShareButton url={linkShare}>
              <FaTwitter className="hover:text-[#f39058]" />
            </TwitterShareButton>
            <TelegramShareButton url={linkShare}>
              <FaTelegram className="hover:text-[#f39058]" />
            </TelegramShareButton>
            <WhatsappShareButton url={linkShare}>
              <FaWhatsappSquare className="hover:text-[#f39058]" />
            </WhatsappShareButton>
          </div>
          <ClipboardCopy copyText={linkShare} />

          <div className="modal-action">
            <form method="dialog">
              <button className="btn bg-[#f39058] text-white">Close</button>
            </form>
          </div>
        </div>

        <form method="dialog" className="!modal-backdrop">
          <button>close</button>
        </form>
      </dialog>
    </>
  );
}
