import Button from "./Button";
import firstSectionImg from "../../../assets/images/landing-page1.webp";

export default function FirstSection({ isAuth }) {
  return (
    <div className="max-w-[1200px] m-auto py-[100px] flex flex-col-reverse md:flex-row  items-center gap-[40px] xl:gap-[110px] p-[25px]">
      <div className="w-[100%] md:w-[50%]">
        <h1 className="mb-2 text-4xl font-semibold xl:text-5xl">
          Find your job at
        </h1>
        <h1 className="xl:text-5xl text-4xl font-bold text-[#F39058] mb-5">
          Talent Scouting
        </h1>
        <div className="mb-4 text-[18px]">
          Find all companies from start up companies to big companies and see
          all the available job vacancies here
        </div>
        <Button isAuth={isAuth} text="Join Us" />
      </div>
      <img
        className="w-[100%] md:w-[50%]"
        fetchpriority="high"
        src={firstSectionImg}
        alt="landing-page"
      />
    </div>
  );
}
