import React from "react";
import style from "./Perusahaan.module.css";
import { MdLocationPin } from "react-icons/md";
import { ImOffice, ImBriefcase } from "react-icons/im";
import { Markup } from "interweave";

export default function CardPerusahaan(props) {
  return (
    <div className={`${style.card} hover:text-orange-700`}>
      <div className={style.image}>
        <img
          src={
            props?.logo
              ? `${process.env.REACT_APP_MEDIA_URL}/images/${props?.logo}`
              : "/images/company_default.png"
          }
          alt="company"
        />
      </div>
      <div className={style.content}>
        <div className={style.Title}>{props.company}</div>
        <div className={style.company}>
          <Markup content={props.description} />
        </div>
        <div className={style.detail}>
          <div className={style.detailContent}>
            <div>
              <MdLocationPin />
            </div>
            <div>{props.location}</div>
          </div>
          <div className={style.detailContent}>
            <div>
              <ImOffice />
            </div>
            <div
              style={{
                width: "11rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "noWrap",
              }}
            >
              {props.category}
            </div>
          </div>
          <div className={style.detailContent}>
            <div>
              <ImBriefcase />
            </div>
            <div
              style={{
                paddingTop: "4px",
              }}
            >
              {props.hiring} Positions actively hiring
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
