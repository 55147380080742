import jwt_decode from "jwt-decode";

export function setAuthToken(userData) {
  let user = {
    token: userData.access_token,
    user_fullname: userData.user.users_full_name,
    user_email: userData.user.users_email,
    isAuth: true,
  };

  const setUser = localStorage.setItem("user", JSON.stringify(user));

  return setUser;
}

export function getUser() {
  const user = JSON.parse(localStorage.getItem("user"));
  return user;
}

export function getAuthToken() {
  const user = getUser();
  return user?.token;
}

export function getTokenDuration() {
  const decode = jwt_decode(getAuthToken());
  const tokenExp = decode.exp;
  const currentTime = Math.floor(Date.now() / 1000);
  const duration = tokenExp - currentTime;
  return duration;
}
