import style from "../LandingPage.module.css";
import about1 from "../../../assets/images/about1.webp";
import about2 from "../../../assets/images/about2.webp";

export default function SecondSection() {
  return (
    <div className={style.secondContainer}>
      <div className={style.secondContainerWrap}>
        <div className={style.aboutDescription}>
          <div className="mb-5 text-4xl font-semibold text-white xl:text-5xl">
            We are here to help you
          </div>
          <div className={style.aboutDescriptionContent}>
            We are here to assist you in finding the job you desire in your
            dream company
          </div>
        </div>
        <img src={about1} className={style.imgAbout1} alt="img-about1" />
        <img src={about2} className={style.imgAbout2} alt="img-about2" />
      </div>
    </div>
  );
}
