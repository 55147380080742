import { useContext } from "react";
import Layout from "../../components/layouts/Layout";
import { AuthContext } from "../../context/AuthContext";
import FirstSection from "./components/FirstSection";
import SecondSection from "./components/SecondSection";
import ThirdSection from "./components/ThirdSection";

export default function LandingPageJobseeker() {
  const { isAuth } = useContext(AuthContext);

  return (
    <>
      <Layout title="Career Solution">
        <div className="bg-[#F7F7F7] mb-[-120px] pb-[120px]">
          <FirstSection isAuth={isAuth} />
          <SecondSection />
          <ThirdSection isAuth={isAuth} />
        </div>
      </Layout>
    </>
  );
}
