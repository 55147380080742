import React from "react";
import style from "./Perusahaan.module.css";
import { Markup } from "interweave";

export default function Deskripsi({ company }) {
  return (
    <div className={style.companyDetailWrapper}>
      <div className={style.aboutTitle}>About Company</div>
      <div>
        <Markup content={company?.company_description} />
      </div>
      <div className={style.aboutTitle}>Address</div>
      <div>{company?.company_address}</div>
    </div>
  );
}
