import React, { useEffect, useState } from "react";
import { RiAlertFill } from "react-icons/ri";
import { AiFillStar } from "react-icons/ai";
import { BiEnvelope, BiPhone, BiLocationPlus } from "react-icons/bi";
import { FaMoneyBillWave, FaBirthdayCake, FaUserTag } from "react-icons/fa";
import { MdOutlineVisibility } from "react-icons/md";
import { MdAddAPhoto } from "react-icons/md";
import { Link } from "react-router-dom";
import { titleCase } from "./Data/helper";
import axios from "axios";
import api from "../../Api/api";
import { Markup } from "interweave";
import { BsFillCheckCircleFill } from "react-icons/bs";
import Swal from "sweetalert2";
import { ScrollTop } from "../../utils/ScrollTop";

export default function ProfileSaya() {
  const user = JSON.parse(localStorage.getItem("user"));
  const fullname = user?.user_fullname;

  // data
  const [dataDiri, setDataDiri] = useState({});
  const [keahlian, setKeahlian] = useState([]);
  const [pendidikan, setPendidikan] = useState([]);
  const [pengalamanKerja, setPengalamanKerja] = useState([]);
  const [license, setLicense] = useState([]);
  const [totalRating, setTotalRating] = useState(0);
  const [verifikasi, setVerifikasi] = useState("");
  const [emailVerify, setEmailVerify] = useState([]);
  const [jobseekerVerificationStatus, setJobseekerVerificationStatus] =
    useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchDataProfile();
    fetchDataRating();
    ScrollTop();
  }, []);

  const fetchDataRating = async () => {
    const users = JSON.parse(localStorage.getItem("user"));
    const url = `${api.usersRating}?users_email=${users?.user_email}&page=${1}`;

    await axios
      .get(url, {
        headers: {
          "Content-type": "application/json",
          Authorization: `bearer ${users?.token}`,
        },
      })
      .then((response) => {
        setTotalRating(response.data.total_rating);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchDataProfile = async () => {
    setLoading(true);
    const users = JSON.parse(localStorage.getItem("user"));
    const url = `${api.profile}?users_email=${users?.user_email}`;

    await axios
      .get(url, {
        headers: {
          "Content-type": "application/json",
          Authorization: `bearer ${users?.token}`,
        },
      })
      .then((response) => {
        setDataDiri(response.data.data_diri);
        setKeahlian(response.data.keahlian);
        setPendidikan(response.data.pendidikan);
        setLicense(response.data.license);
        setPengalamanKerja(response.data.pengalaman_kerja);
        setVerifikasi(response.data.users_profile_verify);
        setEmailVerify(response.data.email_verify);
        setJobseekerVerificationStatus(
          response.data.jobseeker_verification_status.status
        );
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleVerify = () => {
    Swal.fire({
      title: "Verify Profile?",
      text: "Are you sure want to request for verify?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#F39058",
      cancelButtonColor: "#d33",
      confirmButtonText: "Verify",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const users = JSON.parse(localStorage.getItem("user"));
        const url = `${api.verifyProfile}`;
        const data = {
          users_email: users?.user_email,
        };

        Swal.fire({
          title: "Please wait",
          text: "This may take few minute",
          timerProgressBar: true,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        await axios
          .put(url, data, {
            headers: {
              "Content-type": "application/json",
              Authorization: `bearer ${users?.token}`,
            },
          })
          .then((response) => {
            Swal.fire({
              title: "Success",
              text: "Your request has been sent",
              icon: "success",
              confirmButtonColor: "#F39058",
            });
            fetchDataProfile();
            fetchDataRating();
          })
          .catch((error) => {
            Swal.fire({
              title: "Failed",
              text: "Failed send data",
              icon: "error",
              confirmButtonColor: "#F39058",
            });
            console.log(error);
            fetchDataProfile();
            fetchDataRating();
          });
      }
    });
  };

  const handleEmailVerification = async () => {
    const users = JSON.parse(localStorage.getItem("user"));
    const data = {
      users_email: users?.user_email,
    };
    const url = `${api.emailVerification}`;

    Swal.fire({
      title: "Please wait",
      text: "This may take few minute",
      timerProgressBar: true,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    await axios
      .post(url, data, {
        headers: {
          "Content-type": "application/json",
          Authorization: `bearer ${users?.token}`,
        },
      })
      .then((response) => {
        Swal.fire({
          title: "Success",
          text: "Please check your e-mail to verify",
          icon: "success",
          confirmButtonColor: "#F39058",
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Failed",
          text: "Failed send email",
          icon: "error",
          confirmButtonColor: "#F39058",
        });
        console.log(error);
      });
  };

  const handleUploadPhoto = async (e) => {
    const maxSize = 1000000;
    const selectedFile = e.target.files[0];
    const ALLOWED_TYPES = ["image/png", "image/jpg", "image/jpeg"];

    Swal.fire({
      title: "Please wait",
      text: "This may take few minute",
      timerProgressBar: true,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    if (
      selectedFile &&
      ALLOWED_TYPES.includes(selectedFile.type) &&
      selectedFile.size < maxSize
    ) {
      const users = JSON.parse(localStorage.getItem("user"));
      const url = `${api.uploadPhoto}`;

      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("users_email", users.user_email);

      await axios
        .post(url, formData, {
          headers: {
            Authorization: `bearer ${users?.token}`,
          },
        })
        .then(() => {
          Swal.fire({
            title: "Success",
            text: "Success change photo",
            icon: "success",
            confirmButtonColor: "#F39058",
          });
          fetchDataProfile();
        })
        .catch((error) => {
          Swal.fire({
            title: "Failed",
            text: "Failed change photo",
            icon: "error",
            confirmButtonColor: "#F39058",
          });
          console.log(error);
        });
    } else {
      Swal.fire({
        title: "Failed",
        text: "File too large",
        icon: "error",
        confirmButtonColor: "#F39058",
      });
      console.log("error file too large");
    }
  };

  return (
    <div className="w-full p-[20px] min-h-screen">
      {verifikasi?.users_verified_status_id === "1" &&
        jobseekerVerificationStatus === 1 && (
          <div className="flex justify-between flex-col md:flex-row items-center bg-[#ffd56b] py-[20px] px-[30px] gap-4 w-full mb-4">
            <div className="flex gap-4">
              <i className="text-[50px]">
                <RiAlertFill />
              </i>
              <div>
                <div className="text-xl font-semibold mb-[3px]">
                  Thank you for your register
                </div>
                <div className="text-sm">
                  Before you apply the job, please complete your data first so
                  our team can verify your data
                </div>
              </div>
            </div>
            <button
              onClick={handleVerify}
              className="flex items-center gap-[5px] py-2 px-5 bg-[#f98e50] text-white rounded-full hover:bg-[#f47126] text-sm"
            >
              Verify
            </button>
          </div>
        )}

      {verifikasi?.users_verified_status_id === "2" &&
        jobseekerVerificationStatus === 1 && (
          <div className="flex flex-col md:flex-row items-center bg-[#ffd56b] py-[20px] px-[30px] gap-5 w-full mb-4">
            <i className="text-[50px]">
              <RiAlertFill />
            </i>
            <div>
              <div className="text-xl font-semibold mb-[3px]">
                Please wait for verification
              </div>
              <div className="text-sm">
                Your data is being evaluated by our team
              </div>
            </div>
          </div>
        )}

      {verifikasi?.users_verified_status_id === "4" &&
        jobseekerVerificationStatus === 1 && (
          <div className="flex flex-col md:flex-row items-center justify-between bg-[#ffd56b] py-[20px] px-[30px] gap-4 w-full mb-4">
            <div className="flex flex-col md:flex-row gap-4 md:w-[80%] items-center">
              <div className="text-[50px]">
                <RiAlertFill />
              </div>
              <div>
                <div className="text-xl font-semibold mb-[3px]">
                  Your verification has been rejected
                </div>
                <div className="text-sm">
                  {verifikasi?.users_profile_verify_note}
                </div>
              </div>
            </div>

            <button
              onClick={handleVerify}
              className="flex items-center gap-[5px] py-2 px-3 bg-[#f98e50] text-white rounded-full hover:bg-[#f47126] text-sm"
            >
              Re-verify
            </button>
          </div>
        )}

      {loading ? (
        <>
          <div className="w-full pt-20">
            <div className="flex items-center justify-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-col gap-4 xl:flex-row xl:items-center mt-[20px]">
            <div>
              <label
                htmlFor="uploadPhoto"
                className="z-10 cursor-pointer w-[139px] h-[139px] rounded-full bg-black opacity-40 hover:opacity-70 absolute flex items-center justify-center text-gray-50 text-3xl hover:text-white font-semibold transition delay-50 duration-200 ease-in-out"
              >
                <MdAddAPhoto />
              </label>
              <img
                className="object-cover rounded-full w-[139px] h-[139px] border-4 border-[#323232]"
                src={
                  dataDiri?.users_photo
                    ? `${process.env.REACT_APP_MEDIA_URL}/images/${dataDiri?.users_photo}`
                    : "/images/user-default.png"
                }
                alt="user"
              />

              <input
                id="uploadPhoto"
                type="file"
                name="photo"
                className="hidden"
                accept="image/png, image/jpg"
                onChange={handleUploadPhoto}
              />
            </div>
            <div>
              <div className="flex items-center gap-3 mb-4">
                <div className="text-xl font-semibold">
                  {titleCase(fullname)}
                </div>
                {verifikasi?.users_verified_status_id === "3" && (
                  <div className="flex items-center gap-1 text-[#f48646]">
                    <i>
                      <BsFillCheckCircleFill />
                    </i>
                    <div className="text-xs font-semibold">
                      Profile has been verified
                    </div>
                  </div>
                )}
              </div>

              {totalRating ? (
                <div className="flex flex-col gap-2 text-sm xl:items-center xl:gap-4 xl:flex-row">
                  <span className="flex items-center gap-1">
                    Rating : {totalRating}
                    <i className="text-[#F39058]">
                      <AiFillStar />
                    </i>
                  </span>
                  <Link className="text-[#F39058]" to="/feedback">
                    <span className="hover:text-[#b76435]">
                      See your feedback
                    </span>
                  </Link>
                </div>
              ) : (
                <div className="flex flex-col gap-2 text-sm xl:items-center xl:gap-4 xl:flex-row">
                  <span className="flex items-center gap-1">
                    Rating : -
                    <i className="text-[#F39058]">
                      <AiFillStar />
                    </i>
                  </span>
                  <Link className="text-[#F39058]" to="/feedback">
                    <span className="hover:text-[#b76435]">
                      See your feedback
                    </span>
                  </Link>
                </div>
              )}

              {totalRating !== 0 && totalRating < 3 && (
                <div className="mt-[10px] flex items-center gap-2">
                  <i>
                    <RiAlertFill className="text-3xl text-red-600 md:text-xl" />
                  </i>
                  <p className="text-xs font-semibold text-red-600">
                    Your rating is low
                  </p>
                </div>
              )}
            </div>
          </div>

          <div className="mt-[50px]">
            <div className="flex justify-start mb-4 text-lg font-semibold">
              <div className="border-b-4 border-[#F39058]">About me</div>
            </div>
            {dataDiri?.users_description && (
              <div>{dataDiri?.users_description}</div>
            )}
            {dataDiri?.users_description === null && (
              <div className="w-full px-10">
                <div className="flex items-center justify-center gap-4 font-semibold">
                  <img src="/images/noData.png" alt="data-not-found" />
                  No data
                </div>
              </div>
            )}
          </div>

          <div className="mt-[50px]">
            <div className="flex justify-start mb-4 text-lg font-semibold">
              <div className="border-b-4 border-[#F39058]">Personal info</div>
            </div>
            <div className="flex items-center gap-3 mb-2">
              <i className="text-[20px]">
                <BiEnvelope />
              </i>{" "}
              <span>{dataDiri?.users_email}</span>
              {emailVerify.length > 0 ? (
                <></>
              ) : (
                <button
                  onClick={handleEmailVerification}
                  className="text-sm font-semibold text-[#F39058] hover:text-[#b76435]"
                >
                  verify email
                </button>
              )}
            </div>
            <div className="flex items-center gap-3 mb-2">
              <i className="text-[20px]">
                <BiPhone />
              </i>{" "}
              {dataDiri?.users_phone_number && (
                <span>
                  +{dataDiri.country_phone} {dataDiri?.users_phone_number}
                </span>
              )}
              {dataDiri?.users_phone_number === null && <span>-</span>}
            </div>
            <div className="flex items-center gap-3 mb-2">
              <i className="text-[20px]">
                <BiLocationPlus />
              </i>{" "}
              {dataDiri?.city_name && dataDiri?.country_name && (
                <span>
                  {dataDiri?.city_name}, {dataDiri?.country_name}
                </span>
              )}
              {dataDiri?.city_name === null &&
                dataDiri?.country_name === null && <span>-</span>}
            </div>
            <div className="flex items-center gap-3 mb-2">
              <i className="text-[20px]">
                <FaMoneyBillWave />
              </i>{" "}
              {dataDiri?.users_salary && (
                <span>
                  {dataDiri?.country_currency}{" "}
                  {dataDiri?.users_salary.toLocaleString()}
                </span>
              )}
              {dataDiri?.users_salary === null && <span>-</span>}
            </div>
            <div className="flex items-center gap-3 mb-2">
              <i className="text-[20px]">
                <FaBirthdayCake />
              </i>{" "}
              {dataDiri?.users_birthday && (
                <span>{dataDiri?.users_birthday}</span>
              )}
              {dataDiri?.users_birthday === null && <span>-</span>}
            </div>
            <div className="flex items-center gap-3 mb-2">
              <i className="text-[20px]">
                <FaUserTag />
              </i>{" "}
              {dataDiri?.jobs_occupation_name && (
                <span>{dataDiri?.jobs_occupation_name}</span>
              )}
              {dataDiri?.jobs_occupation_id === null && <span>-</span>}
            </div>
            <div className="flex items-center gap-3 mb-2">
              <i className="text-[20px]">
                <FaUserTag />
              </i>{" "}
              {dataDiri?.jobs_category_name && (
                <span>{dataDiri?.jobs_category_name}</span>
              )}
              {dataDiri?.jobs_category_id === null && <span>-</span>}
            </div>
            <div className="flex items-center gap-3 mb-2">
              <i className="text-[20px]">
                <MdOutlineVisibility />
              </i>{" "}
              {dataDiri?.users_visibility_id && (
                <span>Profile Visibility: {dataDiri?.users_visibility}</span>
              )}
              {dataDiri?.users_visibility_id === null && <span>-</span>}
            </div>
          </div>

          <div className="mt-[50px]">
            <div className="flex justify-start mb-4 text-lg font-semibold">
              <div className="border-b-4 border-[#F39058]">Work experience</div>
            </div>
            {pengalamanKerja?.work_experience?.length > 0 ? (
              <>
                {pengalamanKerja?.work_experience?.map((data, index) => {
                  return (
                    <div key={index}>
                      <div className="flex flex-col gap-[30px] xl:flex-row mb-[20px]">
                        <div className="w-full xl:w-[40%]">
                          {data?.work_experience_currently_work === "true" && (
                            <div className="mb-2 font-semibold">
                              {data?.work_experience_job_title} |{" "}
                              {data?.work_experience_from_year} - Now
                            </div>
                          )}
                          {data?.work_experience_currently_work === "false" && (
                            <div className="mb-2 font-semibold">
                              {data?.work_experience_job_title} |{" "}
                              {data?.work_experience_from_year} -{" "}
                              {data?.work_experience_to_year}
                            </div>
                          )}
                          <div>
                            {data?.work_experience_company} | {data?.city_name},{" "}
                            {data?.country_name}
                          </div>
                          <div className="mt-2 font-semibold">
                            <p>
                              ({data?.count_experience.years}{" "}
                              {data?.count_experience.years > 1
                                ? "Years"
                                : "Year"}{" "}
                              {data?.count_experience.months}{" "}
                              {data?.count_experience.months > 1
                                ? "Months"
                                : "Month"}
                              )
                            </p>
                          </div>
                        </div>
                        <div className="w-full xl:w-[60%]">
                          <div className="flex flex-col gap-[5px] xl:flex-row xl:gap-[68px] mb-2">
                            <div className="font-semibold">Position</div>
                            <div>{data?.work_experience_job_title}</div>
                          </div>
                          <div className="flex flex-col gap-[5px] xl:flex-row xl:gap-[60px] mb-2">
                            <div className="font-semibold">Category</div>
                            <div>{data?.jobs_category_name}</div>
                          </div>
                          <div className="flex flex-col gap-[5px] xl:flex-row xl:gap-[40px] mb-2">
                            <div className="font-semibold">Description</div>
                            <div>
                              <Markup
                                content={data?.work_experience_description}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div className="font-semibold">
                  Total Work Experience : {pengalamanKerja?.total_years}{" "}
                  {pengalamanKerja?.total_years > 1 ? "Years" : "Year"}{" "}
                  {pengalamanKerja?.total_months}{" "}
                  {pengalamanKerja?.total_months > 1 ? "Months" : "Month"}
                </div>
              </>
            ) : (
              <div className="w-full px-10">
                <div className="flex items-center justify-center gap-4 font-semibold">
                  <img src="/images/noData.png" alt="data-not-found" /> No data
                </div>
              </div>
            )}
          </div>

          <div className="mt-[50px]">
            <div className="flex justify-start mb-4 text-lg font-semibold">
              <div className="border-b-4 border-[#F39058]">Education</div>
            </div>
            {pendidikan.length > 0 ? (
              <>
                {pendidikan?.map((data, index) => {
                  return (
                    <div
                      key={index}
                      className="flex flex-col mb-4 xl:flex-row gap-[10px] "
                    >
                      {data.users_education_present === "true" && (
                        <div className="w-full xl:w-[20%] font-semibold">
                          {data.users_education_from_year} - Now
                        </div>
                      )}
                      {data.users_education_present === "false" && (
                        <div className="w-full xl:w-[20%] font-semibold">
                          {data.users_education_from_year} -
                          {data.users_education_to_year}
                        </div>
                      )}

                      <div className="w-full xl:w-[70%]">
                        <div className="font-semibold">
                          {data.users_education_school_name}
                        </div>
                        <div className="text-[#989898]">
                          {data.users_education_major}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <div className="w-full px-10">
                <div className="flex items-center justify-center gap-4 font-semibold">
                  <img src="/images/noData.png" alt="data-not-found" /> No data
                </div>
              </div>
            )}
          </div>

          <div className="mt-[50px]">
            <div className="flex justify-start mb-4 text-lg font-semibold">
              <div className="border-b-4 border-[#F39058]">Skill</div>
            </div>
            {keahlian.length > 0 ? (
              <>
                {keahlian?.map((data, index) => {
                  return (
                    <div key={index} className="text-md">
                      <div className="mb-2">
                        {titleCase(data?.skills_name)} |{" "}
                        <span className="font-semibold">
                          {data.proficiency_name}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <div className="w-full px-10">
                <div className="flex items-center justify-center gap-4 font-semibold">
                  <img src="/images/noData.png" alt="data-not-found" /> No data
                </div>
              </div>
            )}
          </div>

          <div className="mt-[50px]">
            <div className="flex justify-start mb-4 text-lg font-semibold">
              <div className="border-b-4 border-[#F39058]">Certificate</div>
            </div>
            {license.length > 0 ? (
              <>
                {license?.map((data, index) => {
                  return (
                    <div
                      key={index}
                      className="flex flex-col mb-4 xl:flex-row gap-[10px] "
                    >
                      <div className="w-full xl:w-[40%]">
                        <div className="font-semibold">{data.license_name}</div>
                        <div className="text-[#989898]">
                          {data.publisher_organization}
                        </div>
                      </div>

                      {data.never_expired === "true" && (
                        <div className="w-full xl:w-[60%] font-semibold">
                          {data?.license_from_month}&nbsp;
                          {data?.license_from_year} - Now
                        </div>
                      )}
                      {data.never_expired === "false" && (
                        <div className="w-full xl:w-[60%] font-semibold">
                          {data?.license_from_month}&nbsp;
                          {data?.license_from_year}&nbsp;-&nbsp;
                          {data?.license_to_month}&nbsp;
                          {data?.license_to_year}
                        </div>
                      )}
                    </div>
                  );
                })}
              </>
            ) : (
              <div className="w-full px-10">
                <div className="flex items-center justify-center gap-4 font-semibold">
                  <img src="/images/noData.png" alt="data-not-found" /> No data
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}
