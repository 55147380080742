export default function Loading() {
  return (
    <div className="w-full pt-20">
      <div className="flex items-center justify-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  );
}
