import changePasswordStyle from "../ChangePassword.module.css";

export default function ChangePasswordHeader({ msgResponse }) {
  return (
    <>
      <div className={changePasswordStyle.title}>Change Password</div>
      <div className={changePasswordStyle.line}></div>
      <div className={changePasswordStyle.validationMessage}>
        <small>{msgResponse}</small>
      </div>
    </>
  );
}
