import axios from "axios";
import api from "./api";

export const login = async (body) => {
  const response = await axios.post(api.login, body);

  return response;
};

export const register = async (body) => {
  const response = await axios.post(api.register, body);

  return response;
};

export const forgotPassword = async (body) => {
  const response = await axios.post(api.forgotPassword, body);

  return response;
};

export const changePassword = async (body, tokenEmail) => {
  const url = `${api.forgotPassword}/${tokenEmail}`;
  const response = await axios.post(url, body);

  return response;
};

export const getDataJob = async (
  filterProfesi,
  filterLocation,
  tampilBerdasarkanValue,
  userEmailValue,
  tipePekerjaanValue,
  tanpaPengalaman,
  pengalamanValue,
  kerjaRemoteValue,
  page
) => {
  const url = `${api.lowonganKerja}?profesi=${filterProfesi}&lokasi=${filterLocation}&tampil_berdasarkan=${tampilBerdasarkanValue}&users_email=${userEmailValue}&tipe_pekerjaan=${tipePekerjaanValue}&tanpa_pengalaman=${tanpaPengalaman}&pengalaman=${pengalamanValue}&kerja_remote=${kerjaRemoteValue}&page=${page}`;
  const response = await axios.get(url);

  return response;
};
