import React from "react";
import Card from "../LokerPage/components/Card";
import { titleParser } from "../../../utils/helper";

export default function LowonganPerusahaan({ dataJob }) {
  return (
    <>
      <div className="px-[25px] py-5 text-lg font-semibold lg:px-[50px]">
        Job vacancies
      </div>
      <div className="grid grid-cols-1 gap-4 mb-10 px-[25px] xl:px-[50px] md:grid-cols-2 xl:grid-cols-3">
        {dataJob?.length > 0 && (
          <>
            {dataJob?.map((data, index) => {
              const companyJobSlug = titleParser(data.company_jobs_position);
              return (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`/lowongan-kerja/${companyJobSlug}/${data.company_jobs_id}`}
                  key={index}
                >
                  <Card
                    logo={data.company_photo}
                    company={data.company_name}
                    jobTitle={data.company_jobs_position}
                    location={data.city_name}
                    workType={data.jobs_type_name}
                    salaryRange={data.company_jobs_salary_range}
                    minSalary={data.company_jobs_salary_min}
                    maxSalary={data.company_jobs_salary_max}
                    created={data.company_jobs_created_date}
                    updated={data.company_jobs_updated_date}
                    hideSalary={data.company_jobs_salary_hide}
                    currency={data.company_jobs_salary_currency}
                    key={index}
                  />
                </a>
              );
            })}
          </>
        )}
      </div>
    </>
  );
}
