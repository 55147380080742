import React, { useState, useEffect } from "react";
import { BiEnvelope, BiPhone, BiLocationPlus } from "react-icons/bi";
import { FaMoneyBillWave, FaBirthdayCake, FaUserTag } from "react-icons/fa";
import { BiArrowBack } from "react-icons/bi";
import { MdOutlineVisibility } from "react-icons/md";
import Select from "react-select";
import WindowedSelect, { createFilter } from "react-windowed-select";
import axios from "axios";
import api from "../../Api/api";
import { ScrollTop } from "../../utils/ScrollTop";

export default function DataDiri() {
  const [showForm, setShowForm] = useState(false);
  const [dataDiri, setDataDiri] = useState("");
  const [country, setCountry] = useState([]);
  const [city, setCity] = useState([]);
  const [jobsCategory, setJobsCategory] = useState([]);
  const [jobsOccupation, setJobsOccupation] = useState([]);
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [alertFail, setAlertFail] = useState(false);
  const [loading, setLoading] = useState(false);

  const customFilter = createFilter({ ignoreAccents: false });

  const users = JSON.parse(localStorage.getItem("user"));
  const email = users?.user_email;

  useEffect(() => {
    fetchDataDiri();
    fetchCountryCity();
    fetchJobsClassification();
    ScrollTop();
  }, []);

  const fetchDataDiri = async () => {
    setLoading(true);
    const users = JSON.parse(localStorage.getItem("user"));
    const url = `${api.dataDiri}?users_email=${users?.user_email}`;

    await axios
      .get(url, {
        headers: {
          "Content-type": "application/json",
          Authorization: `bearer ${users?.token}`,
        },
      })
      .then((respone) => {
        setDataDiri(respone.data.data_diri);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const fetchCountryCity = async () => {
    const users = JSON.parse(localStorage.getItem("user"));

    await axios
      .get(api.countryCity, {
        headers: {
          "Content-type": "application/json",
          Authorization: `bearer ${users?.token}`,
        },
      })
      .then((response) => {
        setCountry(response.data.country);
        setCity(response.data.city);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchJobsClassification = async () => {
    const users = JSON.parse(localStorage.getItem("user"));

    await axios
      .get(api.jobsClassification, {
        headers: {
          "Content-type": "application/json",
          Authorization: `bearer ${users?.token}`,
        },
      })
      .then((response) => {
        setJobsOccupation(response.data.jobs_occupation);
        setJobsCategory(response.data.jobs_category);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleUpdateDataDiri = async () => {
    const users = JSON.parse(localStorage.getItem("user"));
    const url = `${api.updateDataDiri}/${users?.user_email}`;

    await axios
      .put(
        url,
        {
          users_full_name: dataDiri.users_full_name,
          users_description: dataDiri.users_description,
          country_id: dataDiri.country_id,
          city_id: dataDiri.city_id,
          users_postal_code: dataDiri.users_postal_code,
          users_birthday: dataDiri.users_birthday,
          users_gender: dataDiri.users_gender,
          users_phone_number: dataDiri.users_phone_number,
          users_salary: dataDiri.users_salary,
          jobs_occupation_id: dataDiri.jobs_occupation_id,
          jobs_category_id: dataDiri.jobs_category_id,
          users_visibility_id: dataDiri.users_visibility_id,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `bearer ${users?.token}`,
          },
        }
      )
      .then((response) => {
        setAlertSuccess(true);
        setTimeout(() => {
          setAlertSuccess(false);
        }, 3000);
        ScrollTop();
        users["user_fullname"] = dataDiri.users_full_name;
        localStorage.setItem("user", JSON.stringify(users));
        fetchDataDiri();
      })
      .catch((error) => {
        console.log(error);
        setAlertFail(true);
        setTimeout(() => {
          setAlertFail(false);
        }, 3000);
        ScrollTop();
      });
  };

  // jobs classification
  const jobsOccupationOptions = jobsOccupation.map((item) => {
    return { value: item.jobs_occupation_id, label: item.jobs_occupation_name };
  });

  const jobsCategoryOptions = jobsCategory
    .filter(
      (o) =>
        parseInt(o.jobs_occupation_id) ===
        parseInt(dataDiri?.jobs_occupation_id)
    )
    .map((item) => {
      return { value: item.jobs_category_id, label: item.jobs_category_name };
    });

  // country
  const countryOptions = country.map((item) => {
    return { value: item.country_id, label: item.country_name };
  });

  const cityOptions = city
    .filter((c) => c.country_id === dataDiri?.country_id)
    .map((item) => {
      return { value: item.city_id, label: item.city_name };
    });

  const countryPhoneOptions = country
    .filter((c) => c.country_id === dataDiri?.country_id)
    .map((item) => {
      return {
        label: item.country_phone + " " + item.country_name,
        value: item.country_phone,
      };
    });

  return (
    <div className="w-full p-[20px] xl:p-[30px] min-h-screen">
      {loading ? (
        <>
          <div className="w-full pt-20">
            <div className="flex items-center justify-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-col items-start gap-[40px] md:justify-between md:flex-row">
            <div className="border-b-4 border-[#F39058] text-2xl font-semibold">
              Personal Data
            </div>

            {!showForm && (
              <button
                onClick={() => {
                  setShowForm(!showForm);
                  fetchDataDiri();
                }}
                className="py-2 px-10 bg-[#F39058] text-white rounded-full hover:bg-[#f47126] text-sm"
              >
                Change
              </button>
            )}
          </div>

          {!showForm && (
            <>
              <div className="mt-[60px]">
                <div className="mb-4 text-lg font-semibold">About me</div>
                {dataDiri?.users_description && (
                  <div>{dataDiri?.users_description}</div>
                )}
                {dataDiri?.users_description === null && (
                  <div className="w-full px-10">
                    <div className="flex items-center justify-center gap-4 font-semibold">
                      <img src="/images/noData.png" alt="data-not-found" />
                      No data
                    </div>
                  </div>
                )}
              </div>
              <div className="mt-[40px]">
                <div className="mb-4 text-lg font-semibold">Personal Info</div>
                <div className="flex items-center gap-3 mb-2">
                  <i className="text-[20px]">
                    <BiEnvelope />
                  </i>{" "}
                  <span>{email}</span>
                </div>
                <div className="flex items-center gap-3 mb-2">
                  <i className="text-[20px]">
                    <BiPhone />
                  </i>{" "}
                  {dataDiri?.users_phone_number && (
                    <span>
                      +{dataDiri.country_phone} {dataDiri?.users_phone_number}
                    </span>
                  )}
                  {dataDiri?.users_phone_number === null && <span>-</span>}
                </div>
                <div className="flex items-center gap-3 mb-2">
                  <i className="text-[20px]">
                    <BiLocationPlus />
                  </i>{" "}
                  {dataDiri?.city_name && dataDiri?.country_name && (
                    <span>
                      {dataDiri?.city_name}, {dataDiri?.country_name}
                    </span>
                  )}
                  {dataDiri?.city_name === null &&
                    dataDiri?.country_name === null && <span>-</span>}
                </div>
                <div className="flex items-center gap-3 mb-2">
                  <i className="text-[20px]">
                    <FaMoneyBillWave />
                  </i>{" "}
                  {dataDiri?.users_salary && (
                    <span>
                      {dataDiri?.country_currency}{" "}
                      {dataDiri?.users_salary.toLocaleString()}
                    </span>
                  )}
                  {dataDiri?.users_salary === null && <span>-</span>}
                </div>
                <div className="flex items-center gap-3 mb-2">
                  <i className="text-[20px]">
                    <FaBirthdayCake />
                  </i>{" "}
                  {dataDiri?.users_birthday && (
                    <span>{dataDiri?.users_birthday_result}</span>
                  )}
                  {dataDiri?.users_birthday === null && <span>-</span>}
                </div>
                <div className="flex items-center gap-3 mb-2">
                  <i className="text-[20px]">
                    <FaUserTag />
                  </i>{" "}
                  {dataDiri?.jobs_occupation_name && (
                    <span>{dataDiri?.jobs_occupation_name}</span>
                  )}
                  {dataDiri?.jobs_occupation_id === null && <span>-</span>}
                </div>
                <div className="flex items-center gap-3 mb-2">
                  <i className="text-[20px]">
                    <FaUserTag />
                  </i>{" "}
                  {dataDiri?.jobs_category_name && (
                    <span>{dataDiri?.jobs_category_name}</span>
                  )}
                  {dataDiri?.jobs_category_id === null && <span>-</span>}
                </div>
                <div className="flex items-center gap-3 mb-2">
                  <i className="text-[20px]">
                    <MdOutlineVisibility />
                  </i>{" "}
                  {dataDiri?.users_visibility_id && (
                    <span>
                      Profile Visibility: {dataDiri?.users_visibility}
                    </span>
                  )}
                  {dataDiri?.users_visibility_id === null && <span>-</span>}
                </div>
              </div>
            </>
          )}
        </>
      )}

      {showForm && (
        <>
          <div className="mt-[60px]">
            <div className="w-full">
              {alertSuccess && (
                <div className="text-white alert alert-success" role="alert">
                  Success save data
                </div>
              )}
              {alertFail && (
                <div className="text-white alert alert-danger" role="alert">
                  Failed save data
                </div>
              )}
            </div>
            <button
              onClick={() => {
                setShowForm(!showForm);
                fetchDataDiri();
              }}
              className="flex items-center gap-[5px] py-2 px-4 bg-[#F39058] text-white rounded-full hover:bg-[#f47126] mb-4 text-sm"
            >
              <BiArrowBack /> Back
            </button>
            <div>
              <label htmlFor="nama-lengkap" className="form-label">
                Full Name <span className="text-danger">*</span>
              </label>
              <input
                className="form-control"
                name="users_full_name"
                type="text"
                placeholder="Full Name"
                id="nama-lengkap"
                value={dataDiri?.users_full_name}
                onChange={(e) =>
                  setDataDiri((prev) => ({
                    ...prev,
                    users_full_name: e.target.value,
                  }))
                }
              />
              {!dataDiri?.users_full_name && (
                <div className="mt-[8px] text-[13px] text-red-500">
                  Full Name required
                </div>
              )}

              <label htmlFor="email" className="mt-3 form-label">
                Email
              </label>
              <input
                className="form-control"
                type="email"
                placeholder={dataDiri?.users_email}
                id="email"
                disabled
              />

              <label htmlFor="users_description" className="mt-3 form-label">
                Description <span className="text-danger">*</span>
              </label>
              <textarea
                className="form-control"
                id="users_description"
                name="users_description"
                rows="3"
                placeholder="Description"
                value={dataDiri?.users_description}
                onChange={(e) =>
                  setDataDiri((prev) => ({
                    ...prev,
                    users_description: e.target.value,
                  }))
                }
              />
              {!dataDiri?.users_description && (
                <div className="mt-[8px] text-[13px] text-red-500">
                  Description required
                </div>
              )}

              <label className="mt-3 form-label">
                Country <span className="text-danger">*</span>
              </label>
              <Select
                isClearable={true}
                options={countryOptions}
                onChange={(event) => {
                  event === null
                    ? setDataDiri((prev) => ({
                        ...prev,
                        country_id: null,
                        country_phone: null,
                        country_name: null,
                        city_id: null,
                      }))
                    : setDataDiri((prev) => ({
                        ...prev,
                        country_id: event.value,
                        country_name: event.label,
                      }));
                }}
                value={
                  countryOptions.find(
                    (c) => c.value === parseInt(dataDiri?.country_id)
                  ) == null
                    ? null
                    : countryOptions.find(
                        (c) => c.value === parseInt(dataDiri?.country_id)
                      )
                }
                components={{
                  IndicatorSeparator: () => null,
                }}
                placeholder="Choose country"
                menuPlacement="auto"
              />
              {!dataDiri?.country_id && (
                <div className="mt-[8px] text-[13px] text-red-500">
                  Country required
                </div>
              )}

              <label className="mt-3 form-label">
                City <span className="text-danger">*</span>
              </label>
              <WindowedSelect
                filterOption={customFilter}
                isClearable={true}
                options={cityOptions}
                onChange={(event) => {
                  event === null
                    ? setDataDiri((prev) => ({ ...prev, city_id: null }))
                    : setDataDiri((prev) => ({
                        ...prev,
                        city_id: event.value,
                      }));
                }}
                value={
                  cityOptions.find(
                    (c) => c.value === parseInt(dataDiri?.city_id)
                  ) == null
                    ? null
                    : cityOptions.find(
                        (c) => c.value === parseInt(dataDiri?.city_id)
                      )
                }
                components={{
                  IndicatorSeparator: () => null,
                }}
                placeholder="Choose city"
                menuPlacement="auto"
              />
              {!dataDiri.city_id && (
                <div className="mt-[8px] text-[13px] text-red-500">
                  City required
                </div>
              )}

              <label htmlFor="kode-pos" className="mt-3 form-label">
                Postal code <span className="text-danger">*</span>
              </label>
              <input
                className="form-control"
                type="number"
                name="users_postal_code"
                placeholder="Postal code"
                id="kode-pos"
                value={dataDiri.users_postal_code}
                onChange={(e) =>
                  setDataDiri((prev) => ({
                    ...prev,
                    users_postal_code: e.target.value,
                  }))
                }
                min={0}
              />
              {!dataDiri?.users_postal_code && (
                <div className="mt-[8px] text-[13px] text-red-500">
                  Postal code required
                </div>
              )}

              <label htmlFor="date" className="mt-3 form-label">
                Date of Birth <span className="text-danger">*</span>
              </label>
              <input
                id="date"
                className="form-control"
                name="users_birthday"
                type="date"
                value={dataDiri?.users_birthday}
                onChange={(e) =>
                  setDataDiri((prev) => ({
                    ...prev,
                    users_birthday: e.target.value,
                  }))
                }
              />
              {!dataDiri.users_birthday && (
                <div className="mt-[8px] text-[13px] text-red-500">
                  Date of Birth required
                </div>
              )}

              <label htmlFor="gender" className="mt-3 form-label">
                Gender <span className="text-danger">*</span>
              </label>
              <div className="flex flex-col gap-2 md:gap-4 md:flex-row">
                <div className="form-check">
                  <input
                    className="cursor-pointer form-check-input"
                    type="radio"
                    name="users_gender"
                    id="L"
                    value="L"
                    checked={dataDiri?.users_gender === "L" ? true : false}
                    onChange={(e) =>
                      setDataDiri((prev) => ({
                        ...prev,
                        users_gender: e.target.value,
                      }))
                    }
                  />
                  <label
                    className="cursor-pointer form-check-label"
                    htmlFor="L"
                  >
                    Male
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="cursor-pointer form-check-input"
                    type="radio"
                    name="users_gender"
                    id="P"
                    value="P"
                    checked={dataDiri?.users_gender === "P" ? true : false}
                    onChange={(e) =>
                      setDataDiri((prev) => ({
                        ...prev,
                        users_gender: e.target.value,
                      }))
                    }
                  />
                  <label
                    className="cursor-pointer form-check-label"
                    htmlFor="P"
                  >
                    Female
                  </label>
                </div>
              </div>
              {!dataDiri.users_birthday && (
                <div className="mt-[8px] text-[13px] text-red-500">
                  Gender required
                </div>
              )}

              <label htmlFor="phone" className="mt-3 form-label">
                Phone Number <span className="text-danger">*</span>
              </label>
              <div className="flex flex-col gap-3 md:flex-row">
                <div className="w-[100%] md:w-[25%]">
                  <Select
                    isClearable={true}
                    options={countryPhoneOptions}
                    onChange={(event) => {
                      event === null
                        ? setDataDiri((prev) => ({
                            ...prev,
                            country_phone: null,
                          }))
                        : setDataDiri((prev) => ({
                            ...prev,
                            country_phone: event.value,
                          }));
                    }}
                    components={{
                      IndicatorSeparator: () => null,
                      DropdownIndicator: () => null,
                    }}
                    value={countryPhoneOptions}
                    placeholder="Phone Code"
                    menuPlacement="auto"
                    isDisabled={true}
                  />
                </div>
                <div className="w-[100%] md:w-[75%]">
                  <input
                    id="phone"
                    className="form-control"
                    value={dataDiri?.users_phone_number}
                    name="users_phone_number"
                    type="number"
                    min="0"
                    placeholder="Phone Number"
                    onChange={(e) =>
                      setDataDiri((prev) => ({
                        ...prev,
                        users_phone_number: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
              {!dataDiri.users_phone_number && (
                <div className="mt-[8px] text-[13px] text-red-500">
                  Phone Number required
                </div>
              )}

              <label htmlFor="gaji" className="mt-3 form-label">
                Salary <span className="text-danger">*</span>
              </label>
              <input
                id="gaji"
                className="form-control"
                name="users_salary"
                value={dataDiri?.users_salary}
                type="number"
                placeholder="Salary"
                onChange={(e) =>
                  setDataDiri((prev) => ({
                    ...prev,
                    users_salary: e.target.value,
                  }))
                }
              />
              {!dataDiri.users_salary && (
                <div className="mt-[8px] text-[13px] text-red-500">
                  Salary required
                </div>
              )}

              <label htmlFor="visibility" className="mt-3 form-label">
                Profile Visibility<span className="text-danger">*</span>
              </label>
              <div className="">
                <div className="form-check">
                  <input
                    className="cursor-pointer form-check-input"
                    type="radio"
                    name="users_visibility"
                    id="standard"
                    value="1"
                    checked={dataDiri?.users_visibility_id === 1 ? true : false}
                    onChange={(e) =>
                      setDataDiri((prev) => ({
                        ...prev,
                        users_visibility_id: parseInt(e.target.value),
                      }))
                    }
                  />
                  <label
                    className="cursor-pointer form-check-label"
                    htmlFor="standard"
                  >
                    Standard (Recommended)
                  </label>
                  <p className="text-xs">
                    The company can see all your personal data
                  </p>
                </div>
                <div className="form-check">
                  <input
                    className="cursor-pointer form-check-input"
                    type="radio"
                    name="users_visibility"
                    id="restricted"
                    value="2"
                    checked={dataDiri?.users_visibility_id === 2 ? true : false}
                    onChange={(e) =>
                      setDataDiri((prev) => ({
                        ...prev,
                        users_visibility_id: parseInt(e.target.value),
                      }))
                    }
                  />
                  <label
                    className="cursor-pointer form-check-label"
                    htmlFor="restricted"
                  >
                    Restricted
                  </label>
                  <p className="text-xs">
                    The company can see all your personal data, but can't see
                    your resume
                  </p>
                </div>
                <div className="form-check">
                  <input
                    className="cursor-pointer form-check-input"
                    type="radio"
                    name="users_visibility"
                    id="hidden"
                    value="3"
                    checked={dataDiri?.users_visibility_id === 3 ? true : false}
                    onChange={(e) =>
                      setDataDiri((prev) => ({
                        ...prev,
                        users_visibility_id: parseInt(e.target.value),
                      }))
                    }
                  />
                  <label
                    className="cursor-pointer form-check-label"
                    htmlFor="hidden"
                  >
                    Hidden
                  </label>
                  <p className="text-xs">
                    The company can't search you, your profile only appeared
                    when you apply to their company jobs
                  </p>
                </div>
              </div>

              <label className="mt-3 form-label">
                Job Occupation Preference
              </label>
              <Select
                isClearable={true}
                options={jobsOccupationOptions}
                onChange={(event) => {
                  event === null
                    ? setDataDiri((prev) => ({
                        ...prev,
                        jobs_occupation_id: null,
                        jobs_category_id: null,
                      }))
                    : setDataDiri((prev) => ({
                        ...prev,
                        jobs_occupation_id: parseInt(event.value),
                      }));
                }}
                value={
                  jobsOccupationOptions.find(
                    (jo) => jo.value === parseInt(dataDiri?.jobs_occupation_id)
                  ) == null
                    ? null
                    : jobsOccupationOptions.find(
                        (jo) =>
                          jo.value === parseInt(dataDiri?.jobs_occupation_id)
                      )
                }
                components={{
                  IndicatorSeparator: () => null,
                }}
                placeholder="Choose your occupation"
                menuPlacement="auto"
              />

              <label className="mt-3 form-label">Job Category Preference</label>
              <Select
                isClearable={true}
                options={jobsCategoryOptions}
                onChange={(event) => {
                  event === null
                    ? setDataDiri((prev) => ({
                        ...prev,
                        jobs_category_id: null,
                      }))
                    : setDataDiri((prev) => ({
                        ...prev,
                        jobs_category_id: parseInt(event.value),
                      }));
                }}
                value={
                  jobsCategoryOptions.find(
                    (c) => c.value === parseInt(dataDiri?.jobs_category_id)
                  ) == null
                    ? null
                    : jobsCategoryOptions.find(
                        (c) => c.value === dataDiri?.jobs_category_id
                      )
                }
                components={{
                  IndicatorSeparator: () => null,
                }}
                placeholder="Choose your category"
                menuPlacement="auto"
              />

              <div className="flex justify-end w-full">
                {(!dataDiri.users_full_name ||
                  !dataDiri.users_email ||
                  !dataDiri.users_description ||
                  !dataDiri.country_id ||
                  !dataDiri.city_id ||
                  !dataDiri.users_postal_code ||
                  !dataDiri.users_birthday ||
                  !dataDiri.users_gender ||
                  !dataDiri.users_phone_number ||
                  !dataDiri.users_salary) && (
                  <button
                    className="flex items-center gap-[5px] py-2 px-4 bg-[#fabc9b] text-white rounded-full mt-4 text-sm cursor-not-allowed"
                    disabled
                  >
                    Save
                  </button>
                )}

                {dataDiri.users_full_name &&
                  dataDiri.users_email &&
                  dataDiri.users_description &&
                  dataDiri.country_id &&
                  dataDiri.city_id &&
                  dataDiri.users_postal_code &&
                  dataDiri.users_birthday &&
                  dataDiri.users_gender &&
                  dataDiri.users_phone_number &&
                  dataDiri.users_salary && (
                    <button
                      onClick={handleUpdateDataDiri}
                      className="flex items-center gap-[5px] py-2 px-4 bg-[#F39058] text-white rounded-full hover:bg-[#f47126] mt-4 text-sm"
                    >
                      Save
                    </button>
                  )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
