import changePasswordStyle from "../ChangePassword.module.css";
import Button from "../../../components/auth/Button";

export default function ChangePasswordComponent({
  onSubmit,
  users_password,
  setUserPassword,
  validation,
  users_password_confirmation,
  setUserPasswordConfirmation,
  loading,
}) {
  return (
    <>
      <div className={changePasswordStyle.emailLabel}>New password</div>
      <form onSubmit={onSubmit}>
        <div className={changePasswordStyle.inputEmail}>
          <input
            type="password"
            placeholder="New password"
            name="users_password"
            value={users_password}
            onChange={(e) => setUserPassword(e.target.value)}
            required
          />
          <small className={changePasswordStyle.textDanger}>
            {validation.users_password}
          </small>
        </div>
        <div className={changePasswordStyle.label}>Re-type new password</div>
        <div className={changePasswordStyle.inputEmail}>
          <input
            type="password"
            placeholder="Re-type new password"
            name="users_password_confirmation"
            value={users_password_confirmation}
            onChange={(e) => setUserPasswordConfirmation(e.target.value)}
            required
          />
        </div>
        <small className={changePasswordStyle.textDanger}>
          {validation.users_password_confirmation}
        </small>
        <div className={changePasswordStyle.btnWrapper}>
          <Button text="Change Password" loading={loading} />
        </div>
      </form>
    </>
  );
}
