import React from "react";
import style from "./Perusahaan.module.css";

export default function Galeri({ dataGallery }) {
  console.log(dataGallery);
  return (
    <div className={style.companyDetailWrapper}>
      <div className={style.aboutTitle}>Gallery</div>
      <div className="grid justify-center grid-cols-1 gap-4 md:grid-cols-3 xl:grid-cols-4 md:justify-start">
        {dataGallery.map((item, index) => {
          return (
            <img
              key={index}
              className="object-cover w-full h-[200px]"
              src={`${process.env.REACT_APP_MEDIA_URL}/images/${item.company_gallery_name}`}
              alt="gallery"
            />
          );
        })}
      </div>
    </div>
  );
}
