import loginStyle from "../Login.module.css";
import Header from "./Header";
import LoginInput from "./LoginInput";
import Button from "../../../components/auth/Button";
import { Link } from "react-router-dom";

export default function LoginComponent({
  msgResponse,
  onSubmit,
  credentials,
  onChange,
  validation,
  loading,
  urlReferrer,
}) {
  return (
    <div className={loginStyle.loginCard}>
      <Header msgResponse={msgResponse} />
      <form onSubmit={onSubmit}>
        <LoginInput
          label="Email"
          type="text"
          placeholder="yourmail@mail.com"
          name="users_email"
          value={credentials.users_email}
          onChange={(event) => onChange("users_email", event.target.value)}
          required
          validation={validation.users_email}
        />

        <LoginInput
          label="Password"
          type="password"
          placeholder="password"
          name="user_password"
          value={credentials.users_password}
          onChange={(event) => onChange("users_password", event.target.value)}
          required
          validation={validation.users_password}
        />

        <p className={loginStyle.forgotPassword}>
          <Link to="/forgot-password">Forgot password?</Link>
        </p>
        <div className={loginStyle.btnLoginWrapper}>
          <Button text="Sign In" loading={loading} />
        </div>
      </form>
      <div className={loginStyle.signUp}>
        Don't have account?
        <Link
          to={
            urlReferrer ? `/register?url_referrer=${urlReferrer}` : "/register"
          }
        >
          <span className={loginStyle.signUpLink}> Sign Up</span>
        </Link>
      </div>
    </div>
  );
}
