import loginStyle from "../Login.module.css";

const LoginInput = ({ label, validation, ...props }) => {
  return (
    <>
      <div className={loginStyle.label}>{label}</div>
      <div className={loginStyle.input}>
        <input {...props} />
        <small className={loginStyle.textDanger}>{validation}</small>
      </div>
    </>
  );
};

export default LoginInput;
