import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../../components/layouts/Layout'

function NotFound() {
  return (
    <>
      <Helmet>
        <title>Not Found</title>
      </Helmet>
      <Layout>
        <div className="max-w-[1200px] min-h-[100vh] py-[200px] m-auto px-5 lg:px-0">
          <div className="flex flex-col md:flex-row min-w-[300px] justify-center gap-6 items-center mb-[60px]">
            <h1 className="text-2xl font-semibold">404</h1>
            <h1 className="text-2xl font-semibold">Page not found ...</h1>
          </div>
          <h1 className="flex justify-center mb-2 text-2xl font-semibold">
            Opps, we couldn’t find your page...
          </h1>
          <p className="flex justify-center">
            The page is missing or your link is incorrect
          </p>
        </div>
      </Layout>
    </>
  )
}

export default NotFound
