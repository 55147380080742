import axios from "axios";
import React, { useState, useEffect } from "react";
import { BiArrowBack } from "react-icons/bi";
import Select from "react-select";
import WindowedSelect, { createFilter } from "react-windowed-select";
import api from "../../Api/api";
import { Month } from "./Data/month.ts";
import { generateYear } from "./Data/helper";
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import { ScrollTop } from "../../utils/ScrollTop";
import Swal from "sweetalert2";

export default function License() {
  const List_year = generateYear();
  const [showForm, setShowForm] = useState(false);
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [alertFail, setAlertFail] = useState(false);
  const [loading, setLoading] = useState(false);
  const customFilter = createFilter({ ignoreAccents: false });

  const [action, setAction] = useState(1);
  const [dataLicenseForShow, setDataLicenseForshow] = useState([]);
  const [dataLicenseForUpdate, setDataLicenseForUpdate] = useState({
    license_id: "",
    users_id: "",
    license_name: "",
    publisher_organization: "",
    never_expired: false,
    license_from_month: "",
    license_from_year: "",
    license_to_month: "",
    license_to_year: "",
    license_description: "",
  });

  useEffect(() => {
    showLicense();
    ScrollTop();
  }, []);

  const showLicense = async () => {
    setLoading(true);
    const users = JSON.parse(localStorage.getItem("user"));
    const url = `${api.license}?users_email=${users.user_email}`;

    await axios
      .get(url, {
        headers: {
          "Content-type": "application/json",
          Authorization: `bearer ${users?.token}`,
        },
      })
      .then((response) => {
        setDataLicenseForshow(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const fillValueFormUpdate = (id) => {
    setAction(2);
    dataLicenseForShow
      .filter((data) => data.license_id === id)
      .map((data) => {
        setDataLicenseForUpdate((prev) => ({
          ...prev,
          license_id: data.license_id,
          users_id: data.users_id,
          license_name: data.license_name,
          publisher_organization: data.publisher_organization,
          never_expired: data.never_expired === "true" ? true : false,
          license_from_month: data.license_from_month,
          license_from_year: data.license_from_year,
          license_to_month: data.license_to_month,
          license_to_year: data.license_to_year,
          license_description: data.license_description,
        }));
      });
  };

  const insertLicense = async () => {
    const users = JSON.parse(localStorage.getItem("user"));
    const url = `${api.license}?users_email=${users.user_email}`;

    const data = {
      users_id: dataLicenseForUpdate.users_id,
      license_name: dataLicenseForUpdate.license_name,
      publisher_organization: dataLicenseForUpdate.publisher_organization,
      never_expired: `${dataLicenseForUpdate.never_expired}`,
      license_from_month: dataLicenseForUpdate.license_from_month,
      license_from_year: dataLicenseForUpdate.license_from_year,
      license_to_month: dataLicenseForUpdate.license_to_month,
      license_to_year: dataLicenseForUpdate.license_to_year,
      license_description: dataLicenseForUpdate.license_description,
    };

    await axios
      .post(url, data, {
        headers: {
          "Content-type": "application/json",
          Authorization: `bearer ${users?.token}`,
        },
      })
      .then((response) => {
        setAlertSuccess(true);
        setTimeout(() => {
          setAlertSuccess(false);
        }, 3000);
        showLicense();
        ScrollTop();
        setAction(1);
        setDataLicenseForUpdate((prev) => ({
          ...prev,
          license_id: "",
          users_id: "",
          license_name: "",
          users_education_major: "",
          publisher_organization: "",
          never_expired: false,
          license_from_month: "",
          license_from_year: "",
          license_to_month: "",
          license_to_year: "",
          license_description: "",
        }));
      })
      .catch((error) => {
        console.log(error);
        setAlertFail(true);
        setTimeout(() => {
          setAlertFail(false);
        }, 3000);
        showLicense();
        ScrollTop();
        setAction(1);
        setDataLicenseForUpdate((prev) => ({
          ...prev,
          license_id: "",
          users_id: "",
          license_name: "",
          users_education_major: "",
          publisher_organization: "",
          never_expired: false,
          license_from_month: "",
          license_from_year: "",
          license_to_month: "",
          license_to_year: "",
          license_description: "",
        }));
      });
  };

  const updateLicense = async () => {
    const users = JSON.parse(localStorage.getItem("user"));
    const url = `${api.license}/${dataLicenseForUpdate.license_id}`;

    const data = {
      users_id: dataLicenseForUpdate.users_id,
      license_name: dataLicenseForUpdate.license_name,
      publisher_organization: dataLicenseForUpdate.publisher_organization,
      never_expired: `${dataLicenseForUpdate.never_expired}`,
      license_from_month: dataLicenseForUpdate.license_from_month,
      license_from_year: dataLicenseForUpdate.license_from_year,
      license_to_month: dataLicenseForUpdate.license_to_month,
      license_to_year: dataLicenseForUpdate.license_to_year,
      license_description: dataLicenseForUpdate.license_description,
    };

    await axios
      .put(url, data, {
        headers: {
          "Content-type": "application/json",
          Authorization: `bearer ${users?.token}`,
        },
      })
      .then((response) => {
        setAlertSuccess(true);
        setTimeout(() => {
          setAlertSuccess(false);
        }, 3000);
        showLicense();
        ScrollTop();
        setAction(1);
        setDataLicenseForUpdate((prev) => ({
          ...prev,
          license_id: "",
          users_id: "",
          license_name: "",
          users_education_major: "",
          publisher_organization: "",
          never_expired: false,
          license_from_month: "",
          license_from_year: "",
          license_to_month: "",
          license_to_year: "",
          license_description: "",
        }));
      })
      .catch((error) => {
        console.log(error);
        setAlertFail(true);
        setTimeout(() => {
          setAlertFail(false);
        }, 3000);
        showLicense();
        ScrollTop();
        setAction(1);
        setDataLicenseForUpdate((prev) => ({
          ...prev,
          license_id: "",
          users_id: "",
          license_name: "",
          users_education_major: "",
          publisher_organization: "",
          never_expired: false,
          license_from_month: "",
          license_from_year: "",
          license_to_month: "",
          license_to_year: "",
          license_description: "",
        }));
      });
  };

  const deleteLicense = async (id) => {
    Swal.fire({
      title: "Delete License?",
      text: "Are you sure want to delete this data?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#F39058",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        const users = JSON.parse(localStorage.getItem("user"));
        const url = `${api.license}/${id}`;

        axios
          .delete(url, {
            headers: {
              "Content-type": "application/json",
              Authorization: `bearer ${users?.token}`,
            },
          })
          .then((response) => {
            Swal.fire({
              title: "Success",
              text: "Succes delete data",
              icon: "success",
              confirmButtonColor: "#F39058",
            });
            setDataLicenseForUpdate((prev) => ({
              ...prev,
              license_id: "",
              users_id: "",
              license_name: "",
              users_education_major: "",
              publisher_organization: "",
              never_expired: false,
              license_from_month: "",
              license_from_year: "",
              license_to_month: "",
              license_to_year: "",
              license_description: "",
            }));
            showLicense();
            setAction(1);
          })
          .catch((error) => {
            console.log(error);
            Swal.fire({
              title: "Failed",
              text: "Failed delete data",
              icon: "error",
              confirmButtonColor: "#F39058",
            });
            setDataLicenseForUpdate((prev) => ({
              ...prev,
              license_id: "",
              users_id: "",
              license_name: "",
              users_education_major: "",
              publisher_organization: "",
              never_expired: false,
              license_from_month: "",
              license_from_year: "",
              license_to_month: "",
              license_to_year: "",
              license_description: "",
            }));
            showLicense();
            setAction(1);
          });
      }
    });
  };

  const handleClearDataLicenseForUpdate = () => {
    setDataLicenseForUpdate((prev) => ({
      ...prev,
      license_id: "",
      users_id: "",
      license_name: "",
      users_education_major: "",
      publisher_organization: "",
      never_expired: false,
      license_from_month: "",
      license_from_year: "",
      license_to_month: "",
      license_to_year: "",
      license_description: "",
    }));
    setAction(1);
  };
  return (
    <div className="w-full p-[20px] xl:p-[30px] min-h-screen">
      {loading && (
        <>
          <div className="w-full pt-20">
            <div className="flex items-center justify-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </>
      )}

      {!loading && (
        <>
          <div className="flex flex-col items-start gap-[40px] md:justify-between md:flex-row">
            <div className="border-b-4 border-[#F39058] text-2xl font-semibold">
              Certificate
            </div>
            {!showForm && (
              <button
                onClick={() => {
                  setShowForm(!showForm);
                }}
                className="py-2 px-10 bg-[#F39058] text-white rounded-full hover:bg-[#f47126] text-sm"
              >
                Change
              </button>
            )}
          </div>

          {!showForm && (
            <>
              <div className="mt-[60px]">
                {dataLicenseForShow?.length > 0 ? (
                  <>
                    {dataLicenseForShow?.map((data, index) => {
                      return (
                        <div
                          key={index}
                          className="flex flex-col mb-4 xl:flex-row gap-[10px] "
                        >
                          <div className="w-full xl:w-[40%]">
                            <div className="font-semibold">
                              {data.license_name}
                            </div>
                            <div className="text-[#989898]">
                              {data.publisher_organization}
                            </div>
                          </div>

                          {data.never_expired === "true" && (
                            <div className="w-full xl:w-[60%] font-semibold">
                              {data?.license_from_month}&nbsp;
                              {data?.license_from_year} - Now
                            </div>
                          )}
                          {data.never_expired === "false" && (
                            <div className="w-full xl:w-[60%] font-semibold">
                              {data?.license_from_month}&nbsp;
                              {data?.license_from_year}&nbsp;-&nbsp;
                              {data?.license_to_month}&nbsp;
                              {data?.license_to_year}
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <div className="w-full px-10 pt-[70px]">
                    <div className="flex items-center justify-center gap-4 font-semibold">
                      <img src="/images/noData.png" alt="data-not-found" /> No
                      data
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </>
      )}

      {showForm && (
        <>
          <div className="mt-[60px]">
            <div className="w-full">
              {alertSuccess && (
                <div className="text-white alert alert-success" role="alert">
                  Success save data
                </div>
              )}
              {alertFail && (
                <div className="text-white alert alert-danger" role="alert">
                  Failed save data
                </div>
              )}
            </div>
            <button
              onClick={() => {
                setShowForm(!showForm);
              }}
              className="flex items-center gap-[5px] py-2 px-4 bg-[#F39058] text-white rounded-full hover:bg-[#f47126] mb-4 text-sm"
            >
              <BiArrowBack /> Back
            </button>

            <div className="mt-[20px]">
              {dataLicenseForShow?.map((data) => {
                return (
                  <div
                    key={Math.random()}
                    className="w-full bg-white rounded-sm shadow-md py-[15px] px-[30px] flex justify-between items-center mb-3"
                  >
                    <div>
                      <div className="mb-2 font-bold">{data?.license_name}</div>
                      <div className="mb-1 text-sm">
                        {data?.publisher_organization}
                      </div>
                      <div className="mb-1 text-sm">
                        {data?.license_description}
                      </div>
                      {data?.never_expired === "true" && (
                        <div className="text-sm">
                          {data?.license_from_month} {data?.license_from_year} -
                          Now
                        </div>
                      )}
                      {data?.never_expired === "false" && (
                        <div className="text-sm">
                          {data?.license_from_month} {data?.license_from_year} -{" "}
                          {data?.license_to_month} {data?.license_to_year}
                        </div>
                      )}
                    </div>
                    <div className="flex flex-col md:flex-row gap-3 text-[#FF9A62] text-2xl items-start">
                      <button
                        className="p-2 hover:text-[#9b532d]"
                        onClick={() => {
                          fillValueFormUpdate(data.license_id);
                          const section = document.querySelector("#update");
                          section.scrollIntoView({
                            behavior: "smooth",
                            block: "start",
                          });
                        }}
                      >
                        <AiFillEdit />
                      </button>

                      <button
                        onClick={() => deleteLicense(data.license_id)}
                        className="p-2 hover:text-[#9b532d]"
                      >
                        <AiFillDelete />
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="py-[10px]" id="update"></div>

            <h1 className="py-[15px] font-semibold">
              Add / Update Certificate
            </h1>

            <div className="w-full">
              {(dataLicenseForUpdate.license_name ||
                dataLicenseForUpdate.publisher_organization ||
                dataLicenseForUpdate.license_description ||
                dataLicenseForUpdate.license_from_month ||
                dataLicenseForUpdate.license_from_year) && (
                <button
                  onClick={handleClearDataLicenseForUpdate}
                  className="flex items-center gap-2 py-2 text-sm font-semibold text-red-400 hover:text-red-800"
                >
                  Clear all fields <AiFillDelete />
                </button>
              )}
            </div>

            <div>
              <label className="mt-3 form-label">
                Certificate <span className="text-danger">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                name="license_name"
                placeholder="Certificate name"
                value={dataLicenseForUpdate?.license_name}
                onChange={(e) =>
                  setDataLicenseForUpdate((prev) => ({
                    ...prev,
                    license_name: e.target.value,
                  }))
                }
              />
              {!dataLicenseForUpdate?.license_name && (
                <div className="mt-[8px] text-[13px] text-red-500">
                  License name required
                </div>
              )}

              <label className="mt-3 form-label">
                Publisher Organization <span className="text-danger">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                placeholder="Publisher Organization"
                name="publisher_organization"
                value={dataLicenseForUpdate?.publisher_organization}
                onChange={(e) =>
                  setDataLicenseForUpdate((prev) => ({
                    ...prev,
                    publisher_organization: e.target.value,
                  }))
                }
              />
              {!dataLicenseForUpdate?.publisher_organization && (
                <div className="mt-[8px] text-[13px] text-red-500">
                  Publisher organization required
                </div>
              )}

              <label className="mt-3 form-label">Certificate Period</label>
              <div className="form-check">
                <input
                  className="cursor-pointer form-check-input"
                  type="checkbox"
                  id="time_periode"
                  value=""
                  checked={dataLicenseForUpdate.never_expired}
                  onChange={() =>
                    setDataLicenseForUpdate((prev) => ({
                      ...prev,
                      never_expired: !dataLicenseForUpdate.never_expired,
                      license_to_month: null,
                      license_to_year: null,
                    }))
                  }
                />
                <label
                  className="cursor-pointer form-check-label"
                  htmlFor="time_periode"
                >
                  Never expired
                </label>
              </div>

              <div className="flex flex-col items-end gap-3 md:flex-row">
                <div className="mt-3 mb-2 w-[100%] md:w-[25%]">
                  <h1 className="mb-2">
                    From <span className="text-danger">*</span>
                  </h1>
                  <Select
                    value={
                      Month.find(
                        (c) =>
                          c.value ===
                          `${dataLicenseForUpdate.license_from_month}`
                      ) == null
                        ? null
                        : Month.find(
                            (c) =>
                              c.value ===
                              `${dataLicenseForUpdate.license_from_month}`
                          )
                    }
                    isClearable={true}
                    options={Month}
                    onChange={(event) =>
                      event == null
                        ? setDataLicenseForUpdate((prev) => ({
                            ...prev,
                            license_from_month: null,
                          }))
                        : setDataLicenseForUpdate((prev) => ({
                            ...prev,
                            license_from_month: event.value,
                          }))
                    }
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    menuPlacement="auto"
                    placeholder={"Month"}
                  />
                </div>

                <div className="mb-2 w-[100%] md:w-[25%]">
                  <Select
                    value={
                      List_year.find(
                        (c) =>
                          c.value ===
                          parseInt(dataLicenseForUpdate.license_from_year)
                      ) == null
                        ? null
                        : List_year.find(
                            (c) =>
                              c.value ===
                              parseInt(dataLicenseForUpdate.license_from_year)
                          )
                    }
                    isClearable={true}
                    options={List_year}
                    onChange={(event) =>
                      event == null
                        ? setDataLicenseForUpdate((prev) => ({
                            ...prev,
                            license_from_year: null,
                          }))
                        : setDataLicenseForUpdate((prev) => ({
                            ...prev,
                            license_from_year: event.value,
                          }))
                    }
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    menuPlacement="auto"
                    placeholder={"Year"}
                  />
                </div>

                <div className="mb-2 w-[100%] md:w-[25%]">
                  <h1 className="mb-2">To</h1>
                  <Select
                    value={
                      Month.find(
                        (c) =>
                          c.value === `${dataLicenseForUpdate.license_to_month}`
                      ) == null
                        ? null
                        : Month.find(
                            (c) =>
                              c.value ===
                              `${dataLicenseForUpdate.license_to_month}`
                          )
                    }
                    isClearable={true}
                    options={Month}
                    onChange={(event) =>
                      event == null
                        ? setDataLicenseForUpdate((prev) => ({
                            ...prev,
                            license_to_month: null,
                          }))
                        : setDataLicenseForUpdate((prev) => ({
                            ...prev,
                            license_to_month: event.value,
                          }))
                    }
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    menuPlacement="auto"
                    placeholder={"Month"}
                    isDisabled={
                      dataLicenseForUpdate.never_expired == true ? true : false
                    }
                  />
                </div>

                <div className="mb-2 w-[100%] md:w-[25%]">
                  <Select
                    value={
                      List_year.find(
                        (c) =>
                          c.value ===
                          parseInt(dataLicenseForUpdate.license_to_year)
                      ) == null
                        ? null
                        : List_year.find(
                            (c) =>
                              c.value ===
                              parseInt(dataLicenseForUpdate.license_to_year)
                          )
                    }
                    isClearable={true}
                    options={List_year}
                    onChange={(event) =>
                      event == null
                        ? setDataLicenseForUpdate((prev) => ({
                            ...prev,
                            license_to_year: null,
                          }))
                        : setDataLicenseForUpdate((prev) => ({
                            ...prev,
                            license_to_year: event.value,
                          }))
                    }
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    menuPlacement="auto"
                    placeholder={"Year"}
                    isDisabled={
                      dataLicenseForUpdate.never_expired == true ? true : false
                    }
                  />
                </div>
              </div>
              {(!dataLicenseForUpdate.license_from_month ||
                !dataLicenseForUpdate.license_from_year) && (
                <div className="text-[13px] text-red-500">Period required</div>
              )}

              <label htmlFor="license_description" className="mt-3 form-label">
                Description
              </label>
              <textarea
                className="form-control"
                id="license_description"
                name="license_description"
                rows="3"
                placeholder="Description"
                value={dataLicenseForUpdate?.license_description}
                onChange={(e) =>
                  setDataLicenseForUpdate((prev) => ({
                    ...prev,
                    license_description: e.target.value,
                  }))
                }
              />

              <div className="flex justify-end w-full">
                {(!dataLicenseForUpdate.license_name ||
                  !dataLicenseForUpdate.publisher_organization ||
                  !dataLicenseForUpdate.license_from_month ||
                  !dataLicenseForUpdate.license_from_year) && (
                  <button
                    className="flex items-center gap-[5px] py-2 px-4 bg-[#fabc9b] text-white rounded-full mt-4 text-sm cursor-not-allowed"
                    disabled
                  >
                    Save
                  </button>
                )}

                {dataLicenseForUpdate.license_name &&
                  dataLicenseForUpdate.publisher_organization &&
                  dataLicenseForUpdate.license_from_month &&
                  dataLicenseForUpdate.license_from_year && (
                    <button
                      onClick={action === 1 ? insertLicense : updateLicense}
                      className="flex items-center gap-[5px] py-2 px-4 bg-[#F39058] text-white rounded-full hover:bg-[#f47126] mt-4 text-sm"
                    >
                      Save
                    </button>
                  )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
