import signUpStyle from "../Register.module.css";

const Header = () => {
  return (
    <>
      <div className={signUpStyle.titleSignUp}>Sign Up</div>
      <div className={signUpStyle.line}></div>
    </>
  );
};

export default Header;
