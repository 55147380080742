import LowonganKerja from './LokerPage/LowonganKerja'
import Perusahaan from './PerusahaanPage/Perusahaan'

export const Menu = [
  {
    id: 1,
    component: <LowonganKerja />,
  },
  {
    id: 2,
    component: <Perusahaan />,
  },
]
