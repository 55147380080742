import React, { useState } from 'react'
import LayoutUser from '../../components/layouts/LayoutUser'
import { Menu } from './Data/Menu'
import { MenuContent } from './Data/MenuContent'
import { Helmet } from 'react-helmet'

export default function UserProfileIndex() {
  const [menuActive, setMenuActive] = useState(1)

  return (
    <>
      <Helmet>
        <title>Profile</title>
      </Helmet>
      <LayoutUser>
        <div className="w-full pt-[70px] flex">
          <div className="xl:w-[21%] bg-[#323232] fixed h-[120vh] pt-[50px]">
            {Menu.map((m, i) => {
              return (
                <li
                  key={i}
                  onClick={() => setMenuActive(m.id)}
                  className={
                    menuActive === m.id
                      ? 'p-3 bg-white border-r-4 border-[#F39058] flex items-center gap-2 font-semibold cursor-pointer hover:text-[#F39058]'
                      : 'p-3 flex items-center gap-2 text-[#fff] cursor-pointer hover:text-[#F39058]'
                  }
                >
                  <i>{m.icon}</i>
                  <span className="hidden xl:block">{m.menu}</span>
                </li>
              )
            })}
          </div>
          <div className="ml-[52px] xl:ml-[21%] w-full">
            {MenuContent.filter((m) => m.id === menuActive).map((m, i) => {
              return <div key={i}>{m.component}</div>
            })}
          </div>
        </div>
      </LayoutUser>
    </>
  )
}
