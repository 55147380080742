import axios from "axios";
import React, { useState, useEffect } from "react";
import { BiArrowBack } from "react-icons/bi";
import Select from "react-select";
import WindowedSelect, { createFilter } from "react-windowed-select";
import api from "../../Api/api";
import { Month } from "./Data/month.ts";
import { generateYear } from "./Data/helper";
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import { ScrollTop } from "../../utils/ScrollTop";
import Swal from "sweetalert2";

export default function Pendidikan() {
  const List_year = generateYear();
  const [showForm, setShowForm] = useState(false);
  const [degrees, setDegrees] = useState([]);
  const [country, setCountry] = useState([]);
  const [city, setCity] = useState([]);
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [alertFail, setAlertFail] = useState(false);
  const [loading, setLoading] = useState(false);
  const customFilter = createFilter({ ignoreAccents: false });

  const [action, setAction] = useState(1);
  const [dataPendidikanForShow, setDataPendidikanForshow] = useState([]);
  const [dataPendidikanForUpdate, setDataPendidikanForUpdate] = useState({
    users_education_id: "",
    degrees_id: "",
    users_education_school_name: "",
    users_education_major: "",
    users_education_present: false,
    country_id: "",
    city_id: "",
    users_education_from_month: "",
    users_education_from_year: "",
    users_education_to_month: "",
    users_education_to_year: "",
  });

  useEffect(() => {
    fetchDegrees();
    fetchCountryCity();
    showPendidikan();
    ScrollTop();
  }, []);

  const fetchDegrees = async () => {
    const users = JSON.parse(localStorage.getItem("user"));

    await axios
      .get(api.degrees, {
        headers: {
          "Content-type": "application/json",
          Authorization: `bearer ${users?.token}`,
        },
      })
      .then((response) => {
        setDegrees(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchCountryCity = async () => {
    const users = JSON.parse(localStorage.getItem("user"));

    await axios
      .get(api.countryCity, {
        headers: {
          "Content-type": "application/json",
          Authorization: `bearer ${users?.token}`,
        },
      })
      .then((response) => {
        setCountry(response.data.country);
        setCity(response.data.city);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const showPendidikan = async () => {
    setLoading(true);
    const users = JSON.parse(localStorage.getItem("user"));
    const url = `${api.education}?users_email=${users.user_email}`;

    await axios
      .get(url, {
        headers: {
          "Content-type": "application/json",
          Authorization: `bearer ${users?.token}`,
        },
      })
      .then((response) => {
        setDataPendidikanForshow(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const fillValueFormUpdate = (id) => {
    setAction(2);
    dataPendidikanForShow
      .filter((data) => data.users_education_id === id)
      .map((data) => {
        setDataPendidikanForUpdate((prev) => ({
          ...prev,
          users_education_id: data.users_education_id,
          degrees_id: data.degrees_id,
          users_education_school_name: data.users_education_school_name,
          users_education_major: data.users_education_major,
          country_id: data.country_id,
          city_id: data.city_id,
          users_education_present:
            data.users_education_present === "true" ? true : false,
          users_education_from_month: data.users_education_from_month,
          users_education_from_year: data.users_education_from_year,
          users_education_to_month: data.users_education_to_month,
          users_education_to_year: data.users_education_to_year,
        }));
      });
  };

  const insertPendidikan = async () => {
    const users = JSON.parse(localStorage.getItem("user"));
    const url = `${api.education}?users_email=${users.user_email}`;

    const data = {
      degrees_id: dataPendidikanForUpdate.degrees_id,
      users_education_school_name:
        dataPendidikanForUpdate.users_education_school_name,
      users_education_major: dataPendidikanForUpdate.users_education_major,
      users_education_present: `${dataPendidikanForUpdate.users_education_present}`,
      country_id: dataPendidikanForUpdate.country_id,
      city_id: dataPendidikanForUpdate.city_id,
      users_education_from_month:
        dataPendidikanForUpdate.users_education_from_month,
      users_education_from_year:
        dataPendidikanForUpdate.users_education_from_year,
      users_education_to_month:
        dataPendidikanForUpdate.users_education_to_month,
      users_education_to_year: dataPendidikanForUpdate.users_education_to_year,
    };

    await axios
      .post(url, data, {
        headers: {
          "Content-type": "application/json",
          Authorization: `bearer ${users?.token}`,
        },
      })
      .then((response) => {
        setAlertSuccess(true);
        setTimeout(() => {
          setAlertSuccess(false);
        }, 3000);
        showPendidikan();
        ScrollTop();
        setAction(1);
        setDataPendidikanForUpdate((prev) => ({
          ...prev,
          users_education_id: "",
          degrees_id: null,
          users_education_school_name: "",
          users_education_major: "",
          country_id: null,
          city_id: null,
          users_education_present: false,
          users_education_from_month: "",
          users_education_from_year: "",
          users_education_to_month: "",
          users_education_to_year: "",
        }));
      })
      .catch((error) => {
        console.log(error);
        setAlertFail(true);
        setTimeout(() => {
          setAlertFail(false);
        }, 3000);
        showPendidikan();
        ScrollTop();
        setAction(1);
        setDataPendidikanForUpdate((prev) => ({
          ...prev,
          users_education_id: "",
          degrees_id: null,
          users_education_school_name: "",
          users_education_major: "",
          country_id: null,
          city_id: null,
          users_education_present: false,
          users_education_from_month: "",
          users_education_from_year: "",
          users_education_to_month: "",
          users_education_to_year: "",
        }));
      });
  };

  const updatePendidikan = async () => {
    const users = JSON.parse(localStorage.getItem("user"));
    const url = `${api.education}/${dataPendidikanForUpdate.users_education_id}`;

    const data = {
      degrees_id: dataPendidikanForUpdate.degrees_id,
      users_education_school_name:
        dataPendidikanForUpdate.users_education_school_name,
      users_education_major: dataPendidikanForUpdate.users_education_major,
      users_education_present: `${dataPendidikanForUpdate.users_education_present}`,
      country_id: dataPendidikanForUpdate.country_id,
      city_id: dataPendidikanForUpdate.city_id,
      users_education_from_month:
        dataPendidikanForUpdate.users_education_from_month,
      users_education_from_year:
        dataPendidikanForUpdate.users_education_from_year,
      users_education_to_month:
        dataPendidikanForUpdate.users_education_to_month,
      users_education_to_year: dataPendidikanForUpdate.users_education_to_year,
    };

    await axios
      .put(url, data, {
        headers: {
          "Content-type": "application/json",
          Authorization: `bearer ${users?.token}`,
        },
      })
      .then((response) => {
        setAlertSuccess(true);
        setTimeout(() => {
          setAlertSuccess(false);
        }, 3000);
        showPendidikan();
        ScrollTop();
        setAction(1);
        setDataPendidikanForUpdate((prev) => ({
          ...prev,
          users_education_id: "",
          degrees_id: null,
          users_education_school_name: "",
          users_education_major: "",
          country_id: null,
          city_id: null,
          users_education_present: false,
          users_education_from_month: "",
          users_education_from_year: "",
          users_education_to_month: "",
          users_education_to_year: "",
        }));
      })
      .catch((error) => {
        console.log(error);
        setAlertFail(true);
        setTimeout(() => {
          setAlertFail(false);
        }, 3000);
        showPendidikan();
        ScrollTop();
        setAction(1);
        setDataPendidikanForUpdate((prev) => ({
          ...prev,
          users_education_id: "",
          degrees_id: null,
          users_education_school_name: "",
          users_education_major: "",
          country_id: null,
          city_id: null,
          users_education_present: false,
          users_education_from_month: "",
          users_education_from_year: "",
          users_education_to_month: "",
          users_education_to_year: "",
        }));
      });
  };

  const deletePendidikan = async (id) => {
    Swal.fire({
      title: "Delete education?",
      text: "Are you sure want to delete this data?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#F39058",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        const users = JSON.parse(localStorage.getItem("user"));
        const url = `${api.education}/${id}`;

        axios
          .delete(url, {
            headers: {
              "Content-type": "application/json",
              Authorization: `bearer ${users?.token}`,
            },
          })
          .then((response) => {
            Swal.fire({
              title: "Success",
              text: "Succes delete data",
              icon: "success",
              confirmButtonColor: "#F39058",
            });
            setDataPendidikanForUpdate((prev) => ({
              ...prev,
              users_education_id: "",
              degrees_id: null,
              users_education_school_name: "",
              users_education_major: "",
              country_id: null,
              city_id: null,
              users_education_present: false,
              users_education_from_month: "",
              users_education_from_year: "",
              users_education_to_month: "",
              users_education_to_year: "",
            }));
            showPendidikan();
            setAction(1);
          })
          .catch((error) => {
            console.log(error);
            Swal.fire({
              title: "Failed",
              text: "Failed delete data",
              icon: "error",
              confirmButtonColor: "#F39058",
            });
            setDataPendidikanForUpdate((prev) => ({
              ...prev,
              users_education_id: "",
              degrees_id: null,
              users_education_school_name: "",
              users_education_major: "",
              country_id: null,
              city_id: null,
              users_education_present: false,
              users_education_from_month: "",
              users_education_from_year: "",
              users_education_to_month: "",
              users_education_to_year: "",
            }));
            showPendidikan();
            setAction(1);
          });
      }
    });
  };

  const handleClearDataPendidikanForUpdate = () => {
    setDataPendidikanForUpdate((prev) => ({
      ...prev,
      users_education_id: "",
      degrees_id: null,
      users_education_school_name: "",
      users_education_major: "",
      country_id: null,
      city_id: null,
      users_education_present: false,
      users_education_from_month: "",
      users_education_from_year: "",
      users_education_to_month: "",
      users_education_to_year: "",
    }));
    setAction(1);
  };

  // options value
  const degreesOptions = degrees.map((item) => {
    return { value: item.degrees_id, label: item.degrees_name };
  });

  const countryOptions = country.map((item) => {
    return { value: item.country_id, label: item.country_name };
  });

  const cityOptions = city
    .filter(
      (c) => c.country_id === parseInt(dataPendidikanForUpdate?.country_id)
    )
    .map((item) => {
      return { value: item.city_id, label: item.city_name };
    });

  return (
    <div className="w-full p-[20px] xl:p-[30px] min-h-screen">
      {loading && (
        <>
          <div className="w-full pt-20">
            <div className="flex items-center justify-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </>
      )}

      {!loading && (
        <>
          <div className="flex flex-col items-start gap-[40px] md:justify-between md:flex-row">
            <div className="border-b-4 border-[#F39058] text-2xl font-semibold">
              Education
            </div>
            {!showForm && (
              <button
                onClick={() => {
                  setShowForm(!showForm);
                }}
                className="py-2 px-10 bg-[#F39058] text-white rounded-full hover:bg-[#f47126] text-sm"
              >
                Change
              </button>
            )}
          </div>

          {!showForm && (
            <>
              <div className="mt-[60px]">
                {dataPendidikanForShow?.length > 0 ? (
                  <>
                    {dataPendidikanForShow?.map((data, index) => {
                      return (
                        <div
                          key={index}
                          className="flex flex-col mb-4 xl:flex-row gap-[10px] "
                        >
                          {data.users_education_present === "true" && (
                            <div className="w-full xl:w-[20%] font-semibold">
                              {data.users_education_from_year} - Now
                            </div>
                          )}
                          {data.users_education_present === "false" && (
                            <div className="w-full xl:w-[20%] font-semibold">
                              {data.users_education_from_year} -
                              {data.users_education_to_year}
                            </div>
                          )}

                          <div className="w-full xl:w-[70%]">
                            <div className="font-semibold">
                              {data.users_education_school_name}
                            </div>
                            <div className="text-[#989898]">
                              {data.users_education_major}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <div className="w-full px-10 pt-[70px]">
                    <div className="flex items-center justify-center gap-4 font-semibold">
                      <img src="/images/noData.png" alt="data-not-found" /> No
                      data
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </>
      )}

      {showForm && (
        <>
          <div className="mt-[60px]">
            <div className="w-full">
              {alertSuccess && (
                <div className="text-white alert alert-success" role="alert">
                  Success save data
                </div>
              )}
              {alertFail && (
                <div className="text-white alert alert-danger" role="alert">
                  Failed save data
                </div>
              )}
            </div>
            <button
              onClick={() => {
                setShowForm(!showForm);
              }}
              className="flex items-center gap-[5px] py-2 px-4 bg-[#F39058] text-white rounded-full hover:bg-[#f47126] mb-4 text-sm"
            >
              <BiArrowBack /> Back
            </button>

            <div className="mt-[20px]">
              {dataPendidikanForShow?.map((data) => {
                return (
                  <div
                    key={Math.random()}
                    className="w-full bg-white rounded-sm shadow-md py-[15px] px-[30px] flex justify-between items-center mb-3"
                  >
                    <div>
                      <div className="mb-2 font-bold">
                        {data?.users_education_school_name}
                      </div>
                      <div className="mb-1 text-sm">
                        {data?.users_education_major} | {data?.city_name},{" "}
                        {data?.country_name}
                      </div>
                      {data?.users_education_present === "true" && (
                        <div className="text-sm">
                          {data?.users_education_from_month}{" "}
                          {data?.users_education_from_year} - Now
                        </div>
                      )}
                      {data?.users_education_present === "false" && (
                        <div className="text-sm">
                          {data?.users_education_from_month}{" "}
                          {data?.users_education_from_year} -{" "}
                          {data?.users_education_to_month}{" "}
                          {data?.users_education_to_year}
                        </div>
                      )}
                    </div>
                    <div className="flex flex-col md:flex-row gap-3 text-[#FF9A62] text-2xl items-start">
                      <button
                        className="p-2 hover:text-[#9b532d]"
                        onClick={() => {
                          fillValueFormUpdate(data.users_education_id);
                          const section = document.querySelector("#update");
                          section.scrollIntoView({
                            behavior: "smooth",
                            block: "start",
                          });
                        }}
                      >
                        <AiFillEdit />
                      </button>

                      <button
                        onClick={() =>
                          deletePendidikan(data.users_education_id)
                        }
                        className="p-2 hover:text-[#9b532d]"
                      >
                        <AiFillDelete />
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="py-[10px]" id="update"></div>

            <h1 className="py-[15px] font-semibold">Add / Update Education</h1>

            <div className="w-full">
              {(dataPendidikanForUpdate.degrees_id ||
                dataPendidikanForUpdate.users_education_school_name ||
                dataPendidikanForUpdate.users_education_major ||
                dataPendidikanForUpdate.country_id ||
                dataPendidikanForUpdate.city_id ||
                dataPendidikanForUpdate.users_education_from_month ||
                dataPendidikanForUpdate.users_education_from_year) && (
                <button
                  onClick={handleClearDataPendidikanForUpdate}
                  className="flex items-center gap-2 py-2 text-sm font-semibold text-red-400 hover:text-red-800"
                >
                  Clear all fields <AiFillDelete />
                </button>
              )}
            </div>

            <div>
              <label className="mt-3 form-label">
                Degree <span className="text-danger">*</span>
              </label>
              <Select
                isClearable={true}
                options={degreesOptions}
                onChange={(event) => {
                  event === null
                    ? setDataPendidikanForUpdate((prev) => ({
                        ...prev,
                        degrees_id: null,
                      }))
                    : setDataPendidikanForUpdate((prev) => ({
                        ...prev,
                        degrees_id: event.value,
                      }));
                }}
                value={
                  degreesOptions.find(
                    (c) =>
                      c.value === parseInt(dataPendidikanForUpdate?.degrees_id)
                  ) == null
                    ? null
                    : degreesOptions.find(
                        (c) =>
                          c.value ===
                          parseInt(dataPendidikanForUpdate?.degrees_id)
                      )
                }
                components={{
                  IndicatorSeparator: () => null,
                }}
                placeholder="Choose degree"
                menuPlacement="auto"
              />
              {!dataPendidikanForUpdate?.degrees_id && (
                <div className="mt-[8px] text-[13px] text-red-500">
                  degree required
                </div>
              )}

              <label className="mt-3 form-label">
                School / University <span className="text-danger">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                placeholder="School / University"
                value={dataPendidikanForUpdate?.users_education_school_name}
                onChange={(e) =>
                  setDataPendidikanForUpdate((prev) => ({
                    ...prev,
                    users_education_school_name: e.target.value,
                  }))
                }
              />
              {!dataPendidikanForUpdate?.users_education_school_name && (
                <div className="mt-[8px] text-[13px] text-red-500">
                  School / University required
                </div>
              )}

              <label className="mt-3 form-label">
                Major <span className="text-danger">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                placeholder="major"
                value={dataPendidikanForUpdate?.users_education_major}
                onChange={(e) =>
                  setDataPendidikanForUpdate((prev) => ({
                    ...prev,
                    users_education_major: e.target.value,
                  }))
                }
              />
              {!dataPendidikanForUpdate?.users_education_major && (
                <div className="mt-[8px] text-[13px] text-red-500">
                  Major required
                </div>
              )}

              <label className="mt-3 form-label">
                Country <span className="text-danger">*</span>
              </label>
              <Select
                isClearable={true}
                options={countryOptions}
                onChange={(event) => {
                  event === null
                    ? setDataPendidikanForUpdate((prev) => ({
                        ...prev,
                        country_id: null,
                        city_id: null,
                      }))
                    : setDataPendidikanForUpdate((prev) => ({
                        ...prev,
                        country_id: event.value,
                      }));
                }}
                value={
                  countryOptions.find(
                    (c) =>
                      c.value === parseInt(dataPendidikanForUpdate?.country_id)
                  ) == null
                    ? null
                    : countryOptions.find(
                        (c) =>
                          c.value ===
                          parseInt(dataPendidikanForUpdate?.country_id)
                      )
                }
                components={{
                  IndicatorSeparator: () => null,
                }}
                placeholder="Choose country"
                menuPlacement="auto"
              />
              {!dataPendidikanForUpdate?.country_id && (
                <div className="mt-[8px] text-[13px] text-red-500">
                  Country required
                </div>
              )}

              <label className="mt-3 form-label">
                City <span className="text-danger">*</span>
              </label>
              <WindowedSelect
                filterOption={customFilter}
                isClearable={true}
                options={cityOptions}
                onChange={(event) => {
                  event === null
                    ? setDataPendidikanForUpdate((prev) => ({
                        ...prev,
                        city_id: null,
                      }))
                    : setDataPendidikanForUpdate((prev) => ({
                        ...prev,
                        city_id: event.value,
                      }));
                }}
                value={
                  cityOptions.find(
                    (c) =>
                      c.value === parseInt(dataPendidikanForUpdate?.city_id)
                  ) == null
                    ? null
                    : cityOptions.find(
                        (c) =>
                          c.value === parseInt(dataPendidikanForUpdate?.city_id)
                      )
                }
                components={{
                  IndicatorSeparator: () => null,
                }}
                placeholder="Choose city"
                menuPlacement="auto"
              />
              {!dataPendidikanForUpdate?.city_id && (
                <div className="mt-[8px] text-[13px] text-red-500">
                  City required
                </div>
              )}

              <label className="mt-3 form-label">Period</label>
              <div className="form-check">
                <input
                  className="cursor-pointer form-check-input"
                  type="checkbox"
                  id="time_periode"
                  value=""
                  checked={dataPendidikanForUpdate.users_education_present}
                  onChange={() =>
                    setDataPendidikanForUpdate((prev) => ({
                      ...prev,
                      users_education_present:
                        !dataPendidikanForUpdate.users_education_present,
                      users_education_to_month: null,
                      users_education_to_year: null,
                    }))
                  }
                />
                <label
                  className="cursor-pointer form-check-label"
                  htmlFor="time_periode"
                >
                  Until now
                </label>
              </div>

              <div className="flex flex-col items-end gap-3 md:flex-row">
                <div className="mt-3 mb-2 w-[100%] md:w-[25%]">
                  <h1 className="mb-2">
                    From <span className="text-danger">*</span>
                  </h1>
                  <Select
                    value={
                      Month.find(
                        (c) =>
                          c.value ===
                          `${dataPendidikanForUpdate.users_education_from_month}`
                      ) == null
                        ? null
                        : Month.find(
                            (c) =>
                              c.value ===
                              `${dataPendidikanForUpdate.users_education_from_month}`
                          )
                    }
                    isClearable={true}
                    options={Month}
                    onChange={(event) =>
                      event == null
                        ? setDataPendidikanForUpdate((prev) => ({
                            ...prev,
                            users_education_from_month: null,
                          }))
                        : setDataPendidikanForUpdate((prev) => ({
                            ...prev,
                            users_education_from_month: event.value,
                          }))
                    }
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    menuPlacement="auto"
                    placeholder={"Month"}
                  />
                </div>

                <div className="mb-2 w-[100%] md:w-[25%]">
                  <Select
                    value={
                      List_year.find(
                        (c) =>
                          c.value ===
                          parseInt(
                            dataPendidikanForUpdate.users_education_from_year
                          )
                      ) == null
                        ? null
                        : List_year.find(
                            (c) =>
                              c.value ===
                              parseInt(
                                dataPendidikanForUpdate.users_education_from_year
                              )
                          )
                    }
                    isClearable={true}
                    options={List_year}
                    onChange={(event) =>
                      event == null
                        ? setDataPendidikanForUpdate((prev) => ({
                            ...prev,
                            users_education_from_year: null,
                          }))
                        : setDataPendidikanForUpdate((prev) => ({
                            ...prev,
                            users_education_from_year: event.value,
                          }))
                    }
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    menuPlacement="auto"
                    placeholder={"Year"}
                  />
                </div>

                <div className="mb-2 w-[100%] md:w-[25%]">
                  <h1 className="mb-2">To</h1>
                  <Select
                    value={
                      Month.find(
                        (c) =>
                          c.value ===
                          `${dataPendidikanForUpdate.users_education_to_month}`
                      ) == null
                        ? null
                        : Month.find(
                            (c) =>
                              c.value ===
                              `${dataPendidikanForUpdate.users_education_to_month}`
                          )
                    }
                    isClearable={true}
                    options={Month}
                    onChange={(event) =>
                      event == null
                        ? setDataPendidikanForUpdate((prev) => ({
                            ...prev,
                            users_education_to_month: null,
                          }))
                        : setDataPendidikanForUpdate((prev) => ({
                            ...prev,
                            users_education_to_month: event.value,
                          }))
                    }
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    menuPlacement="auto"
                    placeholder={"Month"}
                    isDisabled={
                      dataPendidikanForUpdate.users_education_present == true
                        ? true
                        : false
                    }
                  />
                </div>

                <div className="mb-2 w-[100%] md:w-[25%]">
                  <Select
                    value={
                      List_year.find(
                        (c) =>
                          c.value ===
                          parseInt(
                            dataPendidikanForUpdate.users_education_to_year
                          )
                      ) == null
                        ? null
                        : List_year.find(
                            (c) =>
                              c.value ===
                              parseInt(
                                dataPendidikanForUpdate.users_education_to_year
                              )
                          )
                    }
                    isClearable={true}
                    options={List_year}
                    onChange={(event) =>
                      event == null
                        ? setDataPendidikanForUpdate((prev) => ({
                            ...prev,
                            users_education_to_year: null,
                          }))
                        : setDataPendidikanForUpdate((prev) => ({
                            ...prev,
                            users_education_to_year: event.value,
                          }))
                    }
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    menuPlacement="auto"
                    placeholder={"Year"}
                    isDisabled={
                      dataPendidikanForUpdate.users_education_present == true
                        ? true
                        : false
                    }
                  />
                </div>
              </div>
              {(!dataPendidikanForUpdate.users_education_from_month ||
                !dataPendidikanForUpdate.users_education_from_year) && (
                <div className="text-[13px] text-red-500">Period required</div>
              )}

              <div className="flex justify-end w-full">
                {(!dataPendidikanForUpdate.degrees_id ||
                  !dataPendidikanForUpdate.users_education_school_name ||
                  !dataPendidikanForUpdate.users_education_major ||
                  !dataPendidikanForUpdate.country_id ||
                  !dataPendidikanForUpdate.city_id ||
                  !dataPendidikanForUpdate.users_education_from_month ||
                  !dataPendidikanForUpdate.users_education_from_year) && (
                  <button
                    className="flex items-center gap-[5px] py-2 px-4 bg-[#fabc9b] text-white rounded-full mt-4 text-sm cursor-not-allowed"
                    disabled
                  >
                    Save
                  </button>
                )}

                {dataPendidikanForUpdate.degrees_id &&
                  dataPendidikanForUpdate.users_education_school_name &&
                  dataPendidikanForUpdate.users_education_major &&
                  dataPendidikanForUpdate.country_id &&
                  dataPendidikanForUpdate.city_id &&
                  dataPendidikanForUpdate.users_education_from_month &&
                  dataPendidikanForUpdate.users_education_from_year && (
                    <button
                      onClick={
                        action === 1 ? insertPendidikan : updatePendidikan
                      }
                      className="flex items-center gap-[5px] py-2 px-4 bg-[#F39058] text-white rounded-full hover:bg-[#f47126] mt-4 text-sm"
                    >
                      Save
                    </button>
                  )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
