import style from "../LandingPage.module.css";
import Button from "./Button";
import landingPagePicture from "../../../assets/images/landing-page-picture.webp";
import landingPagePicture2 from "../../../assets/images/landing-page-picture-2.webp";

export default function ThirdSection({ isAuth }) {
  return (
    <div className={style.thirdContainer}>
      <div className={style.secondAbout} id="explore">
        <img
          src={landingPagePicture}
          className={style.secondAboutImg}
          alt="second-about"
        />
        <div>
          <div className="mb-5 text-4xl font-semibold xl:text-5xl">
            Find your best company
          </div>
          <div className={`mb-4 ${style.secondAboutDescription}`}>
            Find all companies from start up companies to big companies and see
            all the available job vacancies
          </div>
          <Button isAuth={isAuth} text="Join Us" />
        </div>
      </div>
      <div className={style.thirdAbout}>
        <div>
          <div className="mb-5 text-4xl font-semibold xl:text-5xl">
            Start your journey now!
          </div>
          <div className={`${style.secondAboutDescription} mb-4`}>
            And find your dream job here with us
          </div>
          <Button isAuth={isAuth} text="Join Us" />
        </div>
        <img
          src={landingPagePicture2}
          className={style.secondAboutImg}
          alt="second-about"
        />
      </div>
    </div>
  );
}
