import style from "../LowonganKerja.module.css";
import { IoCloseSharp } from "react-icons/io5";
import Toggle from "react-toggle";
import "react-toggle/style.css";

export default function FilterResponsive({
  filterResponsive,
  handleFilterResponsive,
  tampilBerdasarkanValue,
  setTampilBerdasarkanValue,
  tipePekerjaanValue,
  setTipePekerjaanValue,
  tanpaPengalamanValue,
  setTanpaPengalamanValue,
  pengalamanValue,
  setPengalamanValue,
  kerjaRemoteValue,
  setKerjaRemoteValue,
}) {
  return (
    <div
      className={
        filterResponsive
          ? `${style.filterRes} ${style.filterShow}`
          : style.filterRes
      }
    >
      <div className={style.filterWrap}>
        <div className={style.close} onClick={handleFilterResponsive}>
          <IoCloseSharp />
        </div>
        <div className={style.filterContent}>
          {/* <div className={style.show}>Short By</div> */}
          {/* <div className={style.buttonShowWrap}>
            <button
              className={
                tampilBerdasarkanValue === 1
                  ? `${style.buttonShow} ${style.active}`
                  : `${style.buttonShow}`
              }
              onClick={() => setTampilBerdasarkanValue(1)}
            >
              Newest
            </button>
            <button
              className={
                tampilBerdasarkanValue === 2
                  ? `${style.buttonShow} ${style.active}`
                  : `${style.buttonShow}`
              }
              onClick={() => setTampilBerdasarkanValue(2)}
            >
              Your skill
            </button>
          </div> */}
          {/* <hr /> */}
          <div className={style.workType}>Job type</div>
          <div className={style.radioContent}>
            <div className={style.radioItem}>
              <input
                type="radio"
                id="penuhWaktu"
                onClick={() => setTipePekerjaanValue(1)}
                checked={tipePekerjaanValue === 1 ? true : false}
              />
              <label htmlFor="penuhWaktu">Full time</label>
            </div>
            <div className={style.radioItem}>
              <input
                type="radio"
                id="paruhWaktu"
                onClick={() => setTipePekerjaanValue(2)}
                checked={tipePekerjaanValue === 2 ? true : false}
              />
              <label htmlFor="paruhWaktu">Part time</label>
            </div>
            <div className={style.radioItem}>
              <input
                type="radio"
                id="kontrak"
                onClick={() => setTipePekerjaanValue(3)}
                checked={tipePekerjaanValue === 3 ? true : false}
              />
              <label htmlFor="kontrak">Contract</label>
            </div>
          </div>
          <hr />
          <div className={style.workType}>No experience</div>
          <div className={style.toggleContent}>
            <Toggle
              checked={tanpaPengalamanValue}
              className="custom-toggle"
              onChange={() => setTanpaPengalamanValue(!tanpaPengalamanValue)}
            />
          </div>
          {!tanpaPengalamanValue && (
            <>
              <hr />
              <div className={style.workType}>Experience</div>
              <div className={style.radioContent}>
                <div className={style.radioItem}>
                  <input
                    type="radio"
                    id="kurangSetahun"
                    onChange={() => setPengalamanValue(2)}
                    checked={pengalamanValue === 2 ? true : false}
                  />
                  <label htmlFor="kurangSetahun">Less than a year</label>
                </div>
                <div className={style.radioItem}>
                  <input
                    type="radio"
                    id="1Tahun"
                    onChange={() => setPengalamanValue(3)}
                    checked={pengalamanValue === 3 ? true : false}
                  />
                  <label htmlFor="1Tahun">1 year</label>
                </div>
                <div className={style.radioItem}>
                  <input
                    type="radio"
                    id="2Tahun"
                    onChange={() => setPengalamanValue(4)}
                    checked={pengalamanValue === 4 ? true : false}
                  />
                  <label htmlFor="2Tahun">2 year</label>
                </div>
                <div className={style.radioItem}>
                  <input
                    type="radio"
                    id="3Tahun"
                    onChange={() => setPengalamanValue(5)}
                    checked={pengalamanValue === 5 ? true : false}
                  />
                  <label htmlFor="3Tahun">3 year</label>
                </div>
                <div className={style.radioItem}>
                  <input
                    type="radio"
                    id="lebih3Tahun"
                    onChange={() => setPengalamanValue(6)}
                    checked={pengalamanValue === 6 ? true : false}
                  />
                  <label htmlFor="lebih3Tahun">More than 3 years</label>
                </div>
              </div>
            </>
          )}
          <hr />
          <div className={style.workType}>Open to remote</div>
          <div className={style.toggleContent}>
            <Toggle
              checked={kerjaRemoteValue ? true : false}
              className="custom-toggle"
              onChange={() =>
                kerjaRemoteValue
                  ? setKerjaRemoteValue("")
                  : setKerjaRemoteValue(true)
              }
            />
          </div>
          <div className={style.filterResponsiveInisdeBtnWrap}>
            <button
              className={style.filterResponsiveInsideBtn}
              onClick={handleFilterResponsive}
            >
              Cari Lowongan
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
