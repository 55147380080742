import React from "react";
import moment from "moment";

export default function CardStatusLamaran({ data, cancelLamaran }) {
  moment.locale("en");
  return (
    <div className="flex flex-col justify-between w-full gap-4 p-4 mb-4 bg-white rounded-md shadow-md xl:gap-0 md:flex-row">
      <div className="flex flex-col gap-4 md:flex-row">
        <div>
          <img
            className="w-[89px]"
            src={
              data?.company_photo
                ? `${process.env.REACT_APP_MEDIA_URL}/images/${data?.company_photo}`
                : "/images/company_default.png"
            }
            alt="company"
          />
        </div>
        <div>
          <div className="mb-2 font-semibold">{data.company_jobs_position}</div>
          <div className="text-[#8A8A8A] text-sm mb-[3px] font-semibold">
            {data.company_name}
          </div>
          <div className="text-[#8A8A8A] text-xs mb-[3px]">
            Send at {moment(data.users_apply_created_date).format("Do MMMM YY")}
          </div>
          <div
            className={
              data.apply_status_id == 1
                ? "mt-3 text-sm text-white bg-[#BABABA] max-w-[150px] px-3 py-[2px] text-center rounded-sm"
                : data.apply_status_id == 2 || data.apply_status_id == 3
                ? "mt-3 text-sm text-white bg-green-500 w-[120px] px-3 py-[2px] text-center rounded-sm"
                : "mt-3 text-sm text-white bg-red-500 w-[120px] px-3 py-[2px] text-center rounded-sm"
            }
          >
            {data.apply_status_name}
          </div>
        </div>
      </div>
      <div>
        <button
          onClick={cancelLamaran.bind(this, data.users_apply_id)}
          className="bg-[#F39058] text-white text-sm py-2 px-3 rounded-full hover:bg-[#f7762b]"
        >
          Cancel Apply
        </button>
      </div>
    </div>
  );
}
