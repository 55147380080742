import { Link } from "react-router-dom";
import style from "../LandingPage.module.css";

export default function Button({ isAuth, text }) {
  return (
    <Link to={isAuth ? "/lowongan-kerja" : "login"}>
      <button className={style.btnJoinUs}>{text}</button>
    </Link>
  );
}
