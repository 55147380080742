import signUpStyle from "../Register.module.css";

const RegisterInput = ({ label, validation, ...props }) => {
  return (
    <>
      <div className={signUpStyle.label}>{label}</div>
      <div className={signUpStyle.inputFullname}>
        <input {...props} />
        <small className={signUpStyle.textDanger}>{validation}</small>
      </div>
    </>
  );
};

export default RegisterInput;
