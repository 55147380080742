import React from "react";
import { starValue } from "./StarValue";
import moment from "moment";
import "moment/locale/id";

export default function CardFeedback({ dataRating }) {
  return (
    <div className="shadow-md w-full bg-white rounded-md py-[25px] px-[30px] mb-[30px]">
      <div className="flex flex-col gap-3 md:items-center md:flex-row ">
        <img
          className="w-[60px]"
          src={
            dataRating?.company_photo
              ? `${process.env.REACT_APP_MEDIA_URL}/images/${dataRating?.company_photo}`
              : "/images/company_default.png"
          }
          alt="company"
        />
        <div className="text-sm font-semibold">{dataRating.company_name}</div>
        <div className="text-sm text-[#8A8A8A]">
          {moment(dataRating.users_rating_created_date).format("Do MMMM YY")}
        </div>
        {starValue(dataRating.users_rating_value)}
      </div>
      <p className="mt-[20px] text-sm">{dataRating.users_rating_comment}</p>
    </div>
  );
}
