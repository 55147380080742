export default function Tab({ menuActive, setMenuActive }) {
  return (
    <div className="pt-[75px]">
      <div className="w-full bg-[#f2f2f3]">
        <div className="max-w-[1200px] m-auto flex gap-8 py-4 px-4 xl:px-0 font-semibold text-sm">
          <button
            className={
              menuActive === 1 ? "text-[#FE5D40]" : "hover:text-[#FE5D40]"
            }
            onClick={() => setMenuActive(1)}
          >
            Jobs
          </button>
          <button
            className={
              menuActive === 2 ? "text-[#FE5D40]" : "hover:text-[#FE5D40]"
            }
            onClick={() => setMenuActive(2)}
          >
            Company
          </button>
        </div>
      </div>
    </div>
  );
}
