export const Menu = [
  {
    id: 1,
    menu: "All",
  },
  {
    id: 2,
    menu: "Waiting",
  },
  {
    id: 3,
    menu: "Interview",
  },
  {
    id: 4,
    menu: "Reject",
  },
];
