/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import style from "./LowonganKerja.module.css";
import { ScrollTop } from "../../../utils/ScrollTop";
import Search from "./components/Search";
import BtnFilterResponsive from "./components/BtnFilterResponsive";
import FilterResponsive from "./components/FilterResponsive";
import FilterSidebar from "./components/FilterSidebar";
import Job from "./components/Job";
import Loading from "./components/Loading";
import JobNotFound from "./components/JobNotFound";
import ScrollTopBtn from "./components/ScrollTopBtn";
import { getDataJob } from "../../../Api/api-libs";
import { getUser } from "../../../utils/auth";

export default function LowonganKerja() {
  const [dataJob, setDataJob] = useState([]);
  const [totalDataJob, setTotalDataJob] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);

  const [showScrollBtn, setShowScrollBtn] = useState(false);
  const [page, setPage] = useState(1);

  // state for responsive filter sidebar
  const [filterResponsive, setFilterResponsive] = useState(false);

  // search
  const [filterProfesi, setFilterProfesi] = useState("");
  const [filterLokasi, setFilterLokasi] = useState("");

  // filter sidebar
  const [tampilBerdasarkanValue, setTampilBerdasarkanValue] = useState(1);
  const [tipePekerjaanValue, setTipePekerjaanValue] = useState("");
  const [tanpaPengalamanValue, setTanpaPengalamanValue] = useState(false);
  const [pengalamanValue, setPengalamanValue] = useState("");
  const [kerjaRemoteValue, setKerjaRemoteValue] = useState("");

  useEffect(() => {
    setIsLoading(true);
    fetchDataJob();

    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowScrollBtn(true);
      } else {
        setShowScrollBtn(false);
      }
    });
  }, []);

  useEffect(() => {
    ScrollTop();
    setIsLoading(true);
    fetchDataJob();
    setPage(1);
  }, [
    filterProfesi,
    filterLokasi,
    tampilBerdasarkanValue,
    tipePekerjaanValue,
    tanpaPengalamanValue,
    pengalamanValue,
    kerjaRemoteValue,
  ]);

  useEffect(() => {
    setLoadingPage(true);
    fetchDataJob();
  }, [page]);

  // fetch data job
  const fetchDataJob = async () => {
    const filterLocation = filterLokasi ? filterLokasi?.value : "";
    const tanpaPengalaman = tanpaPengalamanValue ? "1" : "";
    const user = getUser();
    const userEmailValue = user ? user.email : "";

    try {
      const response = await getDataJob(
        filterProfesi,
        filterLocation,
        tampilBerdasarkanValue,
        userEmailValue,
        tipePekerjaanValue,
        tanpaPengalaman,
        pengalamanValue,
        kerjaRemoteValue,
        page
      );

      if (page === 1) {
        setDataJob(response.data.data_job.data);
        setTotalDataJob(response.data.data_job.total);
      } else {
        setDataJob([...dataJob, ...response.data.data_job.data]);
        setTotalDataJob(response.data.data_job.total);
      }
    } catch (error) {
      console.log(error);
    }

    setIsLoading(false);
    setLoadingPage(false);
  };

  // toogle responsive filter
  const handleFilterResponsive = () => {
    setFilterResponsive(!filterResponsive);
  };

  // handle for input search profesi and lokasi job
  const handleLocation = (value) => {
    setFilterLokasi(value);
  };

  const handleOnSearch = (string) => {
    setFilterProfesi(string);
  };

  const handleOnSelect = (item) => {
    setFilterProfesi(item.name);
  };

  return (
    <>
      <div className="bg-[#f7f7f7] pb-[120px]">
        <Search
          onSearch={handleOnSearch}
          onSelect={handleOnSelect}
          onChange={handleLocation}
          filterLokasi={filterLokasi}
        />
        <BtnFilterResponsive onClick={handleFilterResponsive} />
        {filterResponsive && (
          <FilterResponsive
            filterResponsive={filterResponsive}
            handleFilterResponsive={handleFilterResponsive}
            tampilBerdasarkanValue={tampilBerdasarkanValue}
            setTampilBerdasarkanValue={setTampilBerdasarkanValue}
            tipePekerjaanValue={tipePekerjaanValue}
            setTipePekerjaanValue={setTipePekerjaanValue}
            tanpaPengalamanValue={tanpaPengalamanValue}
            setTanpaPengalamanValue={setTanpaPengalamanValue}
            pengalamanValue={pengalamanValue}
            setPengalamanValue={setPengalamanValue}
            kerjaRemoteValue={kerjaRemoteValue}
            setKerjaRemoteValue={setKerjaRemoteValue}
          />
        )}

        <div className={`${style.secondContainer} `}>
          <FilterSidebar
            tampilBerdasarkanValue={tampilBerdasarkanValue}
            setTampilBerdasarkanValue={setTampilBerdasarkanValue}
            tipePekerjaanValue={tipePekerjaanValue}
            setTipePekerjaanValue={setTipePekerjaanValue}
            tanpaPengalamanValue={tanpaPengalamanValue}
            setTanpaPengalamanValue={setTanpaPengalamanValue}
            pengalamanValue={pengalamanValue}
            setPengalamanValue={setPengalamanValue}
            kerjaRemoteValue={kerjaRemoteValue}
            setKerjaRemoteValue={setKerjaRemoteValue}
          />

          {!isLoading && (
            <>
              {dataJob?.length > 0 && (
                <Job
                  dataJob={dataJob}
                  loadingPage={loadingPage}
                  totalDataJob={totalDataJob}
                  page={page}
                  setPage={setPage}
                />
              )}
              {dataJob?.length === 0 && <JobNotFound />}
            </>
          )}
          {isLoading && <Loading />}
        </div>
      </div>
      {showScrollBtn && <ScrollTopBtn ScrollTop={ScrollTop} />}
    </>
  );
}
