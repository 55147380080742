import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/layouts/Layout";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import { convertToHTML } from "draft-convert";
import style from "../UserProfile/Profile.module.css";
import { useParams } from "react-router-dom";
import axios from "axios";
import api from "../../Api/api";
import Swal from "sweetalert2";
import { ScrollTop } from "../../utils/ScrollTop";
import { AiFillFilePdf } from "react-icons/ai";
import { RiAlertFill } from "react-icons/ri";

export default function Lamar() {
  const [companyJobs, setCompanyJobs] = useState([]);
  const [cekApply, setCekApply] = useState([]);
  const [resume, setResume] = useState(1); // hardcode sementara

  let params = useParams();
  const jobId = params.jobId;

  // surat lamaran
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [convertedContent, setConvertedContent] = useState(null);
  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
  };
  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
  };

  useEffect(() => {
    fetchCompanyJobData();
    showResume();
  }, []);

  const fetchCompanyJobData = async () => {
    const users = JSON.parse(localStorage.getItem("user"));
    const url = `${api.usersApply}?company_jobs_id=${jobId}&users_email=${users.user_email}`;

    await axios
      .get(url, {
        headers: {
          "Content-type": "application/json",
          Authorization: `bearer ${users?.token}`,
        },
      })
      .then((response) => {
        setCompanyJobs(response.data.data_company_jobs);
        setCekApply(response.data.cek_users_apply);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const showResume = async () => {
    const users = JSON.parse(localStorage.getItem("user"));
    const url = `${api.resume}?users_email=${users?.user_email}`;

    await axios
      .get(url, {
        headers: {
          Authorization: `bearer ${users?.token}`,
        },
      })
      .then((response) => {
        setResume(response.data.users_resume);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleApply = () => {
    Swal.fire({
      title: "Apply this job?",
      text: "Are you sure want to apply this job?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#F39058",
      cancelButtonColor: "#d33",
      confirmButtonText: "Apply",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const users = JSON.parse(localStorage.getItem("user"));
        const url = `${api.usersApply}`;
        const data = {
          users_email: users?.user_email,
          company_id: companyJobs?.company_id,
          users_resume_id: resume[0]?.users_resume_id,
          company_jobs_id: companyJobs?.company_jobs_id,
          apply_status_id: 1,
          users_apply_cover_letter: convertedContent,
        };

        await axios
          .post(url, data, {
            headers: {
              "Content-type": "application/json",
              Authorization: `bearer ${users?.token}`,
            },
          })
          .then(() => {
            Swal.fire({
              title: "Succes",
              text: "Success apply this job",
              icon: "success",
              confirmButtonColor: "#F39058",
            });
            fetchCompanyJobData();
            ScrollTop();
          })
          .catch((error) => {
            Swal.fire({
              title: "Failed",
              text: "Failed apply this job",
              icon: "error",
              confirmButtonColor: "#F39058",
            });
            console.log(error);
            fetchCompanyJobData();
          });
      }
    });
  };

  return (
    <>
      <Layout title="Apply Job">
        <div className="max-w-[800px] m-auto pt-[100px] px-[15px] xl:px-0">
          <div className="bg-white border-[1px] border-black mb-4 py-[20px] px-[20px] md:py-[30px] md:px-[30px] flex gap-4 items-center rounded">
            <img
              className="w-[100px]"
              src={
                companyJobs?.company_photo
                  ? `${process.env.REACT_APP_MEDIA_URL}/images/${companyJobs?.company_photo}`
                  : "/images/company_default.png"
              }
              alt=""
            />
            <div>
              <h1 className="font-semibold text-md md:text-xl">
                {companyJobs?.company_jobs_position}
              </h1>
              <div className="text-sm">
                {companyJobs?.company_name} &nbsp;| &nbsp;{" "}
                {companyJobs?.city_name}, &nbsp;
                {companyJobs?.country_name}
              </div>
            </div>
          </div>

          {cekApply.length === 0 ? (
            <div className="bg-white border-[1px] border-black mb-[90px] py-[20px] px-[20px] md:py-[30px] md:px-[30px] rounded">
              <h1 className="mb-3 font-semibold">Cover Letter</h1>
              <Editor
                onEditorStateChange={handleEditorChange}
                wrapperClassName={`wrapper-class ${style.wrapperEditor}`}
                editorClassName={`editor-class ${style.editorEditor}`}
                toolbarClassName={`toolbar-class ${style.toolbarEditor} bg-dark`}
                toolbar={{
                  options: ["inline", "list"],
                  inline: {
                    inDropdown: false,
                    options: ["bold", "italic", "underline"],
                  },
                  list: {
                    inDropdown: false,
                    options: ["unordered", "ordered"],
                  },
                }}
                editorState={editorState}
              />
              <h1 className="mt-5 mb-3 font-semibold">Resume</h1>
              <div className="bg-white border-[1px] border-black mb-4 px-[20px] rounded">
                <div className="m-[30px]">
                  <div className="flex items-start">
                    {resume.length > 0 && (
                      <a
                        className="flex items-start gap-2"
                        target="_blank"
                        rel="noreferrer"
                        href={`${resume[0]?.users_resume_path}${resume[0]?.users_resume_file_name}`}
                      >
                        <span className="text-2xl">
                          <AiFillFilePdf />
                        </span>
                        <span>{resume[0]?.users_resume_file_name}</span>
                      </a>
                    )}
                    {!resume.length > 0 && <p>You haven't uploaded a resume</p>}
                  </div>
                </div>
              </div>

              {resume.length > 0 && (
                <button
                  onClick={handleApply}
                  className="py-[12px] px-4 bg-[#F39058] text-white rounded-full hover:bg-[#f47126] mt-4 text-sm w-full"
                >
                  Send Apply
                </button>
              )}
              {!resume.length > 0 && (
                <>
                  <button
                    onClick={handleApply}
                    className="py-[12px] px-4 bg-[#fabc9b] text-white rounded-full mt-4 text-sm w-full cursor-not-allowed"
                    disabled
                  >
                    Send Apply
                  </button>
                  <div className="flex items-center gap-2 mt-3 text-sm font-semibold">
                    <RiAlertFill className="text-[18px] text-[#f9c135]" />
                    <p>
                      Please
                      <Link
                        to="/profile"
                        className="underline underline-offset-4 text-[#fba255] hover:!text-[#cf731c]"
                      >
                        {" "}
                        upload your resume{" "}
                      </Link>
                      before apply this job.
                    </p>
                  </div>
                </>
              )}
            </div>
          ) : (
            <div className="bg-white border-[1px] border-black mb-[90px] py-[20px] px-[20px] md:py-[30px] md:px-[30px] rounded">
              <div>Success apply this job</div>
            </div>
          )}
        </div>
      </Layout>
    </>
  );
}
