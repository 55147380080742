import React from "react";
import Swal from "sweetalert2";
import axios from "axios";
import api from "../../Api/api";
import { useEffect } from "react";
import { useState } from "react";
import { AiFillFilePdf } from "react-icons/ai";
import { ScrollTop } from "../../utils/ScrollTop";

export default function Resume() {
  const [usersResume, setUsersResume] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    showResume();
    ScrollTop();
  }, []);

  const showResume = async () => {
    setLoading(true);
    const users = JSON.parse(localStorage.getItem("user"));
    const url = `${api.resume}?users_email=${users?.user_email}`;

    await axios
      .get(url, {
        headers: {
          Authorization: `bearer ${users?.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        setUsersResume(response.data.users_resume);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const handleUploadResume = async (e) => {
    const selectedFile = e.target.files[0];
    const maxSize = 2 * 1024 * 1024; // 2MB in bytes

    // Validate file type: Only allow .pdf files
    const allowedExtensions = /(\.pdf)$/i; // Regular expression for .pdf extension

    Swal.fire({
      title: "Please wait",
      text: "It may take a few moments",
      timerProgressBar: true,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    // Check if the file is a PDF
    if (!allowedExtensions.exec(selectedFile.name)) {
      Swal.fire({
        title: "Failed",
        text: "Only .pdf files are allowed",
        icon: "error",
        confirmButtonColor: "#F39058",
      });
      return;
    }

    // Check if the file size is within the limit
    if (selectedFile.size < maxSize) {
      const users = JSON.parse(localStorage.getItem("user"));
      const url = `${api.resume}`;

      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("users_email", users.user_email);

      await axios
        .post(url, formData, {
          headers: {
            Authorization: `bearer ${users?.token}`,
          },
        })
        .then(() => {
          Swal.fire({
            title: "Success",
            text: "Successfully uploaded resume",
            icon: "success",
            confirmButtonColor: "#F39058",
          });
          showResume();
        })
        .catch((error) => {
          Swal.fire({
            title: "Failed",
            text: "Failed to upload resume",
            icon: "error",
            confirmButtonColor: "#F39058",
          });
          console.log(error);
        });
    } else {
      Swal.fire({
        title: "Failed",
        text: "File too large. Max 2 MB",
        icon: "error",
        confirmButtonColor: "#F39058",
      });
      console.log("error file too large");
    }
  };

  return (
    <div className="w-full p-[20px] xl:p-[30px] h-screen">
      {loading ? (
        <>
          <div className="w-full pt-20">
            <div className="flex items-center justify-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-col items-start gap-[40px] md:justify-between md:flex-row">
            <div className="border-b-4 border-[#F39058] text-2xl font-semibold">
              Resume
            </div>
          </div>
          <input
            id="uploadResume"
            type="file"
            name="resume"
            className="hidden"
            accept=".pdf"
            onChange={handleUploadResume}
          />
          {usersResume.length > 0 ? (
            <>
              <div className="mt-[50px] mb-[30px]">
                <div className="flex items-start">
                  <a
                    className="flex items-start gap-2"
                    rel="noreferrer"
                    target="_blank"
                    href={`${usersResume[0]?.users_resume_path}${usersResume[0]?.users_resume_file_name}`}
                  >
                    <span className="text-2xl">
                      <AiFillFilePdf />
                    </span>
                    <span>{usersResume[0]?.users_resume_file_name}</span>
                  </a>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="mt-[50px] mb-[30px]">
                You haven't upload a resume
              </div>
            </>
          )}
          <label
            className="py-2 px-10 bg-[#F39058] text-white rounded-full hover:bg-[#f47126] text-sm cursor-pointer"
            htmlFor="uploadResume"
          >
            {usersResume.length > 0 ? "Change Resume" : "Upload Resume"}
          </label>
          <div className="mt-2 ml-2">
            <small className="text-[#d4723a] font-semibold">
              Maximum file size 2 Mb
            </small>
          </div>
        </>
      )}
    </div>
  );
}
