import signUpStyle from "../../pages/Register/Register.module.css";

const Button = ({ loading, text }) => {
  return (
    <button
      className={signUpStyle.btnSignUp}
      type="submit"
      style={{ backgroundColor: loading ? "#858585" : "#000" }}
      disabled={loading}
    >
      {loading && (
        <div className={signUpStyle["lds-ring"]}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      )}
      {!loading && <span>{text}</span>}
    </button>
  );
};

export default Button;
