import DataDiri from "../DataDiri";
import ProfileSaya from "../ProfileSaya";
import PengalamanKerja from "../PengalamanKerja";
import Pendidikan from "../Pendidikan";
import Keahlian from "../Keahlian";
import License from "../License";
import Psychotest from "../Psychotest";
import Resume from "../Resume";

export const MenuContent = [
  {
    id: 1,
    component: <ProfileSaya />,
  },
  {
    id: 2,
    component: <DataDiri />,
  },
  {
    id: 3,
    component: <PengalamanKerja />,
  },
  {
    id: 4,
    component: <Pendidikan />,
  },
  {
    id: 5,
    component: <Keahlian />,
  },
  {
    id: 6,
    component: <License />,
  },
  {
    id: 7,
    component: <Psychotest />,
  },
  {
    id: 8,
    component: <Resume />,
  },
];
