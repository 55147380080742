import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { FaFileDownload } from "react-icons/fa";

import { ScrollTop } from "../../utils/ScrollTop";
import api from "../../Api/api";

export default function Psychotest() {
  const [loading, setLoading] = useState(false);
  const [psychotestData, setPsychotestData] = useState();

  useEffect(() => {
    showPsychotest();
    ScrollTop();
  }, []);

  const showPsychotest = async () => {
    setLoading(true);
    const users = JSON.parse(localStorage.getItem("user"));
    const url = `${api.psychotest}?users_email=${users.user_email}`;

    await axios
      .get(url, {
        headers: {
          "Content-type": "application/json",
          Authorization: `bearer ${users?.token}`,
        },
      })
      .then((response) => {
        setPsychotestData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleGotoTest = async (psychotestId) => {
    const users = JSON.parse(localStorage.getItem("user"));
    const url = `${api.psychotest}/${psychotestId}`;
    const url_ist =
      "https://docs.google.com/forms/d/e/1FAIpQLSeTPHFhNLKZx0aAuBtlNe93zSnwmw07OhFz4tscyzIeXmqY_Q/viewform";
    const url_papikostik =
      "https://docs.google.com/forms/d/e/1FAIpQLSe4sCFctPZCNbkHmHp0FevmNeUUKzIoWlL4hstpHmKvHP7mcw/viewform";
    const url_disc =
      "https://docs.google.com/forms/d/e/1FAIpQLSeUTNM1v94Oi6aqeQOj9nm28atL03wVD7jFtZahJGTdRAG1DA/viewform";

    const data = {
      users_email: users.user_email,
    };

    await axios
      .post(url, data, {
        headers: {
          "Content-type": "application/json",
          Authorization: `bearer ${users?.token}`,
        },
      })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          if (psychotestId === 1) {
            window.location.href = url_ist;
          } else if (psychotestId === 2) {
            window.location.href = url_papikostik;
          } else {
            window.location.href = url_disc;
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="w-full p-[20px] xl:p-[30px] min-h-screen">
      {loading && (
        <>
          <div className="w-full pt-20">
            <div className="flex items-center justify-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </>
      )}

      {!loading && (
        <>
          <div className="flex flex-col items-start gap-[40px] md:justify-between md:flex-row">
            <div className="border-b-4 border-[#F39058] text-2xl font-semibold">
              Psychotest
            </div>
          </div>

          <h1 className="mt-[2.7rem] mb-2 font-semibold">Overview</h1>
          <p>
            Temukan potensi terbaik Anda melalui berbagai tes psikologi yang
            dirancang khusus untuk membantu memahami kepribadian, keterampilan,
            dan minat Anda. Psikotest kami menawarkan pendekatan ilmiah yang
            akurat untuk membantu perusahaan mengetahui potensi serta membantu
            Anda dalam pengambilan keputusan karier, pengembangan diri, dan
            peningkatan kemampuan kerja.
          </p>

          <div className="mt-[2rem] p-[2rem] bg-white rounded-xl shadow-md">
            <h1 className="mb-[1.5rem] font-semibold">
              IST (Intelligence Structure Test)
            </h1>
            <p className="mb-4 text-sm">
              IST (Intelligence Structure Test) merupakan tes psikologi yang
              digunakan untuk mengukur berbagai aspek kecerdasan seseorang. Tes
              ini dirancang untuk mengevaluasi kemampuan kognitif secara
              menyeluruh, seperti kemampuan verbal, numerik, logis, dan spasial.
            </p>
            <h1 className="mb-2 text-sm font-semibold">Aturan: </h1>
            <ul className="mb-[2rem] text-sm">
              <li>Durasi Pengerjaan 90 menit</li>
              <li>Terdapat 9 buah subtest</li>
              <li>Masing-masing subtest memiliki durasi pengerjaannya</li>
            </ul>
            <button
              onClick={() => handleGotoTest(1)}
              disabled={psychotestData?.ist.is_accessed ? false : true}
              className={
                psychotestData?.ist.is_accessed
                  ? "py-2 px-10 bg-[#F39058] text-white rounded-full hover:bg-[#f47126] text-sm"
                  : "flex items-center gap-[5px] py-2 px-4 bg-[#fabc9b] text-white rounded-full mt-4 text-sm cursor-not-allowed"
              }
            >
              IST Test
            </button>
            <div className="mt-2 text-xs text-red-500">
              {psychotestData?.ist.error}.&nbsp;
              {psychotestData?.ist.next_access_date}
            </div>
          </div>

          <div className="mt-[2rem] p-[2rem] bg-white rounded-xl shadow-md">
            <h1 className="mb-[1rem] font-semibold">Papikostik</h1>
            <p className="mb-4 text-sm">
              Merupakan tes psikologi yang digunakan untuk menilai kepribadian
              seseorang, terutama dalam konteks perilaku di tempat kerja. Tes
              ini mengukur preferensi, motivasi, dan cara seseorang berinteraksi
              dalam lingkungan kerja berdasarkan 20 skala perilaku yang terbagi
              menjadi dua kategori: role (peran dalam pekerjaan) dan need
              (kebutuhan personal).
            </p>
            <h1 className="mb-2 text-sm font-semibold">Aturan: </h1>
            <ul className="mb-[2rem] text-sm">
              <li>Durasi Pengerjaan 30 Menit</li>
              <li>Pada masing-masing soal terdapat 2 buah pernyataan</li>
              <li>
                Peserta diminta untuk memilih pernyataan yang paling menyerupai
                dengan dirinya
              </li>
            </ul>
            <button
              onClick={() => handleGotoTest(2)}
              disabled={psychotestData?.papikostik.is_accessed ? false : true}
              className={
                psychotestData?.papikostik.is_accessed
                  ? "py-2 px-10 bg-[#F39058] text-white rounded-full hover:bg-[#f47126] text-sm"
                  : "flex items-center gap-[5px] py-2 px-4 bg-[#fabc9b] text-white rounded-full mt-4 text-sm cursor-not-allowed"
              }
            >
              Papikostik Test
            </button>
            <div className="mt-2 text-xs text-red-500">
              {psychotestData?.papikostik.error}.&nbsp;
              {psychotestData?.papikostik.next_access_date}
            </div>
          </div>

          <div className="mt-[2rem] p-[2rem] bg-white rounded-xl shadow-md">
            <h1 className="mb-[1rem] font-semibold">
              DISC (Dominance, Influence, Steadiness, dan Compliance)
            </h1>
            <p className="mb-4 text-sm">
              Merupakan tes psikologi yang digunakan untuk menilai kepribadian
              seseorang, terutama dalam konteks perilaku di tempat kerja. Tes
              ini mengukur preferensi, motivasi, dan cara seseorang berinteraksi
              dalam lingkungan kerja berdasarkan 20 skala perilaku yang terbagi
              menjadi dua kategori: role (peran dalam pekerjaan) dan need
              (kebutuhan personal).
            </p>
            <h1 className="mb-2 text-sm font-semibold">Aturan: </h1>
            <ul className="mb-[2rem] text-sm">
              <li>Durasi Pengerjaan 30 Menit</li>
              <li>Pada masing-masing soal terdapat 2 buah pernyataan</li>
              <li>
                Peserta diminta untuk memilih pernyataan yang paling menyerupai
                dengan dirinya
              </li>
            </ul>
            <button
              onClick={() => handleGotoTest(3)}
              disabled={psychotestData?.disc.is_accessed ? false : true}
              className={
                psychotestData?.disc.is_accessed
                  ? "py-2 px-10 bg-[#F39058] text-white rounded-full hover:bg-[#f47126] text-sm"
                  : "flex items-center gap-[5px] py-2 px-4 bg-[#fabc9b] text-white rounded-full mt-4 text-sm cursor-not-allowed"
              }
            >
              DISC Test
            </button>
            <div className="mt-2 text-xs text-red-500">
              {psychotestData?.disc.error}.&nbsp;
              {psychotestData?.disc.next_access_date}
            </div>
          </div>

          <div>
            <h1 className="mt-[3rem] font-semibold">Test Result</h1>
            {psychotestData?.test_result?.length > 0 && (
              <>
                <div className="mt-[30px] mb-[30px]">
                  <div className="flex items-center justify-center">
                    <a
                      className="flex items-center gap-2"
                      rel="noreferrer"
                      target="_blank"
                      href={`${process.env.REACT_APP_MEDIA_URL}/pdf/${psychotestData?.test_result[0].pdf_result}`}
                    >
                      <span className="text-3xl">
                        <FaFileDownload />
                      </span>
                      <span>Psychotest result</span>
                    </a>
                  </div>
                </div>
              </>
            )}
            {psychotestData?.test_result?.length === 0 && (
              <div className="w-full px-10 pt-[40px]">
                <div className="flex items-center justify-center gap-4 font-semibold">
                  <img src="/images/noData.png" alt="data-not-found" />
                  <p className="text-sm">
                    Take the test and wait for our team to provide the results
                  </p>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}
